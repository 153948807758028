import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../location/locationStyle/locationStockage.css";
import "../../client/ClientStyle/client.css";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import * as ClientStyle from "../../client/ClientStyle/clientStyle";
import { Button } from 'primereact/button';
import { createTheme } from "@mui/material/styles";
import { SuprRgl } from "./suprRgl";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import { makeStyles } from "@mui/material/styles";
import { AjoutReglement } from "./AjoutReglement";
import Grid from '@mui/material/Grid';
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { SelectReglement } from "./selectReglement";

import { Modal, useMediaQuery, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import { ModifierReglement } from "./modifReglement";
import correctEncoding from "../../correctEncoding";

export const Reglement = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [paiements, setPaiements] = useState([]);
  const [idPSelected, setIdPSelected] = useState("");
  const [copiedText, setCopiedText] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [idSelectedRgm, setIdSelectedRgm] = useState("");
  const [modifierRgm, setModifierRgm] = useState(false);
  const [ajoutReglement, setAjoutReglement] = useState(false);
  const [rescentRgl, setRescentRgl] = useState("");
  const [suprRgl, setSuprRgl] = useState(false);

  const copyToClipboard = (text) => {
    if (text !== "") {
      if (navigator.clipboard && navigator.clipboard.writeText) {
        navigator.clipboard
          .writeText(text)
          .then(() => {
            setCopiedText(text);
            setOpenSnackbar(true);
          })
          .catch((error) => {
            console.error(
              "Erreur lors de la copie dans le presse-papiers : ",
              error
            );
          });
      } else {
        // Utiliser l'API de sélection de texte comme solution de secours
        const textArea = document.createElement("textarea");
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand("copy");
        document.body.removeChild(textArea);

        setCopiedText(text);
        setOpenSnackbar(true);
      }
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const formattedDate = (dateRecup) => {
    if (dateRecup === "0000-00-00") {
      return `-`;
    }
    const date = new Date(dateRecup);
    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    const month =
      date.getMonth() + 1 < 10
        ? `0${date.getMonth()}`
        : date.getMonth().toString();
    const year = date.getFullYear();
    return `${day}-${(parseInt(month) + 1)
      .toString()
      .padStart(2, "0")}-${year}`;
  };
  function mapTypePaie(typePaie) {
    switch (typePaie) {
      case "vir":
        return "Virement bancaire";
      case "espc":
        return "Espèce";
      case "cq":
        return "Chèque";
      case "cBl":
        return "Carte bleue";
      case "ABOcBl":
        return "ABO carte bleue";
      default:
        return typePaie;
    }
  }
  const [currentPage, setCurrentPage] = useState(1);

  const [visibleBottom, setVisibleBottom] = useState(false);
  const isMobilePortrait = useMediaQuery("(max-width:1207px)  ");

  return (
    <div className="toutCL">
      <Grid container item xs={12} md={9} spacing={2} direction="column">

        <Modal
          open={ajoutReglement}
          disableBackdropClick={true}
          aria-describedby="modal-modal-description"
        >
          <AjoutReglement setAjoutReglement={setAjoutReglement} />
        </Modal>
        <Modal
          open={modifierRgm}
          disableBackdropClick={true}
          aria-describedby="modal-modal-description"
        >
          <ModifierReglement
            setModifierRgm={setModifierRgm}
            idPSelected={idPSelected}
          />
        </Modal>
        <Modal
          open={suprRgl}
          disableBackdropClick={true}
          aria-describedby="modal-modal-description"
        >
          <SuprRgl setSuprRgl={setSuprRgl} idSelected={idPSelected} />
        </Modal>
        {!isMobilePortrait && (
          <div className="flex">
            <div className="gaucheStock">
              <div>
                <div className="center">
                  {/*   
                  <Button
                    variant="contained"
                    sx={{
                      marginBottom: "10px",
                    }}
                    color="primary"
                    style={{ width: "250px" }}
                    onClick={() => setAjoutReglement(true)}
                  >
                    Ajouter un paiement
                  </Button>*/}

                  <Snackbar
                    anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                    open={openSnackbar}
                    autoHideDuration={3000}
                    onClose={handleCloseSnackbar}
                    message={`Copie réussie de " ${copiedText === "location.mail" ? "e-mail" : copiedText
                      } "`}
                    action={
                      <React.Fragment>
                        
                        <IconButton
                          size="small"
                          aria-label="close"
                          color="inherit"
                          onClick={handleCloseSnackbar}
                        >
                          <CloseIcon fontSize="small" />
                        </IconButton>
                      </React.Fragment>
                    }
                  />
                </div>
                <div>

                  <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%'
                  }}>
                    <h3
                      style={{
                        margin: 0,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        maxWidth: 'calc(100% - 120px)' // Adjust based on button width
                      }}

                    >Liste des paiements effectués</h3>
                    <Button
                      label="Paiement"
                      icon="pi pi-plus"
                      severity="info"
                      style={{
                        backgroundColor: '#146C94',
                        flexShrink: 0 // Prevents button from shrinking
                      }}
                      onClick={() => setAjoutReglement(true)}
                    />



                  </div>

                  <ClientStyle.StyledPaper>
                 
                      <DataTable
                        value={paiements}
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 8, 10, 25, 50, 500]}
                        tableStyle={{ minWidth: "13rem" }}
                        rowClassName={() => "custom-row-class"}
                        columnSeparator=" "
                        paginatorTemplate={
                          "FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
                        }
                        paginatorRight={
                          <span style={{ margin: "10px", fontWeight: "bold" }}>
                            Résultats : {paiements.length}
                          </span>
                        }
                      >
                        <Column
                          field="numPaie"
                          header="Id"
                          style={{ width: "20%", cursor: "pointer" }}
                          body={(rowData) => (
                            <div onClick={() => copyToClipboard(rowData.idPaiement)}>
                              {rowData.idPaiement} <ContentCopyIcon sx={{ fontSize: 10 }} />
                            </div>
                          )}
                        />

                        
                        <Column
                          className="loc-column-content"
                          field="idClient"
                          header="Client"
                          body={(rowData) => (
                            <Tooltip
                              title={
                                <div>
                                  {rowData.mail !== "" && (
                                    <div
                                      style={{
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      <span>
                                        <ContentCopyIcon
                                          style={{
                                            cursor: "pointer",
                                            padding: "2px",
                                          }}
                                          fontSize="13px"
                                          onClick={() =>
                                            copyToClipboard(rowData.mail)
                                          }
                                        />
                                        <span className="text-sm ">
                                          {rowData.mail}
                                        </span>
                                      </span>
                                    </div>
                                  )}
                                  {rowData.mail2 !== "" && (
                                    <div
                                      style={{
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      <span className=" ">
                                        <ContentCopyIcon
                                          style={{
                                            cursor: "pointer",
                                            padding: "2px",
                                          }}
                                          fontSize="small"
                                          onClick={() =>
                                            copyToClipboard(rowData.mail2)
                                          }
                                        />
                                        {rowData.mail2}
                                      </span>
                                    </div>
                                  )}

                                  {rowData.num1 !== "" && (
                                    <div
                                      style={{
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      <span className=" ">
                                        <ContentCopyIcon
                                          style={{
                                            cursor: "pointer",
                                            padding: "2px",
                                          }}
                                          fontSize="small"
                                          onClick={() =>
                                            copyToClipboard(rowData.num1)
                                          }
                                        />
                                        {rowData.num1}
                                      </span>
                                    </div>
                                  )}

                                  {rowData.num2 !== "" && (
                                    <div
                                      style={{
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      <span className=" ">
                                        <ContentCopyIcon
                                          style={{
                                            cursor: "pointer",
                                            padding: "2px",
                                          }}
                                          fontSize="small"
                                          onClick={() =>
                                            copyToClipboard(rowData.num2)
                                          }
                                        />
                                        {rowData.num2}{" "}
                                      </span>
                                    </div>
                                  )}
                                </div>
                              }
                              arrow
                            >
                              <span className="loc-column-content1">
                                {/* {rowData.idClient}- */}{correctEncoding(rowData.nom)} 
                              </span>
                            </Tooltip>
                          )}
                        />

                        <Column
                          field="typePaie"
                          header="Type"
                          style={{ width: "20%" }}
                          body={(rowData) => (
                            <div>{mapTypePaie(rowData.typePaie)}</div>
                          )}
                        />
                        <Column
                          field="datePaie"
                          header="Date paiement" 
                          style={{ width: "20%" }}
                          body={(rowData) => formattedDate(rowData.datePaie)}
                        />
                        <Column
                          header="Montant(€)"
                        
                          bodyStyle={{ padding: "10px", fontSize: "15px" }}
                          body={(rowData) => `${rowData.valeurPaie}€`}
                        />
                        <Column
                          field="dateDebut"
                          header="Date"
                          style={{ width: "20%" }}
                          body={(rowData) => (
                            <span style={{ width: "25vw", fontSize: "14px" }}>
                              D: {formattedDate(rowData.dateDebut)} <br />
                              F: {formattedDate(rowData.dateFin)}
                            </span>
                          )}
                        />
                        {localStorage.getItem("pr") === "sad" ||
                          localStorage.getItem("pr") === "st" ||
                          localStorage.getItem("pr") === "ad" ? (
                          <Column
                            header="Actions"
                            style={{ width: "10vw" }}
                            body={(rowData) => (
                              <>
                                <ModeEditOutlineIcon
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setIdPSelected(rowData.idPaiement);
                                    setRescentRgl(rowData.idPaiement);
                                    setModifierRgm(true);
                                  }}
                                />
                                <DeleteIcon
                                  onClick={() => {
                                    setIdPSelected(rowData.idPaiement);
                                    setSuprRgl(true);
                                    setRescentRgl("");
                                  }}
                                  style={{
                                    marginLeft: "10px",
                                    cursor: "pointer",
                                  }}
                                />
                              </>
                            )}
                          ></Column>
                        ) : null}
                      </DataTable>
                    
                  </ClientStyle.StyledPaper>
                </div>
              </div>

           

            </div>
            <div className="droiteStock ">
              <Box
                sx={{
                  marginLeft: {
                    xs: "10px", // Pour les écrans mobiles (extra small)
                    sm: "10px", // Pour les écrans de taille moyenne (small)
                    md: "10px", // Pour les écrans de taille moyenne (medium)
                    lg: "10px", // Pour les écrans larges (large)
                  },
                }}
                className="cente"
              >
                <h2>FILTRE</h2>
              </Box>
              <SelectReglement
                setPaiements={setPaiements}
                ajoutReglement={ajoutReglement}
                modifierRgm={modifierRgm}
                suprRgl={suprRgl}
              />
            </div>
          </div>
        )}{" "}
        {isMobilePortrait && (
          <div>
            <div className=" ">
              <div>
                <div className="center">
                   
                 
                  <Snackbar
                    anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                    open={openSnackbar}
                    autoHideDuration={3000}
                    onClose={handleCloseSnackbar}
                    message={`Copie réussie de " ${copiedText === "location.mail" ? "e-mail" : copiedText
                      } "`}
                    action={
                      <React.Fragment>
                        <Button
                          color="secondary"
                          size="small"
                          onClick={handleCloseSnackbar}
                        >
                          FERMER
                        </Button>
                        <IconButton
                          size="small"
                          aria-label="close"
                          color="inherit"
                          onClick={handleCloseSnackbar}
                        >
                          <CloseIcon fontSize="small" />
                        </IconButton>
                      </React.Fragment>
                    }
                  />
                </div>
                  <h2>FILTRE</h2>
                 
                <div className="  ">
                  <Box>
                    <SelectReglement
                      setPaiements={setPaiements}
                      ajoutReglement={ajoutReglement}
                      modifierRgm={modifierRgm}
                      suprRgl={suprRgl}
                    />
                  </Box>
                </div>

                <div style={{ display: "flex", alignItems: "center" ,gap: "20%"}}> 
                  <h3 className="titreTableau">Liste des paiements</h3>
                  <Button
                    className="boutonAjouter"
                    style={{
                      backgroundColor: '#146C94',
                      flexShrink: 0
                    }}
                    variant="contained"
                    icon="pi pi-plus"
                    color="primary"
                    label="Paiement"
                    onClick={() => setAjoutReglement(true)}
                  />
                </div>
                
                <ClientStyle.StyledPaper>
                <div className="center">
                <DataTable
                      value={paiements}
                      paginator
                      rows={10}
                      rowsPerPageOptions={[5, 10, 25, 50]}
                      tableStyle={{ minWidth: '50rem' }}
                      scrollable
                      scrollDirection="horizontal"
                      paginatorTemplate={
                        "FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
                      }
                      paginatorRight={
                        <span style={{ margin: "10px", fontWeight: "bold" }}>
                          Résultats : {paiements.length}
                        </span>
                      }
                    >
                      <Column
                        field="numPaie"
                        header="Id"
                        style={{ width: "20%" }}
                        body={(rowData) => rowData.idPaiement}
                      />
                    
                      <Column
                        className="loc-column-content"
                        field="nom"
                        style={{ width: "50%" }}
                        header="Client"
                      />

                      <Column
                        field="typePaie"
                        header="Type"
                        className="loc-column-content"
                        bodyStyle={{ fontSize: "13px" }}
                        body={(rowData) => (
                          <div>
                            {mapTypePaie(rowData.typePaie)}
                          </div>
                        )}
                      />
                      <Column
                          field="datePaie"
                          header="Date paiement" 
                          style={{ width: "20%" }}
                          body={(rowData) => formattedDate(rowData.datePaie)}
                      />

                      <Column
                        header="Montant"
                        body={(rowData) => `${rowData.valeurPaie} €`}
                      />

                      <Column
                        header="Actions"
                        body={(rowData) => (
                          <>
                            <ModeEditOutlineIcon
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setIdPSelected(rowData.idPaiement);
                                setRescentRgl(rowData.idPaiement);
                                setModifierRgm(true);
                              }}
                            />
                            <DeleteIcon
                              onClick={() => {
                                setIdPSelected(rowData.idPaiement);
                                setSuprRgl(true);
                                setRescentRgl("");
                              }}
                              style={{
                                marginLeft: "10px",
                                cursor: "pointer",
                              }}
                            />
                          </>
                        )}
                      />

                </DataTable>
                </div>
                    
        
                </ClientStyle.StyledPaper>
              </div>
            </div>{" "}
            <br />

            
          </div>
        )}

      </Grid></div>
  );
};
