function mapTypePaie(typePaie) {
    switch (typePaie) {
      case "vir":
        return "Virement bancaire";
      case "espc":
        return "Espèce";
      case "cBl":
        return "Carte bleu";
      case "cq":
        return "Chèque";
      case "ABOcBl":
        return "ABO carte bleu ";
      default:
        return typePaie;
    }
  }
export default mapTypePaie;