import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../location/locationStyle/locationStockage.css";
import "../../client/ClientStyle/client.css";

import * as ClientStyle from "../../client/ClientStyle/clientStyle";
import { Button } from 'primereact/button';
import { createTheme } from "@mui/material/styles";

import DeleteIcon from "@mui/icons-material/Delete";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
// import { makeStyles } from "@mui/material/styles";
import { ModifierContrat } from "./modifContrat";
import { SelectContrat } from "./selectContrat";
import { AjoutContrat } from "./ajoutContrat";
import { SuprCtr } from "./suprContrat";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Modal, useMediaQuery, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import Grid from '@mui/material/Grid';
import correctEncoding from "../../correctEncoding";

export const Contrat = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [contrats, setContrats] = useState([]);
  const [idPSelected, setIdPSelected] = useState("");
  const [copiedText, setCopiedText] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [idSelectedRgm, setIdSelectedRgm] = useState("");
  const [modifierCtr, setModifierCtr] = useState(false);
  const [ajoutContrat, setAjoutContrat] = useState(false);
  const [rescentRgl, setRescentRgl] = useState("");
  const [valueFiltre, setValueFiltre] = React.useState("actif");
  const [suprCtr, setSuprCtr] = useState(false);

  const [lienContrat, setLienContrat] = useState("");
  const copyToClipboard = (text) => {
    if (text !== "") {
      if (navigator.clipboard && navigator.clipboard.writeText) {
        navigator.clipboard
          .writeText(text)
          .then(() => {
            setCopiedText(text);
            console.log("copie annnh", text);
            setOpenSnackbar(true);
          })
          .catch((error) => {
            console.error(
              "Erreur lors de la copie dans le presse-papiers : ",
              error
            );
          });
      } else {
        // Utiliser l'API de sélection de texte comme solution de secours
        const textArea = document.createElement("textarea");
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand("copy");
        document.body.removeChild(textArea);

        setCopiedText(text);
        setOpenSnackbar(true);
      }
    }
  };
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };
  const formattedDate = (dateRecup) => {
    if (dateRecup === "0000-00-00") {
      return `-`;
    }
    const date = new Date(dateRecup);
    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    const month =
      date.getMonth() + 1 < 10
        ? `0${date.getMonth()}`
        : date.getMonth().toString();
    const year = date.getFullYear();
    return `${day}-${(parseInt(month) + 1)
      .toString()
      .padStart(2, "0")}-${year}`;
  };
  function mapTypeCtr(typeCTRT) {
    switch (typeCTRT) {
      case "CNS":
        return "Contrat non signé ";
      case "CS":
        return "Contrat signé ";
      case "CE":
        return "Contrat envoyé ";
      case "CNE":
        return "Contrat non envoyé ";
      case "PDCAE":
        return "Pas de contrat à envoyer ";
      case "PDC":
        return "Pas de contrat ";
      default:
        return typeCTRT;
    }
  }
  const [currentPage, setCurrentPage] = useState(1);

  const [visibleBottom, setVisibleBottom] = useState(false);
  const isMobilePortrait = useMediaQuery("(max-width:1207px)");

  return (
    <div className="toutCL">

      <Grid container item xs={12} md={9} spacing={2} direction="column">
        <Modal
          open={ajoutContrat}
          disableBackdropClick={true}
          aria-describedby="modal-modal-description"
        >
          <AjoutContrat setAjoutContrat={setAjoutContrat} />
        </Modal>
        <Modal
          open={modifierCtr}
          disableBackdropClick={true}
          aria-describedby="modal-modal-description"
        >
          <ModifierContrat
            setModifierCtr={setModifierCtr}
            idPSelected={idPSelected}
          />
        </Modal>
        <Modal
          open={suprCtr}
          disableBackdropClick={true}
          aria-describedby="modal-modal-description"
        >
          <SuprCtr setSuprCtr={setSuprCtr} idSelected={idPSelected} />
        </Modal>
        {!isMobilePortrait && (
          <div className="flex">
            <div className="gaucheStock">
              <div>
                <div className="center">

                  <Snackbar
                    anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                    open={openSnackbar}
                    autoHideDuration={3000}
                    onClose={handleCloseSnackbar}
                    message={`Copie réussie de " ${copiedText === "location.mail" ? "e-mail" : copiedText
                      } "`}
                    action={
                      <React.Fragment>
                        <Button
                          color="secondary"
                          size="small"
                          onClick={handleCloseSnackbar}
                        >
                          FERMER
                        </Button>
                        <IconButton
                          size="small"
                          aria-label="close"
                          color="inherit"
                          onClick={handleCloseSnackbar}
                        >
                          <CloseIcon fontSize="small" />
                        </IconButton>
                      </React.Fragment>
                    }
                  />
                </div>
                <div>

                  <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%'
                  }}>
                    <h3 style={{
                      margin: 0,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      maxWidth: 'calc(100% - 120px)' // Adjust based on button width
                    }}>Liste des contrats  </h3>
                    <Button
                      label="Contrat"
                      icon="pi pi-plus"
                      severity="info"
                      style={{
                        backgroundColor: '#146C94',
                        flexShrink: 0 // Prevents button from shrinking
                      }}
                      onClick={() => setAjoutContrat(true)}
                    />


                  </div>


                  <ClientStyle.StyledPaper>
                    <div className="">
                      <DataTable
                        value={contrats}
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 8, 10, 25, 50, 500]}
                        tableStyle={{ minWidth: "13rem" }}
                        rowClassName={() => "custom-row-class"}
                        columnSeparator=" "
                        paginatorTemplate={
                          "FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
                        }
                        paginatorRight={
                          <span style={{ margin: "10px", fontWeight: "bold" }}>
                            Résultats : {contrats.length}
                          </span>
                        }
                      >
                        <Column
                          className="loc-column-content"
                          field="idClient"
                          header="Client"
                          body={(rowData) => (
                            <Tooltip
                              title={
                                <div>
                                  {rowData.mail !== "" && (
                                    <div
                                      style={{
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      <span>
                                        <ContentCopyIcon
                                          style={{
                                            cursor: "pointer",
                                            padding: "2px",
                                          }}
                                          fontSize="small"
                                          onClick={() =>
                                            copyToClipboard(rowData.mail)
                                          }
                                        />
                                        <span className="text-sm ">
                                          {rowData.mail}
                                        </span>
                                      </span>
                                    </div>
                                  )}
                                  {rowData.mail2 !== "" && (
                                    <div
                                      style={{
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      <span className=" ">
                                        <ContentCopyIcon
                                          style={{
                                            cursor: "pointer",
                                            padding: "2px",
                                          }}
                                          fontSize="small"
                                          onClick={() =>
                                            copyToClipboard(rowData.mail2)
                                          }
                                        />
                                        {rowData.mail2}
                                      </span>
                                    </div>
                                  )}

                                  {rowData.num1 !== "" && (
                                    <div
                                      style={{
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      <span className=" ">
                                        <ContentCopyIcon
                                          style={{
                                            cursor: "pointer",
                                            padding: "2px",
                                          }}
                                          fontSize="small"
                                          onClick={() =>
                                            copyToClipboard(rowData.num1)
                                          }
                                        />
                                        {rowData.num1}
                                      </span>
                                    </div>
                                  )}

                                  {rowData.num2 !== "" && (
                                    <div
                                      style={{
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      <span className=" ">
                                        <ContentCopyIcon
                                          style={{
                                            cursor: "pointer",
                                            padding: "2px",
                                          }}
                                          fontSize="small"
                                          onClick={() =>
                                            copyToClipboard(rowData.num2)
                                          }
                                        />
                                        {rowData.num2}{" "}
                                      </span>
                                    </div>
                                  )}
                                </div>
                              }
                              arrow
                            >
                              <span className="loc-column-content1">
                                {/* {rowData.idClient}- */} {correctEncoding(rowData.nom)}
                              </span>
                            </Tooltip>
                          )}
                        />

                        <Column
                          field="typeContrat"
                          header="Type"
                          style={{ width: "25%" }}
                          body={(rowData) => (
                            <div>{mapTypeCtr(rowData.typeContrat)}</div>
                          )}
                        />
                        <Column
                          field="lienCtr"
                          header="Lien du contrat"
                          style={{ width: "10vw", maxWidth: "10vw" }}
                          body={(rowData) => {

                            const urlPattern = /^(https?:\/\/)/;
                            const link = urlPattern.test(rowData.lienCtr)
                              ? rowData.lienCtr
                              : null;

                            return link ? (
                              <a
                                style={{
                                  display: "block",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  maxWidth: "10vw",
                                  color: "#146C94",
                                }}
                                href={link}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {rowData.lienCtr}
                              </a>
                            ) : (
                              <span
                                style={{
                                  display: "block",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  maxWidth: "10vw",
                                  color: "#999",  // Couleur grise pour indiquer un état "en attente"
                                }}
                              >
                                {rowData.lienCtr}
                              </span>
                            );
                          }}
                        />
                        <Column
                          field="Stockage"
                          header="Stockage"
                          style={{ width: "15%" }}
                          bodyStyle={{ fontSize: "15px", textAlign: "center" }}
                          body={(rowData) => (rowData.idStockage.split('||').join(',')

                          )}
                        />
                        <Column
                          field="dateCtr"
                          header="Date du contrat"
                          style={{ width: "30%" }}
                          bodyStyle={{ fontSize: "15px" }}
                          body={(rowData) => `${formattedDate(rowData.dateContrat)}`}
                        />
                        <Column
                          header="Entrée en stockage"
                          style={{ width: "20%" }}
                          bodyStyle={{ padding: "10px" }}
                          body={(rowData) => `${formattedDate(rowData.dateEntree)} `}
                        />
                        {/* <Column header="Notes" field="noteCtr"/> */}

                        {localStorage.getItem("pr") === "sad" ||
                          localStorage.getItem("pr") === "st" ||
                          localStorage.getItem("pr") === "ad" ? (
                          <Column
                            header="Actions"
                            style={{ width: "10vw" }}
                            body={(rowData) => (
                              <>
                                <ModeEditOutlineIcon
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setIdPSelected(rowData.idContrat);
                                    setRescentRgl(rowData.idContrat);
                                    setModifierCtr(true);
                                  }}
                                />
                                <DeleteIcon
                                  onClick={() => {
                                    setIdPSelected(rowData.idContrat);
                                    setSuprCtr(true);
                                    setRescentRgl("");
                                  }}
                                  style={{
                                    marginLeft: "10px",
                                    cursor: "pointer",
                                  }}
                                />
                              </>
                            )}
                          ></Column>
                        ) : null}
                      </DataTable>
                    </div>
                  </ClientStyle.StyledPaper>
                </div>
              </div>



            </div>
            <div className="droiteStock ">
              <Box
                sx={{
                  marginLeft: {
                    xs: "10px", // Pour les écrans mobiles (extra small)
                    sm: "10px", // Pour les écrans de taille moyenne (small)
                    md: "10px", // Pour les écrans de taille moyenne (medium)
                    lg: "10px", // Pour les écrans larges (large)
                  },
                }}
                className="cente"
              >
                <h2>FILTRE</h2>
              </Box>
              <SelectContrat
                setValueFiltre={setValueFiltre}
                valueFiltre={valueFiltre}
                setContrats={setContrats}
                ajoutContrat={ajoutContrat}
                modifierCtr={modifierCtr}
                suprCtr={suprCtr}


              />
            </div>
          </div>
        )}{" "}
        {isMobilePortrait && (
          <div>
            <div className=" ">
              <div>
                <div className="center">


                  <Snackbar
                    anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                    open={openSnackbar}
                    autoHideDuration={3000}
                    onClose={handleCloseSnackbar}
                    message={`Copie réussie de " ${copiedText === "location.mail" ? "e-mail" : copiedText
                      } "`}
                    action={
                      <React.Fragment>
                        <Button
                          color="secondary"
                          size="small"
                          onClick={handleCloseSnackbar}
                        >
                          FERMER
                        </Button>
                        <IconButton
                          size="small"
                          aria-label="close"
                          color="inherit"
                          onClick={handleCloseSnackbar}
                        >
                          <CloseIcon fontSize="small" />
                        </IconButton>
                      </React.Fragment>
                    }
                  />
                </div>
                <h2>FILTRE</h2>
                <div className="  ">
                  <Box>
                    <SelectContrat
                      setValueFiltre={setValueFiltre}
                      valueFiltre={valueFiltre}
                      setContrats={setContrats}
                      ajoutContrat={ajoutContrat}
                      modifierCtr={modifierCtr}
                      suprCtr={suprCtr}
                    />
                  </Box>
                </div>
                <div style={{ display: "flex", alignItems: 'center', gap: "20%" }}>
                  <h3>Liste des contrats</h3>
                  <Button
                    style={{
                      backgroundColor: '#146C94',
                      flexShrink: 0
                    }}
                    variant="contained"
                    icon="pi pi-plus"
                    color="primary"
                    label="Contrat"
                    onClick={() => setAjoutContrat(true)}
                  />
                </div>

                <ClientStyle.StyledPaper>
                  <div className="center">
                    <DataTable
                      value={contrats}
                      paginator
                      rows={10}
                      rowsPerPageOptions={[5, 10, 25, 50]}
                      tableStyle={{ minWidth: '50rem' }}
                      scrollable
                      scrollDirection="horizontal"
                      paginatorTemplate={
                        "FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
                      }
                      paginatorRight={
                        <span style={{ margin: "10px", fontWeight: "bold" }}>
                          Résultats : {contrats.length}
                        </span>
                      }
                    >
                      <Column
                        className="loc-column-content"
                        style={{ width: "50%" }}
                        bodyStyle={{ fontSize: "13px" }}
                        field="nom"
                        header="Client"
                      />
                      <Column
                        field="typeContrat"
                        header="Type de contrat"
                        style={{ width: "15%" }}
                        bodyStyle={{ fontSize: "12px" }}
                        className="loc-column-content"
                        body={(rowData) => (
                          <div>
                            {mapTypeCtr(rowData.typeContrat)}
                          </div>
                        )}
                      />

                      <Column
                        field="lienCtr"
                        header="Lien du contrat"
                        body={(rowData) => {

                          const urlPattern = /^(https?:\/\/)/;
                          const link = urlPattern.test(rowData.lienCtr)
                            ? rowData.lienCtr
                            : null;

                          return link ? (
                            <a
                              style={{
                                display: "block",

                                color: "#146C94",
                              }}
                              href={link}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Lien du contrat
                            </a>
                          ) : (
                            <span
                              style={{
                                color: "#999",  // Couleur grise pour indiquer un état "en attente"
                              }}
                            >
                              {rowData.lienCtr}
                            </span>
                          );
                        }}
                      />
                      <Column
                        field="Stockage"
                        header="Stockage"
                        style={{ width: "15%" }}
                        bodyStyle={{ fontSize: "15px", textAlign: "center" }}
                        body={(rowData) => (rowData.idStockage.split('||').join(',')
                        )}
                      />
                      <Column
                        field="dateCtr"
                        header="Date du contrat"
                        style={{ width: "20%" }}
                        bodyStyle={{ fontSize: "12px" }}
                        body={(rowData) => `${formattedDate(rowData.dateContrat)}`}
                      />

                      {localStorage.getItem("pr") === "sad" ||
                        localStorage.getItem("pr") === "st" ||
                        localStorage.getItem("pr") === "ad" ? (
                        <Column
                          header="Actions"

                          body={(rowData) => (
                            <>
                              <ModeEditOutlineIcon
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setIdPSelected(rowData.idContrat);
                                  setRescentRgl(rowData.idContrat);
                                  setModifierCtr(true);
                                }}
                              />
                              <DeleteIcon
                                onClick={() => {
                                  setIdPSelected(rowData.idContrat);
                                  setSuprCtr(true);
                                  setRescentRgl("");
                                }}
                                style={{
                                  marginLeft: "10px",
                                  cursor: "pointer",
                                }}
                              />
                            </>
                          )}
                        />
                      ) : null}
                    </DataTable>
                  </div>
                </ClientStyle.StyledPaper>
              </div>
            </div>{" "}

          </div>
        )}

      </Grid>
    </div>
  );
};
