import React from "react";
import { useState, useEffect, useRef } from "react";
import "../../reglement/reglementStyle/reglementStyle.css";
import "../../reglement/reglementStyle/ajoutReglement.css";
import CancelIcon from "@mui/icons-material/Cancel";
import SearchIcon from "@mui/icons-material/Search";
import { ToastContainer, toast } from "react-toastify";
import InputAdornment from "@mui/material/InputAdornment";
import { addMonths, format, parseISO } from "date-fns";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import useMediaQuery from "@mui/material/useMediaQuery";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import axios from "axios";

import {
  DateField,
  DateTimePicker,
  StaticDatePicker,
} from "@mui/x-date-pickers";
import { Yard } from "@mui/icons-material";

export const AjoutContrat = ({ setAjoutContrat }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const fermer = () => {
    setAjoutContrat(false);
  };
  const [typeContrat, setTypeContrat] = useState("");
  const [lienContrat, setLienContrat] = useState("");

  const handleTypeContratChange = (e) => {
    setTypeContrat(e.target.value);
  };

  const searchTextFieldRef = useRef(null);
  const [LP, setLP] = useState("o");
  const [idClient, setIdClient] = useState("");
  const [nomClient, setNomClient] = useState("");
  const [dateEntree, setDateEntree] = useState("");
  const [dateResrv, setDateResrv] = useState("");
  const [adresse, setAdresse] = useState("");
  const [noteContrat,setNoteContrat] = useState("");
  const [num, setNum] = useState("");
  const [search, setSearch] = useState("");
  const [mail, setMail] = useState("");
  const [idStockage, setIdStockage] = useState("");
  const [idLocation, setIdLocation] = useState("");
  const [loyer, setLoyer] = useState("");
  const [vol, setVol] = useState("");
  const [client, setClient] = useState([]);
  const [location, setLocation] = useState([]);
 
  const [etatInput, setEtatInput] = useState(true);
  const currentDate = new Date().toISOString().slice(0, 10);
  const [dateCtr, setDateCtr] = useState(currentDate); 
  const [selectedDateCtr, setSelectedDateCtr] = useState(dayjs(dateCtr));
  const [titreDifM, setTitreDifM] = useState("Manquant / Surplus");
 
   
  const isMobilePortrait = useMediaQuery("(max-width:1207px)  ");

  const [idLocLP, setIdLocLP] = useState("");
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      chercherLoc();
    }
  };
  // maka info momba ilay location
  const chercherLoc = () => {
    if (/^\s*$/.test(search)) {
      viderChamp();
      // Le texte est vide ou ne contient que des espaces
    } else {
      // search = search.trim();
      //  setSearch(search.trimStart());
      axios
        .post(`${apiUrl}IHM/api/?search=${search}`)
        .then((response) => {
          const locationsData = response.data;
          // setClient(locationsData);
          console.log(response.data, "makaclient");
          setNomClient(locationsData[0].nom);
          setIdClient(locationsData[0].idClient);

          const adresse = `${response.data[0].ville} - ${response.data[0].codePostal} - ${response.data[0].adresse}`;
          const num = `${response.data[0].num1} - ${response.data[0].num2}`;
          const mail = `${response.data[0].mail} - ${response.data[0].mail2}`;
          setAdresse(adresse);
          setNum(num);
          setMail(mail);

          // maka info location;
          infoLocation(locationsData[0].idClient);
        })
        .catch((error) => {
          console.log(error);
          console.log("t6 lty ah ,t6", error);
        });
    }
    // maka info client;
  };
  const infoLocation = (idCli) => {
    axios
      .post(`${apiUrl}IHM/api/paiement.php?idCli=${idCli}`)
      .then(async (response) => {
        setEtatInput(false);
        console.log(response);
        const locationsData = response.data;
        // setClient(locationsData);
        setIdLocation(locationsData[0].idLocation);
        setVol(locationsData[0].totVolume);
        setLoyer(locationsData[0].loyer);
        // setDiffMPaie(locationsData[0].diffMPaie);
        setIdStockage(locationsData[0].idStockage);
        setDateEntree(locationsData[0].dateEntree);
        setDateResrv(locationsData[0].dateReservation); 
        //THISSS 
      })
      .catch((error) => {
        setEtatInput(true);
        console.error("error");
        console.log("t6 lty ah ,t6");
        toast.warn("ce client n'a pas de location en cours");
      });
  };
  //info du dernier paiement
  

  const viderChamp = () => {
    setEtatInput(true);
    setClient("");
    setIdLocation("");
    setLoyer("");
    setMail("");
    setNomClient("");
    setIdClient("");
    setIdStockage(""); 
    setAdresse("");
    setDateResrv("");
    setNoteContrat("");
    setNum(""); 
    setVol("");
  };

 
   

  const ajoutReglem = async (e) => {
    e.preventDefault(); 
  

    if (typeContrat === "") {
      toast.warn("Veuillez verifier le champ  type de contrat");

      return; // Arrêter la soumission du formulaire
    }
    
    if (idClient === "") {
      toast.warn("Veuillez entrer le nom ou numéro téléphone  du Client ");

      return;
    } else {
      setEtatInput(true);
      const dataContrat = {
        idLocation: idLocation,
        idStockage: idStockage,
        typeCtr: typeContrat,
        dateCtr: dateCtr,
        idClient: idClient,
        noteCtr: noteContrat,
        lienCtr : lienContrat,
      };
     
      try {
        await axios
          .put(`${apiUrl}IHM/api/contrat.php`, dataContrat)
          .then((response) => {

            setAjoutContrat(false);
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        
        alert("Enregistrement du contrat NON REUSSI");
      }
    }
  };
  //pour mettre par défaut le curseur sur le recherche
  useEffect(() => {
    if (searchTextFieldRef.current) {
      searchTextFieldRef.current.focus();
    }
  }, []);

  

  const [erreur, setErreur] = useState(false);

   
  const formattedDate = (dateRecup) => {
    if (dateRecup === "0000-00-00" || dateRecup === "") {
      return ``;
    }
    const date = new Date(dateRecup);
    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    const month =
      date.getMonth() + 1 < 10
        ? `0${date.getMonth()}`
        : date.getMonth().toString();
    const year = date.getFullYear();
    return `${day}-${(parseInt(month) + 1)
      .toString()
      .padStart(2, "0")}-${year}`;
  };

 

  const handleDateChangeCtr = (date) => {
    const dateko = new Date(date);
    const year = dateko.getFullYear();
    const month = dateko.getMonth() + 1; // Les mois sont indexés de 0 à 11, donc ajoutez 1
    const day = dateko.getDate();

    // Créez une chaîne de date au format "YYYY-MM-DD"
    const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")}`;

    console.log(formattedDate, "tsy ay tony");
    setDateCtr(formattedDate);
  };

  
  const [dateFormatee, setDateFormatee] = useState("");
   

  return (
    <div>
      <div className=" ">
        {!isMobilePortrait && (
          <div className="modalContainerLoc">
            <div>
              <CancelIcon
                style={{ cursor: "pointer" }}
                sx={{ marginLeft: "95%", fontSize: 40, marginTop: "-26px" }}
                onClick={() => setAjoutContrat(false)}
              />
              <h3 style={{ marginLeft: "40%" }}>
                Contrat pour la location n° {idLocation}
              </h3>{" "}
              <br />
              <br />
            </div>
            <br />{" "}
            <TextField
              id="standard-search"
              label="Adresse mail / numero / nom"
              type="text"
              variant="standard"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
                viderChamp();
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon
                      color="primary"
                      onClick={() => chercherLoc()}
                      style={{ cursor: "pointer" }}
                      fontSize="large"
                    />
                  </InputAdornment>
                ),
              }}
              style={{ width: "30%", marginLeft: "10%" }}
              //emplacement par défaut de souris
              inputRef={searchTextFieldRef}
              //bouton entrée
              onKeyDown={handleKeyDown}
            />
            <br />
            <form className="form" onSubmit={ajoutReglem}>
              <span className="parent-span-rgl">
                <span className="left-span-rgl">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      //  alignItems: "center",
                    }}
                  ></div>
                  <FormControl variant="standard" fullWidth>
                    <InputLabel id="modePaiement-label">
                       Type de contrat
                    </InputLabel>

                    <Select
                      labelId="modePaiement-label"
                      id="typeContrat"
                      value={typeContrat}
                      onChange={handleTypeContratChange}
                      disabled={etatInput}
                    >
                      <MenuItem value="CNS">Contrat non signé (CNS)</MenuItem>
                      <MenuItem value="CS">Contrat signé (CS)</MenuItem>
                      <MenuItem value="CE">Contrat envoyé (CE)</MenuItem>
                      {/* <MenuItem value="CNE">Contrat non envoyé (CNE)</MenuItem>
                      <MenuItem value="PDCAE">Pas de contrat à envoyer (PDC AE)</MenuItem> */}
                      <MenuItem value="PDC">Pas de contrat (PDC)</MenuItem>
                    
                    </Select>
                  </FormControl>
                  <br /> <br />
                  <TextField
                    id="standard-search"
                    label="Lien contrat"
                    type="text"
                    variant="standard"
                    value={lienContrat}
                    onChange={(e) => setLienContrat(e.target.value)}
                    style={{ width: "100%" }}
                    required
                  />
                  <br />
                  <br />
                  <br />
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      format="DD-MM-YYYY"
                      handleDateChangePaie
                      label="Date de Contrat"
                      value={selectedDateCtr}
                      onChange={handleDateChangeCtr}
                      style={{ display: "none" }}
                      disabled={etatInput}
                    />
                  </DemoContainer>
                  <br />
                   <br />
                 
                </span>
                <span className="right-span-rgl">
                  <div
                    className="LP"
                    style={{
                      // textAlign: "center",
                      fontWeight: "800",
                      fontFamily: "Arial, sans-serif",
                      fontSize: "17px",
                      color: "#EC994B",
                      margin: "10px 0",
                    }}
                  >
                    {" "}
                    {idClient} - {nomClient}
                    <br />
                  </div>
                  Mail:
                  <span className="ajoutA"> {mail} </span>
                  <br />
                  Téléphone :<span className="ajoutA"> {num} </span>
                  <br />
                  Adresse :<span className="ajoutA"> {adresse} </span>
                  <br />
                  Box : <span className="ajoutA"> {idStockage}</span> <br />
                  Volume total : <span className="ajoutA"> {vol} m³</span>
                  <br /> Date de réservation :
                  <span className="ajoutA"> {formattedDate(dateResrv)} </span>
                  <br />
                  Date d'entrée :
                  <span className="ajoutA"> {formattedDate(dateEntree)} </span>
                  <br />
                  Loyer mensuel : <span className="ajoutA"> {loyer} € </span>
                  <br /> <br />
                  <br /> <br />

                  <TextareaAutosize
                    variant="standard"
                    label="Notes"
                    minRows={3} // Définissez le nombre minimum de lignes
                    maxRows={5} // Définissez le nombre maximum de lignes
                    value={noteContrat}
                    onChange={(e) => setNoteContrat(e.target.value)}
                    style={{ width: "100%" }}
                  />
                  
                </span>
              </span>
              <Button
                className="myBouton"
                variant="contained"
                type="submit"
                sx={{ marginLeft: "40%", width: "auto", padding: "5px" }}
                disabled={etatInput}
              >
                Confirmer{" "}
              </Button>
            </form>
          </div>
        )}{" "}
        {isMobilePortrait && (
          <div className="modalContainerLoc">
            <div>
              <CancelIcon
                style={{ cursor: "pointer" }}
                sx={{ marginLeft: "90%", fontSize: 30, marginTop: "-6px" }}
                onClick={() => setAjoutContrat(false)}
              />

              <div style={{ textAlign: "center" }}>
                {" "}
                <span
                  style={{
                    display: "inline-block",
                    fontSize: 20,
                  }}
                >
                  Contrat pour la location n° {idLocation}
                </span>
              </div>
            </div>
            <br />
            <TextField
              id="standard-search"
              label="Adresse mail / numero / nom"
              type="text"
              variant="standard"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
                viderChamp();
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon
                      color="primary"
                      onClick={() => chercherLoc()}
                      style={{ cursor: "pointer" }}
                      fontSize="large"
                    />
                  </InputAdornment>
                ),
              }}
              style={{ width: "80%", marginLeft: "10%" }}
              //emplacement par défaut de souris
              inputRef={searchTextFieldRef}
              //bouton entrée
              onKeyDown={handleKeyDown}
            />
            <br />
            <div style={{ textAlign: "left", marginLeft: "10%" }}>
              <div
                className="LP"
                style={{
                  textAlign: "center",
                  fontWeight: "800",
                  fontFamily: "Arial, sans-serif",
                  fontSize: "17px",
                  color: "#EC994B",
                  margin: "10px 0",
                }}
              >
                {idClient} - {nomClient}
                <br /> Mail:
                <span className="ajoutA"> {mail} </span>
              </div>
              Box : <span className="ajoutA"> {idStockage}</span> <br />
              Volume total : <span className="ajoutA"> {vol} m³</span>
              <br /> Date de réservation :
              <span className="ajoutA"> {formattedDate(dateResrv)} </span>
              <br />
              Date d'entrée :
              <span className="ajoutA"> {formattedDate(dateEntree)} </span>
              <br />
              Loyer mensuel : <span className="ajoutA"> {loyer} € </span>
              <br /> <br />
            
            </div>
            <br />
            <form className="form" onSubmit={ajoutReglem}>
              <span className=" ">
                <span className=" ">
                  <FormControl variant="standard" style={{ width: "120%" }}>
                    <InputLabel id="modePaiement-label">
                      Type de contrat
                    </InputLabel>

                    <Select
                      labelId="modePaiement-label"
                      id="typeContrat"
                      value={typeContrat}
                      onChange={handleTypeContratChange}
                      disabled={etatInput}
                    >
               <MenuItem value="CNS">Contrat non signé (CNS)</MenuItem>
                      <MenuItem value="CS">Contrat signé (CS)</MenuItem>
                      <MenuItem value="CE">Contrat envoyé (CE)</MenuItem>
                      {/* <MenuItem value="CNE">Contrat non envoyé (CNE)</MenuItem>
                      <MenuItem value="PDCAE">Pas de contrat à envoyer (PDC AE)</MenuItem> */}
                      <MenuItem value="PDC">Pas de contrat (PDC)</MenuItem>
                    </Select>
                  </FormControl>
                  <br /> <br />
                  <TextField
                    id="standard-search"
                    label="Lien contrat"
                    type="text"
                    variant="standard"
                    value={lienContrat}
                    onChange={(e) => setLienContrat(e.target.value)}
                    style={{ width: "100%" }}
                    required
                  />
                  <br /> <br /> <br />
                  
                  <div style={{ width: "120%" }}>
                    <DemoContainer
                      components={["DatePicker"]}
                      style={{ width: "120%" }}
                    >
                      <DatePicker
                        format="DD-MM-YYYY"
                        handleDateChangePaie
                        label="Date du contrat"
                        value={selectedDateCtr}
                        onChange={handleDateChangeCtr}
                        style={{ display: "none" }}
                        disabled={etatInput}
                      />
                    </DemoContainer>
                    <br />
                   
                    
                  </div>
                </span>
                <span className=" ">
                  <br /> <br />
                   
                </span>
              </span>
              <Button
                className="myBouton"
                variant="contained"
                type="submit"
                sx={{ marginLeft: "40%", width: "auto", padding: "5px" }}
                disabled={etatInput}
              >
                Confirmer{" "}
              </Button>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};
