import Contenneurs from "./conteneur/conteneurPage/contenneurs";
import Etage from "./etage/etagePage/etage";
import Rees from "./RDC/RDCpage/rees";
import "./stockageStyle.css";
import {Autocomplete,TextField, Modal } from "@mui/material";
import axios from "axios";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import React,{ useState, useEffect, useRef } from "react";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Snackbar from "@mui/material/Snackbar";
import { Divider } from 'primereact/divider';
import "primereact/resources/themes/lara-light-cyan/theme.css";
import correctEncoding from "../correctEncoding";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import mapTypePaie from "../mapTypePaie";
import { AjoutLocation } from "../location/locationPage/ajoutLocation";
import { AjoutClient } from "../client/ClientPage/ajoutClient";
import { AjoutReglement } from "../reglement/reglementPage/AjoutReglement";
import { AjoutContrat } from "../contrat/contratPage/ajoutContrat";
import { AjoutFacture } from "../facture/facturePage/ajoutFacture";
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';

export const RechercheStockage = ({
  search,
  setSearch,
  motRecherche,
  setMotRecherche,
  motRechercheClient,
  setMotRechercheClient
}) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [infoStock, setInfoStock] = useState([]);
  const [enCours, setEnCours] = useState("");
  const [etat, setEtat] = useState("");
  const [placesData, setPlacesData] = useState({});
  const [txtPDefaut, setTxtPDefaut] = useState(true);
  const searchTextFieldRef = useRef(null);
  const [vol, setVol] = useState(0);
  const [nom, setNom] = useState("");
  const [mail, setMail] = useState("");
  const [num, setNum] = useState("");
  const [dateEntree, setDateEntree] = useState("");
  const [dateSortie, setDateSortie] = useState("");
  const [loyer, setLoyer] = useState(0);
  const [paieData, setPaieData] = useState([]);
  const [idStockDuClient, setIdStockDuClient] = useState("");
  const [typeContrat, setTypeContrat] = useState("");
  const [dateContrat, setDateContrat] = useState("");
  const [numContrat, setNumContrat] = useState("");
  const [numFact, setNumFact] = useState("");
  const [dateFacture, setDateFacture] = useState("");

  const [debFacture, setDebFacture] = useState("");
  const[finFacture,setFinFacture] = useState("");

  const [typeFacture, setTypeFacture] = useState("");
  const [copiedText, setCopiedText] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const [totalStock, setTotalStock] = useState("");

  const [totVol, setTotVol] = useState("");

  const [suggestions, setSuggestions] = useState([]);

  const [visible, setVisible] = useState(false);
  const [ajoutContrat, setAjoutContrat] = useState(false);
  const [ajoutReglement, setAjoutReglement] = useState(false);
  const [afficheAjoutFact, setAfficheAjoutFact] = useState(false);
  const [afficherAjoutLoc, setAfficherAjoutLoc] = useState(false);


  const handleFetchSuggestions = async (query) => {
    try {
      const response = await axios.get(`${apiUrl}/IHM/api/suggestion.php?word=${query}`);
      setSuggestions(response.data);
    } catch (error) {
      console.error("Erreur lors de la récupération des suggestions", error);
    }
  };

  useEffect(() => {
    axios.get(`${apiUrl}IHM/api/stockage.php`).then((response) => {
      const listeStockages = response.data;

      listeStockages.sort(
        (a, b) =>
          a.place.localeCompare(b.place) ||
          String(a.vol).localeCompare(String(b.vol))
      );

      const placesMap = {};
      listeStockages.forEach((item) => {
        if (!placesMap[item.place]) {
          placesMap[item.place] = {};
        }
        if (!placesMap[item.place][item.vol]) {
          placesMap[item.place][item.vol] = [item.idStockage];
        } else {
          placesMap[item.place][item.vol].push(item.idStockage);
        }
      });

      setPlacesData(placesMap);
    });
  }, []);

  const getPlaceLabel = (place) => {
    switch (place) {
      case "B":
        return "Rée de chaussée";
      case "H":
        return "Étage";
      default:
        return "Extérieur";
    }
  };

  const handleRecherche = () => {
    setMotRechercheClient("");
    if (motRecherche === "") {
      // Si le champ de recherche est vide, réinitialisez les styles et sortez de la fonction
      const divs = document.querySelectorAll(".stockRetard");
      divs.forEach((div) => {
        div.classList.remove("highlight-red");
      });
      return;
    }
    const regex = new RegExp(`\\b${motRecherche}\\b`, "i");// Utilisez une expression régulière pour correspondre au mot exact

    const divs = document.querySelectorAll(".stockRetard");
    divs.forEach((div) => {
      const texteDiv = div.textContent;
      //  recherche
      if (regex.test(texteDiv)) {
        // Si le texte de la div correspond à la recherche, appliquez le style
        div.classList.add("highlight-red");
        setTimeout(() => {
          div.classList.remove("highlight-red");
        }, 2500);

        // Calcul de la position et défilement de la page
        const windowHeight = window.innerHeight;
        const divTopOffset = div.getBoundingClientRect().top;
        const scrollPosition = divTopOffset - windowHeight / 2;

        window.scrollBy({
          top: scrollPosition,
          behavior: "smooth",
        });
        info();
      } else {


      }
    });


  };


  function rechercherEtMettreEnEvidence(motRecherche) {
    const regex = new RegExp(`\\b${motRecherche}\\b`, "i"); // Utilisez une expression régulière pour correspondre au mot exact

    const divs = document.querySelectorAll(".stockRetard");
    divs.forEach((div) => {
      const texteDiv = div.textContent;
      // Recherche
      if (regex.test(texteDiv)) {
        // Si le texte de la div correspond à la recherche, appliquez le style
        div.classList.add("highlight-red");
        setTimeout(() => {
          div.classList.remove("highlight-red");
        }, 2500);

        // Calcul de la position et défilement de la page
        const windowHeight = window.innerHeight;
        const divTopOffset = div.getBoundingClientRect().top;
        const scrollPosition = divTopOffset - windowHeight / 2;

        window.scrollBy({
          top: scrollPosition,
          behavior: "smooth",
        });


      }
    });
  }

  const handleRechercheClient = () => {
    setMotRecherche("");
    if (motRechercheClient == "") { return; }
    recInfoStockClient(motRechercheClient);
  };

  const info = () => {
    setTxtPDefaut(false);

    recInfoStock(motRecherche);
    recInfoBox(motRecherche);
    


  };
  useEffect(() => {
    if (search) {
      info();
      setSearch(false);
    }
  }, [search]);
  //Emplacement par défaut de curseur
  useEffect(() => {
    if (searchTextFieldRef.current) {
      searchTextFieldRef.current.focus();
    }
  }, []);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleRecherche();
      setMotRechercheClient("");
    }
  };

  // recherche à partir du nom client
  const handleKeyDownClient = (e) => {
    if (e.key === "Enter") {
      handleRechercheClient();
      setMotRecherche("");
    }
  };
  const recInfoBox = (param) => {
    try {
      axios

        .get(`${apiUrl}IHM/api/stockageRetard.php?idStockage=${param}`)
        .then((response) => {
          setVol(response.data[0][0].vol);
        });
    } catch (error) {
      setInfoStock([]);
      if (error.response) {
        console.error("Erreur de réponse du serveur :", error.response.data);
      } else if (error.request) {
        console.error("Pas de réponse du serveur :", error.request);
      } else {
        console.error("Erreur lors de la requête :", error.message);
      }
    }
  };

  const recDernPaie = (param) => {
    try {
      setPaieData([]);
      axios

        .get(`${apiUrl}IHM/api/paiement.php?idLoc=${param}`)
        .then((response) => {
          if (response.data.length > 0) {
            setPaieData(response.data);
          }


        });
    } catch (error) {
      setInfoStock([]);
      if (error.response) {
        console.error("Erreur de réponse du serveur :", error.response.data);
      } else if (error.request) {
        console.error("Pas de réponse du serveur :", error.request);
      } else {
        console.error("Erreur lors de la requête :", error.message);
      }
    }
  };
  const recInfoStock = (param) => {
    try {
      setDateContrat("");
      setTypeContrat("");
      setNumContrat("");
      setDateFacture("");
      setTypeFacture("");
      setNumFact("");
      setInfoStock([]);
      axios
//stockageRetard.php si on veut les infos sur le dernier locataire
        .get(`${apiUrl}IHM/api/stockage.php?infoStock=${param}`)
        .then((response) => {
          setInfoStock(response.data);

          if (response.data.length > 0) {

            setTotalStock(response.data[0].concatIdstockage);
            setVol(response.data[0].vol);
            setTotVol(response.data[0].totVolume);
            setDateEntree(response.data[0].dateEntree);
            setDateSortie(response.data[0].dateSortie);
            setEnCours(response.data[0].enCours);
            setLoyer(response.data[0].loyer);
            setNom(response.data[0].nom);
            setMail(response.data[0].mail);
            setNum(response.data[0].num1);
            recDernPaie(response.data[0].idLocation);
            setIdStockDuClient(response.data[0].idStockage);
            recContrat(response.data[0].idClient);
            recFacture(response.data[0].idClient);
          }

          else {
            console.log("makato 3");
          }
        });
    } catch (error) {
      setInfoStock([]);
      if (error.response) {
        console.error("Erreur de réponse du serveur :", error.response.data);
      } else if (error.request) {
        console.error("Pas de réponse du serveur :", error.request);
      } else {
        console.error("Erreur lors de la requête :", error.message);
      }
    }
  };

  // recherche à partir du nom client , alors que info() se fait quan on recherche un box
  const recInfoStockClient = (param) => {
    try {
      setDateContrat("");
      setTypeContrat("");
      setNumContrat("");
      setDateFacture("");
      setTypeFacture("");
      setNumFact("");
      setInfoStock([]);
      axios
        .get(`${apiUrl}IHM/api/stockageRetard.php?infoStockClient=${param}`)
        .then((response) => {
          setInfoStock(response.data);
          console.log("recherce par nom", response.data)
          if (response.data.length > 0) {
            setTotalStock(response.data[0].concatIdstockage);
            setVol(response.data[0].vol);
            setTotVol(response.data[0].totVolume);
            setDateEntree(response.data[0].dateEntree);
            setDateSortie(response.data[0].dateSortie);
            setEnCours(response.data[0].enCours);
            setLoyer(response.data[0].loyer);
            setNum(response.data[0].num1);
            setNom(response.data[0].nom);
            setMail(response.data[0].mail);
            recDernPaie(response.data[0].idLocation);
            
            setIdStockDuClient(response.data[0].idStockage);
            setTxtPDefaut(false);
            // recupere l'information du box du client
            recInfoBox(response.data[0].idStockage);
            rechercherEtMettreEnEvidence(response.data[0].idStockage);
            recContrat(response.data[0].idClient);
            recFacture(response.data[0].idClient);
          }
        
        });
    } catch (error) {
      setInfoStock([]);
      if (error.response) {
        console.error("Erreur de réponse du serveur :", error.response.data);
      } else if (error.request) {
        console.error("Pas de réponse du serveur :", error.request);
      } else {
        console.error("Erreur lors de la requête :", error.message);
      }
    }
  };


  const formattedDate = (dateRecup) => {
    if (dateRecup === "0000-00-00" || dateRecup === "" || dateRecup === null) {
      return ``;
    }

    const date = new Date(dateRecup);

    // Options pour le formatage
    const options = {
      day: 'numeric',   // Jour du mois
      month: 'numeric',    // Mois complet (janvier, février, etc.)
      year: 'numeric'   // Année complète
    };

    // Formatage de la date avec les options spécifiées
    return date.toLocaleDateString('fr-FR', options);
  };

  const recContrat = (param) => {
    try {
      axios

        .get(`${apiUrl}IHM/api/contrat.php?idClientCtr=${param}`)
        .then((response) => {
          if (response.data.length > 0) {
            setDateContrat(response.data[0].dateContrat);
            setTypeContrat(response.data[0].typeContrat);
            setNumContrat(response.data[0].idContrat);
          }

          else {
            console.log("makato 3");

          }
        });
    } catch (error) {

      if (error.response) {
        console.error("Erreur de réponse du serveur :", error.response.data);
      } else if (error.request) {
        console.error("Pas de réponse du serveur :", error.request);
      } else {
        console.error("Erreur lors de la requête :", error.message);
      }
    }
  };

  const recFacture = (param) => {
    try {


      axios

        .get(`${apiUrl}IHM/api/facture.php?idClientFct=${param}`)
        .then((response) => {


          if (response.data.length > 0) {
            setDateFacture(response.data[0].dateEnv);
            setDebFacture(response.data[0].dateDebut);
            setFinFacture(response.data[0].dateFin);   
            setTypeFacture(response.data[0].typeFact);
            setNumFact(response.data[0].numFact);

          }

          else {
            console.log("makato 3");

          }
        });
    } catch (error) {

      if (error.response) {
        console.error("Erreur de réponse du serveur :", error.response.data);
      } else if (error.request) {
        console.error("Pas de réponse du serveur :", error.request);
      } else {
        console.error("Erreur lors de la requête :", error.message);
      }
    }
  };
  function mapTypeCtr(typeCtr) {
    switch (typeCtr) {
      case "CNS":
        return "Contrat non signé";
      case "CS":
        return "Contrat signé ";
      case "CE":
        return "Contrat envoyé";
      case "PDC":
        return "Pas de contrat";
      default:
        return typeCtr;
    }
  }

  const handleClick = (text) => {
    setMotRecherche(text);
    handleRecherche();
  };


  const copyToClipboard = (text) => {
    if (text !== "") {
        if (navigator.clipboard && navigator.clipboard.writeText) {
            navigator.clipboard
                .writeText(text)
                .then(() => {
                    setCopiedText(text);
                    setOpenSnackbar(true);
                })
                .catch((error) => {
                    console.error(
                        "Erreur lors de la copie dans le presse-papiers : ",
                        error
                    );
                });
        } else {
            // Utiliser l'API de sélection de texte comme solution de secours
            const textArea = document.createElement("textarea");
            textArea.value = text;
            document.body.appendChild(textArea);
            textArea.select();
            document.execCommand("copy");
            document.body.removeChild(textArea);

            setCopiedText(text);
            setOpenSnackbar(true);
        }
    }
};
const handleCloseSnackbar = () => {
  setOpenSnackbar(false);
};


  return (
    <div className="">

      <Modal
        open={afficherAjoutLoc}
        disableBackdropClick={true}
        aria-describedby="modal-modal-description"
      >
        <AjoutLocation
          idSelected={motRecherche}
          afficherAjoutLoc={afficherAjoutLoc}
          setAfficherAjoutLoc={setAfficherAjoutLoc}
        />
      </Modal>
      <Modal
        open={visible}
        disableBackdropClick={true}
        aria-describedby="modal-modal-description"
      >
        <AjoutClient
          setVisible={setVisible}
          onClose={() => {
            setVisible(false);
          }}
        />
      </Modal>
      <Modal
        open={ajoutContrat}
        disableBackdropClick={true}
        aria-describedby="modal-modal-description"
      >
        <AjoutContrat setAjoutContrat={setAjoutContrat} />
      </Modal>
      <Modal
        open={afficheAjoutFact}
        disableBackdropClick={true}
        aria-describedby="modal-modal-description"
      >
        <AjoutFacture
          setAfficheAjoutFact={setAfficheAjoutFact}
          onClose={() => {
            setAfficheAjoutFact(false);
          }}
        // setIdInfoLoc={setIdInfoLoc}
        />
      </Modal>
      <Modal
        open={ajoutReglement}
        disableBackdropClick={true}
        aria-describedby="modal-modal-description"
      >
        <AjoutReglement setAjoutReglement={setAjoutReglement} />
      </Modal>




      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={openSnackbar}
        autoHideDuration={1000}
        onClose={handleCloseSnackbar}
        message={`Copie réussie de " ${copiedText === "location.mail" ? "e-mail" : copiedText
          } "`}
        action={
          <React.Fragment>

            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleCloseSnackbar}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
      <div className="">
        <div>
          <TextField
            style={{ margin: "0%", width: "90%" }}
            label="Box ou container"
            variant="outlined"
            value={motRecherche}
            onChange={(e) => {
              setMotRecherche(e.target.value.trimStart());
              setTxtPDefaut(true);
              setInfoStock([]);
            }}
            inputRef={searchTextFieldRef}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon
                    color="primary"
                    onClick={handleRecherche}
                    style={{ cursor: "pointer" }}
                    fontSize="large"
                  />
                </InputAdornment>
              ),
            }}
            onKeyDown={handleKeyDown}
          />
        </div>
        <Divider />
        <div>
          <Autocomplete
            freeSolo
            options={suggestions}
            getOptionLabel={(option) => option.nom} // Utiliser seulement le nom dans le champ TextField
            onInputChange={(e, value) => {
              setMotRechercheClient(value);
              handleFetchSuggestions(value); // Fetch suggestions as user types
              setTxtPDefaut(true);
              setInfoStock([]);
            }}
            onChange={(event, newValue) => {
              if (newValue) {
                setMotRechercheClient(newValue.nom); // Assigner seulement le nom lorsque l'utilisateur sélectionne une suggestion
              }
            }}
            renderOption={(props, option) => (
              <li {...props} style={{ fontSize: "11px" }}>
                {option.nom} (Box: {option.idStockage.split('||').join(',')}) {/* Afficher le nom et le numéro de box dans la liste */}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Client"
                variant="outlined"
                value={motRechercheClient}
                style={{ margin: "0%", width: "90%" }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon
                        color="primary"
                        onClick={handleRechercheClient}
                        style={{ cursor: "pointer" }}
                        fontSize="large"
                      />
                    </InputAdornment>
                  ),
                }}
                onKeyDown={handleKeyDownClient} // Rechercher lorsque "Entrée" est appuyée
              />
            )}
          />
        </div>
        {txtPDefaut ? (
          <div style={{ marginLeft: "0%", padding: "10px" }}>
            <h5 style={{ marginLeft: "0%" }}>Liste des stockages libres :</h5>
            {Object.keys(placesData).map((place, placeIndex) => (
              <div key={placeIndex} style={{ marginBottom: "20px" }}>
                <h4 style={{ marginLeft: "0%", marginBottom: "10px" }}>{getPlaceLabel(place)} :</h4>
                {Object.keys(placesData[place]).map((volume, volIndex) => (
                  <div key={volIndex} style={{ marginBottom: "15px" }}>
                    <strong style={{ display: "block", marginBottom: "5px" }}>{volume} m³ :</strong>
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                      {placesData[place][volume].map((box, boxIndex) => (
                        <div
                          key={boxIndex}
                          style={{
                            cursor: "pointer",
                            margin: "5px",
                            padding: "8px",
                            border: "1px solid #ddd",
                            borderRadius: "4px",
                            backgroundColor: "#f9f9f9",
                            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                            textAlign: "center",
                            fontSize: "14px"
                          }}
                          onClick={() => handleClick(box)}
                        >
                          {box}
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>

        ) : (
          <div>
            {infoStock.length > 0 ? (
              <div className="etat">
                <span>
                  <span>
                    {enCours === "non" ? (
                      <>
                        <h3>Box: {motRecherche} - LIBRE</h3>
                        <br />
                        <br />
                        <h4 style={{ marginLeft: "20px" }}>
                          {" "}
                          Info du dernier locataire
                        </h4>
                      </>
                    ) : (
                      <h3>Box: {motRecherche == "" ? (idStockDuClient) : (motRecherche)} - OCCUPE</h3>
                    )}
                  </span>
                </span>{" "}
                {infoStock.length > 0 && (
                  <div >
                    <div style={{ marginBottom: "10px" }}>
                      <strong style={{ color: "#333", fontSize: "14px" }}>📦V</strong>
                      <span style={{ marginLeft: "5px", fontSize: "14px", color: "#333" }}>{vol} m³</span>

                      <strong style={{ color: "#333", fontSize: "14px" }}> Loyer</strong>
                      <span style={{ marginLeft: "5px", fontSize: "14px", color: "#333" }}>{loyer} €</span>

                    </div>
                    <div style={{ marginBottom: "10px" }}>
                      <strong style={{ color: "#333", fontSize: "14px" }}>👤</strong>
                      <span style={{ marginLeft: "5px", fontSize: "14px", color: "#333" ,cursor:"pointer"}} onClick={() => copyToClipboard(mail)}>{correctEncoding(nom)} <ContentCopyIcon sx={{fontSize:10}}/></span>
                    </div>
                    <div style={{ marginBottom: "10px" }}>
                      <strong style={{ color: "#333", fontSize: "14px" }}>📱</strong>
                      <span style={{ marginLeft: "5px", fontSize: "14px", color: "#333" ,cursor:"pointer"}} onClick={() => copyToClipboard(num)}>{num}{" "}<ContentCopyIcon sx={{fontSize:10}}/></span>
                    </div>
                    <Divider />
                  </div>
                )}
                {totalStock !== motRecherche && totalStock !== "" ? (
                  <div>
                    <div style={{ marginBottom: "10px", fontSize: "14px", color: "#333" }}>
                      📦<strong style={{ color: "#333" }}>Box de {correctEncoding(nom)} </strong>
                      <span style={{ marginLeft: "5px" }}>{totalStock.split('||').join(',')}</span>
                    </div>
                    <div style={{ fontSize: "14px", color: "#333" }}>
                      📏<strong style={{ color: "#333" }}>Vtotal </strong>
                      <span style={{ marginLeft: "5px" }}>{totVol} m³</span>
                    </div>
                    <Divider />
                  </div>
                ) : (
                  ""
                )}

                {/* Section des factures */}
                {numFact ?(
                  <div>
                  <div style={{ fontSize: "14px", color: "#333", marginBottom: "10px" }}>
                  <strong>Facture n° </strong> {numFact}
                  
                  </div>
                  <div style={{ fontSize: "14px", color: "#333", marginBottom: "10px" }}>
                  <strong>Date : </strong>{formattedDate(dateFacture)}
                   
                  </div>
                  <div style={{ fontSize: "14px", color: "#333", marginBottom: "10px" }}>
                    <strong>Type :</strong> {typeFacture}
                  </div>
                  <div style={{ fontSize: "14px", color: "#333" }}>
                    <strong>P :</strong> {formattedDate(debFacture)} au {formattedDate(finFacture)}
                  </div>
                  <Divider />
                </div>
                ) : (
                  <div style={{ fontSize: "14px", color: "#666", marginBottom: "20px" }}>
                    Aucune facture <AddCircleRoundedIcon 
                    sx={{fontSize:20,verticalAlign:"middle",marginTop:"calc(.5ex - .5cap)",cursor:"pointer"}}
                    color="primary"
                    onClick={()=>
                       setAfficheAjoutFact(true)
                    }
                   />
                  </div>
                )}
                

                <div>
                  {/* Section des paiements */}
                  {paieData.length > 0 ? (
                    <div style={{ marginBottom: "20px" }}>
                      <div style={{ fontSize: "14px", fontWeight: "bold", color: "#333", marginBottom: "10px" }}>
                        Paiement
                      </div>
                      {paieData.map((client) => (
                        <div key={client.idPaiement}>
                          <div style={{ marginBottom: "8px" ,fontSize: "14px"}}>
                            <strong style={{ color: "#333" }}>Date :</strong> <span>{formattedDate(client.datePaie)}</span>
                          </div>
                          <div style={{ marginBottom: "8px" ,fontSize: "14px"}}>
                            <strong style={{ color: "#333" }}>Somme :</strong> <span>{client.valeurPaie} €</span> ({mapTypePaie(client.typePaie)})
                          </div>
                          <div style={{ marginBottom: "8px" ,fontSize: "14px"}}>
                            <strong style={{ color: "#333" }}>P:</strong> <span style={{fontSize : "14px"}}>{formattedDate(client.dateDebut)} au {formattedDate(client.dateFin)}</span>
                          </div>
                        </div>

                      ))}
                    </div>
                  ) : (
                    <div style={{ fontSize: "14px", color: "#666", marginBottom: "20px" }}>
                      Aucun paiement <AddCircleRoundedIcon
                       sx={{fontSize:20,verticalAlign:"middle",marginTop:"calc(.5ex - .5cap)",cursor:"pointer"}} 
                       color="primary"
                       onClick={() => setAjoutReglement(true)}
                        />
                    </div>
                  )}

                  <Divider />


                  {numContrat ? (
                      <div>
                        <div style={{ marginBottom: "10px", fontSize: "14px", color: "#333" }}>
                          📄 <strong style={{ color: "#333" }}>Contrat n°{numContrat}</strong> du
                          <span style={{ marginLeft: "5px" }}>{formattedDate(dateContrat)}</span>
                        </div>
                        <div style={{ fontSize: "14px", color: "#333" }}>
                          📑 <strong style={{ color: "#333" }}>Type :</strong>
                          <span style={{ marginLeft: "5px" }}>{mapTypeCtr(typeContrat)}</span>
                        </div>
                        <Divider />
                      </div>
                    ) : (
                      <div style={{ fontSize: "14px", color: "#666", marginBottom: "20px" }}>
                        Aucun Contrat <AddCircleRoundedIcon
                          sx={{ fontSize: 20, verticalAlign: "middle", marginTop: "calc(.5ex - .5cap)", cursor: "pointer" }}
                          color="primary"
                          onClick={() =>
                            setAjoutContrat(true)
                          }
                        />
                      </div>
                    )
                    }

                  </div>



              </div>
            ) : (
              <div className="etat">
                <h3 style={{ marginLeft: "20%" }}>
                  Box {motRecherche}
                  {"   "} - {"   "}
                  LIBRE
                </h3>
                <br />
                Volume : {vol} m³ <br />
              </div>
            )}

            <br />
          </div>
        )}
      </div>
    </div>
  );
};
