import Contenneurs from "./conteneur/conteneurPage/contenneurs";
import Etage from "./etage/etagePage/etage";
import Rees from "./RDC/RDCpage/rees";
import "./stockageStyle.css";
import correctEncoding from "../correctEncoding";
import { Autocomplete, TextField, Button } from "@mui/material";
import axios from "axios";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Snackbar from "@mui/material/Snackbar";
import React,{ useState, useEffect, useRef } from "react";
import { Divider } from 'primereact/divider';
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import EmailLink from "../sendMail";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { width } from "@mui/system";
import mapTypePaie from "../mapTypePaie";
export const RechercheStockageMob = ({
  search,
  setSearch,
  motRecherche,
  setMotRecherche,
  motRechercheClient,
  setMotRechercheClient,
  setValue
}) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [infoStock, setInfoStock] = useState([]);
  const [enCours, setEnCours] = useState("");
  const [etat, setEtat] = useState("");
  const [placesData, setPlacesData] = useState({});
  const [txtPDefaut, setTxtPDefaut] = useState(true);
  const searchTextFieldRef = useRef(null);
  const [vol, setVol] = useState(0);
  const [nom, setNom] = useState("");
  const [mail, setMail] = useState("");
  const [dateEntree, setDateEntree] = useState("");
  const [dateSortie, setDateSortie] = useState("");
  const [loyer, setLoyer] = useState(0);
  const [paieData, setPaieData] = useState([]);
  const [idStockDuClient, setIdStockDuClient] = useState("")
  const [placeRecup, setplaceRecup] = useState("B");
  const [totalStock, setTotalStock] = useState("");
  const [num, setNum] = useState("");

  const [typeContrat, setTypeContrat] = useState("");
  const [dateContrat, setDateContrat] = useState("");
  const [numContrat, setNumContrat] = useState("");
  const [numFact, setNumFact] = useState("");
  const [dateFacture, setDateFacture] = useState("");
  const[debFacture,setDebFacture] = useState("");
  const [finFacture,setFinFacture] = useState("");
 

  const [typeFacture, setTypeFacture] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [copiedText, setCopiedText] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const [totVol, setTotVol] = useState("");

  const handleFetchSuggestions = async (query) => {
    try {
      const response = await axios.get(`${apiUrl}/IHM/api/suggestion.php?word=${query}`);
      setSuggestions(response.data);
    } catch (error) {
      console.error("Erreur lors de la récupération des suggestions", error);
    }
  };


  useEffect(() => {
    axios.get(`${apiUrl}IHM/api/stockage.php`).then((response) => {
      const listeStockages = response.data;

      listeStockages.sort(
        (a, b) =>
          a.place.localeCompare(b.place) ||
          String(a.vol).localeCompare(String(b.vol))
      );


      const placesMap = {};
      listeStockages.forEach((item) => {
        if (!placesMap[item.place]) {
          placesMap[item.place] = {};
        }
        if (!placesMap[item.place][item.vol]) {
          placesMap[item.place][item.vol] = [item.idStockage];
        } else {
          placesMap[item.place][item.vol].push(item.idStockage);
        }
      });

      setPlacesData(placesMap);
    });
  }, []);
  useEffect(() => {
    handleChangePlace();
  }, [placeRecup]);


  const getPlaceLabel = (place) => {
    switch (place) {
      case "B":
        return "Rée de chaussée";
      case "H":
        return "Étage";
      default:
        return "Extérieur";
    }
  };

  const handleClick = (text) => {
    setMotRecherche(text);
    handleRecherche();
  };

  const handleRecherche = () => {
    setMotRechercheClient("");
    if (motRecherche === "") {
      // Si le champ de recherche est vide, réinitialisez les styles et sortez de la fonction
      const divs = document.querySelectorAll(".stock");
      divs.forEach((div) => {
        div.classList.remove("highlight-yellow");
      });
      return;
    }

    recInfoBox(motRecherche);
    const regex = new RegExp(`\\b${motRecherche}\\b`, "i");// Utilisez une expression régulière pour correspondre au mot exact

    const divs = document.querySelectorAll(".stock");
    divs.forEach((div) => {
      const texteDiv = div.textContent;
      //  recherche
      if (regex.test(texteDiv)) {
        // Si le texte de la div correspond à la recherche, appliquez le style
        div.classList.add("highlight-yellow");
        setTimeout(() => {
          div.classList.remove("highlight-yellow");
        }, 2500);

        // Calcul de la position et défilement de la page
        const windowHeight = window.innerHeight;
        const divTopOffset = div.getBoundingClientRect().top;
        const scrollPosition = divTopOffset - windowHeight / 2;

        window.scrollBy({
          top: scrollPosition,
          behavior: "smooth",
        });
        info();
      } else {

      }
    });


  };


  function rechercherEtMettreEnEvidence(param) {
    const regex = new RegExp(`\\b${param}\\b`, "i"); // Utilisez une expression régulière pour correspondre au mot exact

    const divs = document.querySelectorAll(".stock");
    divs.forEach((div) => {
      const texteDiv = div.textContent;
      // Recherche
      if (regex.test(texteDiv)) {
        // Si le texte de la div correspond à la recherche, appliquez le style
        div.classList.add("highlight-yellow");
        setTimeout(() => {
          div.classList.remove("highlight-yellow");
        }, 2500);

        // Calcul de la position et défilement de la page
        const windowHeight = window.innerHeight;
        const divTopOffset = div.getBoundingClientRect().top;
        const scrollPosition = divTopOffset - windowHeight / 2;

        window.scrollBy({
          top: scrollPosition,
          behavior: "smooth",
        });


      }
    });
  }

  const handleRechercheClient = () => {
    setMotRecherche("");
    if (motRechercheClient == "") { return; }
    recInfoStockClient(motRechercheClient);
  };

  const info = () => {
    setTxtPDefaut(false);
    recInfoStock(motRecherche);
    recInfoBox(motRecherche); 
  };
  useEffect(() => {
    if (search) {
      info();
      setSearch(false);
    }
  }, [search]);
  //Emplacement par défaut de curseur
  useEffect(() => {
    if (searchTextFieldRef.current) {
      searchTextFieldRef.current.focus();
    }
  }, []);

  const handleKeyDown = (e) => {
    setInfoStock([]);
    setPaieData([]);
    setIdStockDuClient("");
    if (e.key === "Enter") {


      handleRecherche();
      setMotRechercheClient("");
    }
  };

  // recherche à partir du nom client
  const handleKeyDownClient = (e) => {
    setInfoStock([]);
    setPaieData([]);
    setIdStockDuClient("");
    if (e.key === "Enter") {
      handleRechercheClient();
      setMotRecherche("");
    }
  };
  const handleChangePlace = (e) => {
    if (placeRecup === "B") {
      setValue("1");
    } else if (placeRecup === "H") {
      setValue("2");
    } else {
      setValue("3");
    }
  };

  const recInfoBox = (param) => {
    try {
      axios

        .get(`${apiUrl}IHM/api/stockageRetard.php?idStockage=${param}`)
        .then((response) => {

            setVol(response.data[0][0].vol);
            console.log("DataTEDAVIKO", response.data[0][0])
            setplaceRecup(response.data[0][0].place);
            recInfoStock(param);
            rechercherEtMettreEnEvidence(param);    

        });
    } catch (error) {
      setInfoStock([]);
      if (error.response) {
        console.error("Erreur de réponse du serveur :", error.response.data);
      } else if (error.request) {
        console.error("Pas de réponse du serveur :", error.request);
      } else {
        console.error("Erreur lors de la requête :", error.message);
      }
    }
  };

  const recDernPaie = (param) => {
    try {
      setPaieData([]);
      axios

        .get(`${apiUrl}IHM/api/paiement.php?idLoc=${param}`)
        .then((response) => {
          if (response.data.length > 0) {
            setPaieData(response.data);
          }
        });
    } catch (error) {
      setInfoStock([]);
      if (error.response) {
        console.error("Erreur de réponse du serveur :", error.response.data);
      } else if (error.request) {
        console.error("Pas de réponse du serveur :", error.request);
      } else {
        console.error("Erreur lors de la requête :", error.message);
      }
    }
  };
  const recInfoStock = (param) => {
    try {
      setDateContrat("");
      setTypeContrat("");
      setNumContrat("");
      setDateFacture("");
      setTypeFacture("");
      setNumFact("");
      setInfoStock([]);
      axios
        //stockageRetard.php si on veut les infos sur le dernier locataire
        .get(`${apiUrl}IHM/api/stockage.php?infoStock=${param}`)
        .then((response) => {
          setInfoStock(response.data);

          if (response.data.length > 0) {


            console.log(infoStock);

            setTotalStock(response.data[0].concatIdstockage);
            setVol(response.data[0].vol);
            setTotVol(response.data[0].totVolume);
            setDateEntree(response.data[0].dateEntree);
            setDateSortie(response.data[0].dateSortie);
            setEnCours(response.data[0].enCours);
            setLoyer(response.data[0].loyer);
            setNom(response.data[0].nom);
            setMail(response.data[0].mail);
            setNum(response.data[0].num1);
            recDernPaie(response.data[0].idLocation);
            setTxtPDefaut(false);
            recContrat(response.data[0].idClient);
            recFacture(response.data[0].idClient);
            setIdStockDuClient(response.data[0].idStockage);
          }

          else {
            setTxtPDefaut(false);
          }
        });
    } catch (error) {

      if (error.response) {
        console.error("Erreur de réponse du serveur :", error.response.data);
      } else if (error.request) {
        console.error("Pas de réponse du serveur :", error.request);
      } else {
        console.error("Erreur lors de la requête :", error.message);
      }
    }
  };

  // recherche à partir du nom client , alors que info() se fait quan on recherche un box
  const recInfoStockClient = (param) => {
    try {
      setDateContrat("");
      setTypeContrat("");
      setNumContrat("");
      setDateFacture("");
      setTypeFacture("");
      setNumFact("");
      setInfoStock([]);
      axios

        .get(`${apiUrl}IHM/api/stockageRetard.php?infoStockClient=${param}`)
        .then((response) => {
          setInfoStock(response.data);
          console.log("recherce par nomMMMM", response.data)
          if (response.data.length > 0) {
            setTotalStock(response.data[0].concatIdstockage);
            setVol(response.data[0].vol);
            setTotVol(response.data[0].totVolume);
            setDateEntree(response.data[0].dateEntree);
            setDateSortie(response.data[0].dateSortie);
            setEnCours(response.data[0].enCours);
            setLoyer(response.data[0].loyer);
            setNom(response.data[0].nom);
            setMail(response.data[0].mail);
            setNum(response.data[0].num1);
            recDernPaie(response.data[0].idLocation);

            setIdStockDuClient(response.data[0].idStockage);
            setTxtPDefaut(false);
            // recupere l'information du box du client
            recInfoBox(response.data[0].idStockage);
            rechercherEtMettreEnEvidence(response.data[0].idStockage)

            recContrat(response.data[0].idClient);
            recFacture(response.data[0].idClient);

          } else {

            setTxtPDefaut(false);

          }


        });

    } catch (error) {
      setInfoStock([]);
      if (error.response) {
        console.error("Erreur de réponse du serveur :", error.response.data);
      } else if (error.request) {
        console.error("Pas de réponse du serveur :", error.request);
      } else {
        console.error("Erreur lors de la requête :", error.message);
      }
    }
  };


  const formattedDate = (dateRecup) => {
    if (dateRecup === "0000-00-00" || dateRecup === "" || dateRecup === null) {
      return ``;
    }
    const date = new Date(dateRecup);
    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    const month =
      date.getMonth() + 1 < 10
        ? `0${date.getMonth()}`
        : date.getMonth().toString();
    const year = date.getFullYear();
    return `${day}/${(parseInt(month) + 1)
      .toString()
      .padStart(2, "0")}/${year}`;
  };

  const recContrat = (param) => {
    try {
      axios

        .get(`${apiUrl}IHM/api/contrat.php?idClientCtr=${param}`)
        .then((response) => {
          if (response.data.length > 0) {
            setDateContrat(response.data[0].dateContrat);
            setTypeContrat(response.data[0].typeContrat);
            setNumContrat(response.data[0].idContrat);
          }

          else {
            console.log("makato 3");

          }
        });
    } catch (error) {

      if (error.response) {
        console.error("Erreur de réponse du serveur :", error.response.data);
      } else if (error.request) {
        console.error("Pas de réponse du serveur :", error.request);
      } else {
        console.error("Erreur lors de la requête :", error.message);
      }
    }
  };

  const recFacture = (param) => {
    try {
      axios
        .get(`${apiUrl}IHM/api/facture.php?idClientFct=${param}`)
        .then((response) => {


          if (response.data.length > 0) {
            setDateFacture(response.data[0].dateEnv);
            setDebFacture(response.data[0].dateDebut);
            setFinFacture(response.data[0].dateFin);
            setTypeFacture(response.data[0].typeFact);
            setNumFact(response.data[0].numFact);

          }

          else {
            console.log("makato 3");

          }
        });
    } catch (error) {

      if (error.response) {
        console.error("Erreur de réponse du serveur :", error.response.data);
      } else if (error.request) {
        console.error("Pas de réponse du serveur :", error.request);
      } else {
        console.error("Erreur lors de la requête :", error.message);
      }
    }
  };
  function mapTypeCtr(typeCtr) {
    switch (typeCtr) {
      case "CNS":
        return "Contrat non signé";
      case "CS":
        return "Contrat signé ";
      case "CE":
        return "Contrat envoyé";
      case "PDC":
        return "Pas de contrat";
      default:
        return typeCtr;
    }
  }

  const copyToClipboard = (text) => {
    if (text !== "") {
        if (navigator.clipboard && navigator.clipboard.writeText) {
            navigator.clipboard
                .writeText(text)
                .then(() => {
                    setCopiedText(text);
                    setOpenSnackbar(true);
                })
                .catch((error) => {
                    console.error(
                        "Erreur lors de la copie dans le presse-papiers : ",
                        error
                    );
                });
        } else {
            // Utiliser l'API de sélection de texte comme solution de secours
            const textArea = document.createElement("textarea");
            textArea.value = text;
            document.body.appendChild(textArea);
            textArea.select();
            document.execCommand("copy");
            document.body.removeChild(textArea);

            setCopiedText(text);
            setOpenSnackbar(true);
        }
    }
};
const handleCloseSnackbar = () => {
  setOpenSnackbar(false);
};
  return (
    <div className="">
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={openSnackbar}
        autoHideDuration={1000}
        onClose={handleCloseSnackbar}
        message={`Copie réussie de " ${copiedText === "location.mail" ? "e-mail" : copiedText
          } "`}
        action={
          <React.Fragment>

            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleCloseSnackbar}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
      <div className="">
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", margin: "10px" }}>
          <TextField
            style={{ width: "45%" }}
            label="Box ou container"
            variant="standard"
            value={motRecherche}
            onChange={(e) => {
              setMotRecherche(e.target.value.trimStart());
              setTxtPDefaut(true);
              setInfoStock([]);
            }}
            inputRef={searchTextFieldRef}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon
                    color="primary"
                    onClick={handleRecherche}
                    style={{ cursor: "pointer" }}
                    fontSize="large"
                  />
                </InputAdornment>
              ),
            }}
            onKeyDown={handleKeyDown}
          />

          <Autocomplete
            freeSolo
            style={{ width: "45%"}}
            options={suggestions}
            getOptionLabel={(option) => option.nom} // Utiliser seulement le nom dans le champ TextField
            onInputChange={(e, value) => {
              setMotRechercheClient(value);
              handleFetchSuggestions(value); // Fetch suggestions as user types
              setTxtPDefaut(true);
              setInfoStock([]);
            }}
            onChange={(event, newValue) => {
              if (newValue) {
                setMotRechercheClient(newValue.nom); // Assigner seulement le nom lorsque l'utilisateur sélectionne une suggestion
              }
            }}
            renderOption={(props, option) => (
              <li {...props} style={{ fontSize: "11px" }}>
                {option.nom} (Box: {option.idStockage.split('||').join(',')}) {/* Afficher le nom et le numéro de box dans la liste */}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                style={{marginLeft:"0%"}}
                label="Client"
                variant="standard"
                value={motRechercheClient}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon
                        color="primary"
                        onClick={handleRechercheClient}
                        style={{ cursor: "pointer" }}
                        fontSize="large"
                      />
                    </InputAdornment>
                  ),
                }}
                onKeyDown={handleKeyDownClient} // Rechercher lorsque "Entrée" est appuyée
              />
            )}
          />
        </div>
        {txtPDefaut ? (
          <div style={{ padding: "10px" }}>
            <h5 style={{ textAlign: "center", fontSize: "18px", marginBottom: "14px" }}>
              Liste des stockages libres :
            </h5>
            {Object.keys(placesData).map((place, placeIndex) => (
              <div key={placeIndex} style={{ marginBottom: "20px" }}>
                <h4 style={{ marginBottom: "8px", textDecoration: "underline", fontSize: "14px" }}>
                  {getPlaceLabel(place)} :
                </h4>

                {Object.keys(placesData[place]).map((volume, volIndex) => (
                  <div key={volIndex} style={{ marginBottom: "14px" }}>
                    <strong style={{ display: "block", marginBottom: "8px", fontSize: "14px" }}>
                      {volume} m³ :
                    </strong>

                    {/* Utilisation d'une grille responsive */}
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "repeat(auto-fill, minmax(80px, 1fr))", // Sur mobile, réduit la largeur des boîtes
                        gap: "8px", // Réduit l'espacement
                        alignItems: "center",
                      }}
                    >
                      {placesData[place][volume].slice(0, 4).map((box, boxIndex) => (
                        <div
                          key={boxIndex}
                          style={{
                            cursor: "pointer",
                            padding: "6px",
                            border: "1px solid #ddd",
                            borderRadius: "4px",
                            backgroundColor: "#fff",
                            boxShadow: "0 1px 4px rgba(0, 0, 0, 0.1)",
                            textAlign: "center",
                            fontSize: "12px", // Taille de texte réduite pour mobile
                            transition: "all 0.2s ease",
                          }}
                          onClick={() => handleClick(box)}
                          onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "#f0f0f0")}
                          onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "#fff")}
                        >
                          {box}
                        </div>
                      ))}
                    </div>
         
                  </div>
                ))}

                <Divider style={{ margin: "14px 0" }} />
              </div>
            ))}
          </div>
        ) : (
          <div>
            {infoStock.length > 0 ? (

              <div className="etat" >
                <span>
                  {enCours === "non" ? (
                    <>
                      <h3>Box: {motRecherche} - LIBRE</h3>


                      <h4>
                        {" "}
                        Info du dernier locataire
                      </h4>
                    </>
                  ) : (
                    <h3 style={{ textAlign: "center" }}>Box: {idStockDuClient == "" ? (motRecherche) : (idStockDuClient)} - OCCUPE</h3>
                  )}
                </span>
                <div style={{ display: "block", flexFlow: "wrap",justifyContent: "space-between",marginLeft: "2%"}}>
                  <div>
                    {infoStock.length > 0 && (
                      <div>
                          <div style={{ marginBottom: "10px" }}>
                            <strong style={{ color: "#333", fontSize: "14px" }}>📦V</strong>
                            <span style={{ marginLeft: "5px", fontSize: "14px", color: "#333" }}>{vol} m³</span>
                            <strong style={{ color: "#333", fontSize: "14px" }}> Loyer</strong>
                            <span style={{ marginLeft: "5px", fontSize: "14px", color: "#333" }}>{loyer} €</span>
                          </div>

                        <div style={{ marginBottom: "10px", fontSize: "13px" }}>
                          <strong>👤 </strong>
                          <span style={{ fontSize: "13px", color: "#333" }} onClick={() => copyToClipboard(mail)}>{correctEncoding(nom)} <ContentCopyIcon sx={{fontSize:10}}/></span>
                        </div>

                        <div style={{ marginBottom: "10px", fontSize: "12px" }}>
                          <strong style={{ color: "#333", fontSize: "14px" }}>📱</strong>
                          <span style={{ marginLeft: "5px", fontSize: "14px", color: "#333" }} onClick={() => copyToClipboard(num)}>{num} <ContentCopyIcon sx={{fontSize:10}}/></span>
                        </div>
                        <Divider />
                      </div>
                    )}
                    {totalStock !== motRecherche && totalStock !== "" ? (
                      <div>
                        <div style={{ marginBottom: "10px", fontSize: "14px", color: "#333" }}>
                          <strong style={{ color: "#333" }}>Box:</strong>
                          <span style={{ marginLeft: "5px" }}>{totalStock.split('||').join(',')}</span>
                        </div>
                        <div style={{ fontSize: "14px", color: "#333" }}>
                          <strong style={{ color: "#333" }}>Vtotal :</strong>
                          <span style={{ marginLeft: "5px" }}>{totVol} m³</span>
                        </div>
                        <Divider />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div>

                    {/* Section des factures */}
                      <div>
                        <div style={{ fontSize: "14px", color: "#333", marginBottom: "10px" }}>
                          <strong>Facture n°
                          </strong> {numFact}
                        </div>
                        <div style={{ fontSize: "14px", color: "#333", marginBottom: "10px" }}>
                          <strong> Date :</strong>
                          {formattedDate(dateFacture)}
                        </div>
                        <div style={{ fontSize: "14px", color: "#333", marginBottom: "10px" }}>
                          <strong>Type :</strong> {typeFacture}
                        </div>
                        <div style={{ fontSize: "14px", color: "#333" }}>
                          <strong>P :</strong> {formattedDate(debFacture)} au {formattedDate(finFacture)}
                        </div>
                        <Divider />
                      </div>

                    {/* Section des paiements */}
                    {paieData.length > 0 ? (
                      <div style={{ marginBottom: "20px" }}>
                        <div style={{ fontSize: "14px", fontWeight: "bold", color: "#333", marginBottom: "10px" }}>
                         Paiement
                        </div>
                        {paieData.map((client) => (
                          <div key={client.idPaiement}>

                            <div style={{ marginBottom: "8px" }}>
                              <strong style={{ color: "#333" ,fontSize: "14px" }}>Date :</strong> <span>{formattedDate(client.datePaie)}</span>
                            </div>
                            <div style={{ marginBottom: "8px" }}>
                              <strong style={{ color: "#333" ,fontSize: "14px" }}>Somme :</strong> <span>{client.valeurPaie} €</span> ({mapTypePaie(client.typePaie)})
                            </div>
                            <div style={{ marginBottom: "8px" }}>
                              <strong style={{ color: "#333" ,fontSize: "14px" }}>P:</strong> <span style={{ fontSize: "14px" }}>{formattedDate(client.dateDebut)} au {formattedDate(client.dateFin)}</span>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div>
                        Aucun paiement
                      </div>
                    )}
                    <Divider />
                    <div>
                      <div style={{ marginBottom: "10px", fontSize: "14px", color: "#333" }}>
                         <strong style={{ color: "#333" }}>Contrat n°{numContrat}</strong> du
                          <span style={{ marginLeft: "5px" }}>{formattedDate(dateContrat)}</span>
                      </div>
                      <div style={{ fontSize: "14px", color: "#333" }}>
                         <strong style={{ color: "#333" }}>Type :</strong>
                         <span style={{ marginLeft: "5px" }}>{mapTypeCtr(typeContrat)}</span>
                      </div>
                      <Divider />
                    </div>
                  </div>


                </div>
              </div>
            ) : (
              <div className="etat">
                <h3 style={{ marginLeft: "30%" }}>
                  Box {motRecherche}
                  {"   "} - {"   "}
                  LIBRE
                </h3>
                <br />
                Volume : {vol} m³ <br />
              </div>
            )}

            <br />
          </div>
        )}
      </div>
    </div>
  );
};
