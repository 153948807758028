function mapEtatFiche(etatFiche) {
    switch (etatFiche) {
      case "0":
        return "Injoignable";
      case "1":
        return "Devis envoyé";
      case "2":
        return "Demande de rappel";
      case "3":
        return "Pas interessé / a déjà trouvé";
      case "5":
            return "Non contacté";
      default:
        return etatFiche;
    }
  }
export default mapEtatFiche;