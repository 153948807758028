function mapTypeCtr(typeCTRT) {
    switch (typeCTRT) {
      case "CNS":
        return "Contrat non signé ";
      case "CS":
        return "Contrat signé ";
      case "CE":
        return "Contrat envoyé ";
      case "CNE":
        return "Contrat non envoyé ";
      case "PDCAE":
        return "Pas de contrat à envoyer ";
      case "PDC":
        return "Pas de contrat ";
      default:
        return typeCTRT;
    }
  }
export default mapTypeCtr;