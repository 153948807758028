import React, { useState, useEffect } from "react";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CloseIcon from "@mui/icons-material/Close";
import { Tooltip, Modal } from "@mui/material";
import ClientTooltip from "../nameTooltip";
import { AjoutFacture } from "../facture/facturePage/ajoutFacture";
import { ModifierFacture } from "../facture/facturePage/modifFacture";
import { AjoutReglement } from "../reglement/reglementPage/AjoutReglement";
import { ModifierContrat } from "../contrat/contratPage/modifContrat";
import { useMediaQuery } from '@mui/material';
import axios from 'axios';
import { Box } from '@mui/material';
import correctEncoding from "../correctEncoding";
import mapTypeCtr from "../mapContrat";
import mapTypePaie from "../mapTypePaie"
import formattedDate from "../formatDate";
import InfoIcon from '@mui/icons-material/Info';
import { AjoutContrat } from "../contrat/contratPage/ajoutContrat";
import { Dialog } from 'primereact/dialog';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { TabView, TabPanel } from 'primereact/tabview';


const apiUrl = process.env.REACT_APP_API_URL;
export default function Tasklist() {
    const [factures, setFactures] = useState([]);
    const [paiement, setPaiement] = useState([]);
    const [copiedText, setCopiedText] = useState("");
    const [contrat, setContrat] = useState([]);
    const [sansContrat,setSansContrat] = useState([]);

    const [infoFacture, setInfoFacture] = useState([]);
    const [infoPaie,setInfoPaie] = useState([]);

    const [afficheAjoutFact, setAfficheAjoutFact] = useState(false);
    const [ajoutReglement, setAjoutReglement] = useState(false);
    const [modifierCtr, setModifierCtr] = useState(false);
    const [rescentRgl, setRescentRgl] = useState("");
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [modifierFct, setModifierFct] = useState(false);
    const [idPSelected, setIdPSelected] = useState("");
    const [ajoutContrat, setAjoutContrat] = useState(false);
    const isMobile = useMediaQuery('(max-width:768px)');
    const [locationVisible, setLocationVisible] = useState(false);


    const handleClick = async (idStockage, idClient) => {
        //Clique du numero de stockage
        try { 

            
          const factureResponse = await axios.get(`${apiUrl}/IHM/api/suggestion.php?idClient=${idClient}&facture`);
          setInfoFacture(factureResponse.data);
          setLocationVisible(true);

          const paieResponse = await axios.get(`${apiUrl}/IHM/api/suggestion.php?idClient=${idClient}&paiement`);
          setInfoPaie(paieResponse.data);

          
        } catch (error) {
          console.error("Erreur lors de la récupération des suggestions", error);
        }
    
      };


    useEffect(() => {
        axios.get(`${apiUrl}/IHM/api/task.php?taskFact`)
            .then(response => {
                setFactures(response.data); 
            })
            .catch(error => {
                console.error("Erreur lors du chargement des factures :", error);
            });
    }, []);

    useEffect(() => {
        axios.get(`${apiUrl}/IHM/api/task.php?taskRelancePaie`)
            .then(response => {
                setPaiement(response.data); 
            })
            .catch(error => {
                console.error("Erreur lors du chargement des paiements :", error);
            });
    }, []);

    useEffect(() => {
        axios.get(`${apiUrl}/IHM/api/task.php?taskContrat`)
            .then(response => {
                setContrat(response.data);
            })
            .catch(error => {
                console.error("Erreur lors du chargement des contrat :", error);
            });
    }, []);
    useEffect(() => {
        axios.get(`${apiUrl}/IHM/api/task.php?sansContrat`)
            .then(response => {
                setSansContrat(response.data);
            })
            .catch(error => {
                console.error("Erreur lors du chargement des contrat :", error);
            });
    }, []);

    const copyToClipboard = (text) => {
        if (text !== "") {
            if (navigator.clipboard && navigator.clipboard.writeText) {
                navigator.clipboard
                    .writeText(text)
                    .then(() => {
                        setCopiedText(text);
                        setOpenSnackbar(true);
                    })
                    .catch((error) => {
                        console.error(
                            "Erreur lors de la copie dans le presse-papiers : ",
                            error
                        );
                    });
            } else {
                // Utiliser l'API de sélection de texte comme solution de secours
                const textArea = document.createElement("textarea");
                textArea.value = text;
                document.body.appendChild(textArea);
                textArea.select();
                document.execCommand("copy");
                document.body.removeChild(textArea);

                setCopiedText(text);
                setOpenSnackbar(true);
            }
        }
    };

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    return (
        <>
            <Box>
                <Modal
                    open={afficheAjoutFact}
                    disableBackdropClick={true}
                    aria-describedby="modal-modal-description"
                >
                    <AjoutFacture
                        setAfficheAjoutFact={setAfficheAjoutFact}
                        onClose={() => {
                            setAfficheAjoutFact(false);

                        }}
                    />
                </Modal>
                <Modal
                    open={ajoutReglement}
                    disableBackdropClick={true}
                    aria-describedby="modal-modal-description"
                >
                    <AjoutReglement setAjoutReglement={setAjoutReglement}
                        onClose={() => {
                            setAjoutReglement(false);

                        }} />
                </Modal>

                <Modal
                    open={modifierCtr}
                    disableBackdropClick={true}
                    aria-describedby="modal-modal-description"
                >
                    <ModifierContrat
                        setModifierCtr={setModifierCtr}
                        idPSelected={idPSelected}
                    />
                </Modal>
                <Modal
                    open={modifierFct}
                    disableBackdropClick={true}
                    aria-describedby="modal-modal-description"
                >
                    <ModifierFacture
                        setModifierFct={setModifierFct}
                        idPSelected={idPSelected}
                    />
                </Modal>
                <Modal
                    open={ajoutContrat}
                    disableBackdropClick={true}
                    aria-describedby="modal-modal-description"
                >
                    <AjoutContrat setAjoutContrat={setAjoutContrat} />
                </Modal>

                <Dialog visible={locationVisible} modal style={{ width: '70vw' }} onHide={() => { if (!locationVisible) return; setLocationVisible(false); }}>
                <TabView>

                <TabPanel header="Facture(s)">
                            <DataTable  tableStyle={{ minWidth: '50rem' }} value={infoFacture}>
                              <Column field="numFact" header="Ref" bodyStyle={{ fontSize:'14px',cursor: "pointer" }} body={(rowData) => (
                                <span onClick={() => copyToClipboard(rowData.numFact)}>
                                  {rowData.numFact}
                                </span>
                              )} />
                              <Column field="typeFact" header="Type" />
                              <Column field="nom" header="Client" />
                              <Column field="dateEnv" header="Envoi" body={(rowData) => (
                                <span>
                                  {formattedDate(rowData.dateEnv)}
                                </span>
                              )} />
                              

                              <Column field="idPaiement" header="Paiement" bodyStyle={{ cursor: "pointer" }} body={(rowData => (

                                <span onClick={() => copyToClipboard(rowData.idPaiement)}>
                                  {rowData.idPaiement}
                                </span>

                              ))} />
                              
                              <Column field="" header="Période" bodyStyle={{ fontSize: "14px" }} body={(rowData) => (
                                <span>
                                    D:{formattedDate(rowData.dateDebut)}<br/>
                                    F:{formattedDate(rowData.dateFin)}
                                </span>
                              )}/>
                              <Column header="Notes" field="note" />

                            </DataTable>
                </TabPanel>  

                <TabPanel header="Paiement(s)">
                    <DataTable  tableStyle={{ minWidth: '50rem' }} value={infoPaie}>
                        <Column header="Ref" field="idPaiement"/>
                        <Column header="Type" field="typePaie" body={(rowData) => 
                            <span>
                                {mapTypePaie(rowData.typePaie)}
                            </span>

                        }/>
                        <Column header="Client" field="nom" />
                        <Column header="Envoi" field="datePaie" body={(rowData) => 
                            <span>
                                {formattedDate(rowData.datePaie)}
                            </span>
                        }/>
                        <Column header="Période" field="dateFin" bodyStyle={{fontSize:"14px"}} body={(rowData) =>
                            <span>
                                D:{formattedDate(rowData.dateDebut)}<br/>
                                F:{formattedDate(rowData.dateFin)}
                            </span>
                        }/>
                                <Column header="Montant" field="valeurPaie" body={(rowData) =>
                                    <span>
                                    {rowData.valeurPaie}€
                                    </span>
                                } />


                    </DataTable>
                </TabPanel>
                </TabView>    
                </Dialog>


                <Snackbar
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    open={openSnackbar}
                    autoHideDuration={1000}
                    onClose={handleCloseSnackbar}
                    message={`Copie réussie de " ${copiedText === "location.mail" ? "e-mail" : copiedText
                        } "`}
                    action={
                        <React.Fragment>

                            <IconButton
                                size="small"
                                aria-label="close"
                                color="inherit"
                                onClick={handleCloseSnackbar}
                            >
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        </React.Fragment>
                    }
                />

                <div>

                    <h3> Listes des tâches</h3>
                    {/*               $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$                TABLEAU POUR LES FACTURES               $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$                                */}


                    <DataTable
                        header='Facture à envoyer'
                        value={factures}
                        paginator
                        rows={5}
                        rowsPerPageOptions={[5, 10, 25, 50]}
                        tableStyle={{ minWidth: '50rem' }}
                        scrollable
                        scrollDirection="horizontal"
                        paginatorTemplate={
                            "FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
                        }
                        paginatorRight={
                            <span style={{ margin: "10px", fontWeight: "bold" }}>
                                Résultats : {factures.length}
                            </span>
                        }
                    >
                        <Column
                            style={{ cursor: "pointer" }}
                            field="nom"
                            header="Client"
                            body={(rowData) => (
                                <ClientTooltip
                                    nom={correctEncoding(rowData.nom)}
                                    mail={rowData.mail}
                                    num1={rowData.num1}
                                    copyToClipboard={copyToClipboard}
                                />
                            )}
                        />
                        <Column field='idStockage' header='Box'

                            body={(rowData) => (rowData.idStockage.split('||').join(',')

                            )}

                        />
                        <Column
                            field="dateFin"
                            header="Période"
                         
                            body={(rowData) => {
                                if (rowData.dateFin) {
                                    const date = new Date(rowData.dateFin);
                                    const date3 = new Date(rowData.dateFin);
                                    const date2 = new Date(date3.setMonth(date3.getMonth()+1));
                                  

               
                                    return `${date.toLocaleDateString('fr-FR')} au ${date2.toLocaleDateString('fr-FR')}`;
                                }
                            }}
                        />
                         <Column
                            field="loyer"
                            header="Montant" 
                            
                            body={(rowData) => {
                                const valeur = rowData ? rowData.loyer : 'N/A';
                                return `${valeur}€`;
                            }}
                        />
                        <Column
                            header='Actions'
                            style={{float:'right'}}
                            body={(rowData) => (
                                <div>

                                    <Tooltip title="informations" placement="top">
                                        <Button
                                            icon="pi pi-info-circle"
                                            severity="info"
                                            size="small"
                                            onClick={() => handleClick(rowData.idStockage, rowData.idClient)}
                                        />
                                    </Tooltip>

                                   <Tooltip title="ajouter une facture" placement="top">
                                    <Button

                                        icon="pi pi-plus"
                                        severity="info"
                                        size="small"
                                        style={{
                                            backgroundColor: '#146C94',
                                            flexShrink: 0,
                                            marginLeft:"3px"
                                        }}
                                        onClick={() => setAfficheAjoutFact(true)}
                                    />
                                   </Tooltip>
                                    
                                </div>
                            )}
                        />
                    </DataTable>

                    <Divider />

                    {/*               $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$                TABLEAU POUR LES PAIEMENTS               $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$                                */}

                    <DataTable
                        header={'Relances paiements'}
                        value={paiement} size={'small'}
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5,10,15, 25, 50]}
                        paginatorTemplate={
                            "FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
                        }
                        tableStyle={{ minWidth: '80rem' }}
                        paginatorRight={
                            <span style={{ margin: "10px", fontWeight: "bold" }}>
                                Résultats : {paiement.length}
                            </span>
                        }>
                        <Column style={{fontSize:"14px", cursor: "pointer"}} field="numFact" header="Ref" body={(rowData) => (

                            <div onClick={() => copyToClipboard(rowData.numFact)}>
                                <Tooltip title={
                                    <p> Cliquer pour copier</p>
                                } arrow>
                                    {rowData.numFact}
                                </Tooltip>

                            </div>
                        )}></Column>
                        <Column field="nom" style={{cursor: "pointer" }} header="Client" body={(rowData) => (
                            <ClientTooltip
                                nom={correctEncoding(rowData.nom)}
                                mail={rowData.mail}
                                num1={rowData.num1}
                                copyToClipboard={copyToClipboard}
                            />
                        )} ></Column>
                        <Column field='idStockage' header='Box'

                            body={(rowData) => (rowData.idStockage.split('||').join(',')

                            )}
                        />

                        <Column
                            field="dateT"
                            body={(rowData) => {
                                const date = new Date(rowData.dateT);
                                const day = String(date.getDate()).padStart(2, '0');
                                const month = String(date.getMonth() + 1).padStart(2, '0');
                                const year = date.getFullYear();
                                return `${day}/${month}/${year}`;
                            }}
                            header="Date d'envoi"
                        />
                        <Column
                            field="tarif"
                            body={(rowData) => {
                                const valeur = rowData ? rowData.tarif : 'N/A';
                                return `${valeur}€`;
                            }}
                            header="Montant"
                        />

                        <Column 
                        field='note'
                        header='Notes'
                        style={{width:'8rem',overflow:'hidden',whiteSpace: 'nowrap' }}
                        body={(rowData) =>
                            
                            <>
                                {rowData.note.substring(0, 20)}{rowData.note.length >= 20 && '...'}
                            </>
                        }

                        />

                        <Column field="dateUpdate"  header="MAJ" body={(rowData) => (

                            <span>
                                {formattedDate(rowData.dateUpdate)}{" "}
                                {rowData.infoUpdate}
                            </span>
                        )}/>

                        <Column header='Actions' style={{float:'right'}} body={(rowData) =>

                            <div>
                                <Tooltip title="modifier une facture" placement="top">
                                     <Button
                                    icon="pi pi-pencil"
                                    severity="secondary"
                                    size="small"
                                    
                                    onClick={() => {
                                        setIdPSelected(rowData.numFact);
                                        // setRescentRgl(rowData.idPaiement);
                                        setModifierFct(true);
                                    }}
                                />
                                </Tooltip>
                               <Tooltip title="informations" placement="top">
                                <Button
                                    icon="pi pi-info-circle"
                                    severity="info"
                                    style={{marginLeft:'3px'}}
                                    size="small"
                                    onClick={() => handleClick(rowData.idStockage, rowData.idClient)}
                                />
                               </Tooltip>

                                <Tooltip title="ajouter une facture" placement="top">
                                    <Button
                                        icon="pi pi-plus"
                                        size="small"
                                        severity="info"
                                        style={{
                                            backgroundColor: '#146C94',
                                            marginLeft: '3px'
                                        }}
                                        onClick={() => {
                                            setAfficheAjoutFact(true);
                                        }}
                                    />
                                </Tooltip>
                            </div>
                        }></Column>


                    </DataTable>

                    <Divider />
                    {/*               $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$                TABLEAU POUR LES CONTRATS               $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$                                */}

                    <TabView>
                        <TabPanel header='Relances contrat'>
                            <DataTable
                                value={contrat}
                                paginator
                                rows={5}
                                rowsPerPageOptions={[5, 10, 25, 50]}
                                tableStyle={{ minWidth: '70rem' }}
                                scrollable
                                scrollDirection="horizontal"
                                paginatorTemplate={
                                    "FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
                                }
                                paginatorRight={
                                    <span style={{ margin: "10px", fontWeight: "bold" }}>
                                        Résultats : {contrat.length}
                                    </span>
                                }>
                                <Column field="idContrat" style={{ width: '2%', fontSize: '14px' }} header="ID"></Column>
                                <Column bodyStyle={{width: '20%', cursor: "pointer" }} field="nom" header="Client" body={(rowData) => (
                                    <ClientTooltip
                                        nom={correctEncoding(rowData.nom)}
                                        mail={rowData.mail}
                                        num1={rowData.num1}
                                        copyToClipboard={copyToClipboard}
                                    />
                                )} ></Column>
                                <Column field='idStockage' header='Box'

                                    body={(rowData) => (rowData.idStockage.split('||').join(',')

                                    )}

                                />
                                <Column field="dateContrat" bodyStyle={{width:"2%"}} body={(rowData) => {
                                    const date = new Date(rowData.dateContrat);
                                    const day = String(date.getDate()).padStart(2, '0');
                                    const month = String(date.getMonth() + 1).padStart(2, '0');
                                    const year = date.getFullYear();
                                    return `${day}/${month}/${year}`;
                                }} header="Date du contrat"></Column>

                                <Column
                                    field="typeContrat"
                                    header="Type"
                                    bodyStyle={{ fontSize: "14px", width: "9%" }}
                                    body={(rowData) => (
                                        <div>{mapTypeCtr(rowData.typeContrat)}</div>
                                    )}
                                />
                                <Column field="noteCtr" header="Notes" bodyStyle={{width: '9%' }} />

                                <Column field="dateUpdate" header="MAJ" body={(rowData) => (

                                    <span>
                                        {formattedDate(rowData.dateUpdateCtr)}{" "}
                                        {rowData.infoUpdateCtr}
                                    </span>
                                )} />

                                <Column header='Actions' style={{float:'right'}} body={(rowData) =>
                                    <div>
                                        <Tooltip title="informations" placement="top">
                                            <Button
                                                icon="pi pi-info-circle"
                                                severity="info"
                                                size="small"
                                                onClick={() => handleClick(rowData.idStockage, rowData.idClient)}
                                            />
                                        </Tooltip>

                                        <Tooltip title="modifier contrat" placement="top">
                                            <Button
                                                size="small"
                                                icon="pi pi-pencil"
                                                severity="secondary"
                                                style={{
                                                    // backgroundColor: '#146C94',
                                                    flexShrink: 0,
                                                    marginLeft: "3px"
                                                }}
                                                onClick={() => {
                                                    setIdPSelected(rowData.idContrat);
                                                    setRescentRgl(rowData.idContrat);
                                                    setModifierCtr(true);
                                                }}
                                            />
                                        </Tooltip>
                                    </div>
                                }>
                                </Column>
                            </DataTable>
                        </TabPanel>
                        <TabPanel header='Sans contrat'>
                        <DataTable
                                value={sansContrat}
                                paginator
                                rows={5}
                                rowsPerPageOptions={[5, 10, 25, 50]}
                                tableStyle={{ minWidth: '70rem' }}
                                scrollable
                                scrollDirection="horizontal"
                                paginatorTemplate={
                                    "FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
                                }
                                paginatorRight={
                                    <span style={{ margin: "10px", fontWeight: "bold" }}>
                                        Résultats : {sansContrat.length}
                                    </span>
                                }>
                                <Column style={{ cursor: "pointer" }} field="nom" header="Client" body={(rowData) => (
                                    <ClientTooltip
                                        nom={correctEncoding(rowData.nom)}
                                        mail={rowData.mail}
                                        num1={rowData.num1}
                                        copyToClipboard={copyToClipboard}
                                    />
                                )} ></Column>
                                <Column field='idStockage' header='Box'

                                    body={(rowData) => (rowData.idStockage.split('||').join(',')

                                    )}
                                />
                                <Column header="Date d'entrée"  field='dateEntree'
                                bodyStyle={{width:'40%'}}
                                body={(rowData) => {
                                     const date = new Date(rowData.dateEntree);
                                     const day = String(date.getDate()).padStart(2, '0');
                                     const month = String(date.getMonth() + 1).padStart(2, '0');
                                     const year = date.getFullYear();
                                     return `${day}/${month}/${year}`;
                                }}/>

                                <Column header='Actions' style={{float:'right'}} body={(rowData) =>
                                    <div>
                                        <Tooltip title="informations" placement="top">
                                            <Button
                                                icon="pi pi-info-circle"
                                                severity="info"
                                                size="small"
                                                onClick={() => handleClick(rowData.idStockage, rowData.idClient)}
                                            />
                                        </Tooltip>
                                        <Tooltip title="modifier contrat" placement="top">
                                            <Button
                                                size="small"
                                                icon="pi pi-plus"
                                                style={{
                                                    backgroundColor: '#146C94',
                                                    flexShrink: 0,
                                                    marginLeft: "3px"
                                                }}
                                                onClick={() => {
                                                    setIdPSelected(rowData.idContrat);
                                                    setRescentRgl(rowData.idContrat);
                                                    setAjoutContrat(true);
                                                }}
                                            />
                                            </Tooltip>
                                    </div>


                                }>
                                </Column>
                        </DataTable>
                        </TabPanel>

                    </TabView>
                    


                </div>

            </Box>
        </>

    );
}
