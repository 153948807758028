import React from "react";
import "../../location/locationStyle/ajoutLocation.css";
import axios from "axios";
import { useState, useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ToastContainer, toast } from "react-toastify";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Grid,
    TextareaAutosize,
} from "@mui/material";

import CancelIcon from "@mui/icons-material/Cancel";
import { TextField } from "@mui/material";
export const ModifierFacture = ({ setModifierFct, idPSelected }) => {
    
    const apiUrl = process.env.REACT_APP_API_URL;
    const [paieData, setPaieData] = useState([]);

    const [typeFact, setTypeFact] = useState("FAP");
    const [typePaie, setTypePaie] = useState("");
    const [valeurPaie, setValeurPaie] = useState(0);
    const [dateFin, setDateFin] = useState("2021-01-01");
    const [dateDebut, setDateDebut] = useState("2021-01-01");
    const [dateEnv, setDateEnv] = useState("2021-01-01");
    const [datePaie, setDatePaie] = useState("2021-01-01");
    const [datePaieM, setDatePaieM] = useState(dayjs(datePaie));
    const [dateEnvM, setDateEnvM] = useState(dayjs(dateEnv));
    const [dateFinM, setDateFinM] = useState(dayjs(dateFin));
    const [dateDebutM, setDateDebutM] = useState(dayjs(dateDebut));
    const [tarif, setTarif] = useState("");
    const [modePaiement, setModePaiement] = useState("");
    const inputStyle = {
        fontSize: "20px", // Modifiez la taille de la police selon vos besoins
    };

    const [numPaie, setNumpaie] = useState("");
    const [numFact, setNumFact] = useState("");
    const [note, setNote] = useState("");
    const label = note === "" ? "Note" : "";
    const formatDate = (date) => {
        const dateObject = new Date(date);
        const year = dateObject.getFullYear();
        const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
        const day = dateObject.getDate().toString().padStart(2, "0");
        return `${year}-${month}-${day}`;
    };
    const [labelLoyer, setLabelLoyer] = useState("Loyer à payer");
    const inputLabelStyle = {
        fontWeight: "bold",
    };
    const [idLocation, setIdLocation] = useState("");
    const [idClient, setIdClient] = useState("");
    const [idStockage, setIdStockage] = useState("");
    const [numFactRecup, setNumFactRecup] = useState("");

    const isMobilePortrait = useMediaQuery("(max-width:1207px)  ");
    //mitovy am'ajout
    const [nomCompl, setNomCompl] = useState("");
    const [num, setNum] = useState("");
    const [mail, setMail] = useState("");
    const [adresse, setAdresse] = useState("");
    useEffect(() => {

        axios
            .get(`${apiUrl}IHM/api/facture.php`, {
                params: { idPMod: idPSelected },
            })
            .then((response) => {
                console.log(response.data, "THIS IS VALINY");
                const paieData = response.data;
                setPaieData(paieData);
                const adresse = `${paieData[0].ville} - ${paieData[0].codePostal} - ${paieData[0].adresse}`;
                const num = `${paieData[0].num1} - ${paieData[0].num2}`;
                setIdLocation(paieData[0].idLocation);
                setIdClient(paieData[0].idClient);
                setNomCompl(paieData[0].nom);
                setNum(num);
                setMail(paieData[0].mail);
                setNumFact(paieData[0].numFact);
                setNumFactRecup(paieData[0].numFact);
                setNumpaie(paieData[0].idPaiement);
                setAdresse(adresse);
                setIdStockage(paieData[0].idStockage);
                setTypeFact(paieData[0].typeFact);
                setValeurPaie(paieData[0].tarif);
                setModePaiement(paieData[0].typePaie);
                setDatePaie(paieData[0].datePaie);
                setDateEnv(paieData[0].dateEnv);
                setDateFin(paieData[0].dateFin);
                setDateDebut(paieData[0].dateDebut);
                setNote(paieData[0].note)
            })
            .catch((error) => {
                console.error("error");
            });
    }, [idPSelected]); // Exécutez cette requête lorsque idPSelectedchange

    //fin client

    const handleTypeFactChange = (e) => {
        setTypeFact(e.target.value);
    };
    const handleNumFactChange = (e) => {
        setNumFact(e.target.value);
    };

    const [erreur, setErreur] = useState(false);

    const handleValeurPaieChange = (e) => {
        const inputValue = e.target.value;

        // Vérifie si l'entrée est un nombre positif
        if (/^[+]?\d*\.?\d*$/.test(inputValue) || inputValue === "") {
            setValeurPaie(inputValue);

            setErreur(false);
        } else {
            setErreur(true);
        }
    };

    useEffect(() => {
        setDatePaieM(dayjs(datePaie));
    }, [datePaie]);
    useEffect(() => {
        setDateDebutM(dayjs(dateDebut));
    }, [dateDebut]);
    useEffect(() => {
        setDateEnvM(dayjs(dateEnv));
    }, [dateEnv]);
    useEffect(() => {
        setDateFinM(dayjs(dateFin));
    }, [dateFin]);

    const faireModification = async (e) => {
        e.preventDefault();

        // console.log("appelle  a update");
        // updateBase();
        let debutFormate;
        let finFormate;
        let envFormate;
        let paieFormate;

        paieFormate = formatDate(datePaie);
        envFormate = formatDate(dateEnv);
        debutFormate = formatDate(dateDebut);

        finFormate = formatDate(dateFin);
        // if ((numPaie === "" || numPaie === "0") && typeFact === "FP") {
        //     toast.warn("Veuillez verifier le numéro de paiement");

        //     return; // Arrêter la soumission du formulaire
        // }
        if (typeFact === "FP" && datePaie === null) {
            toast.warn("Date de paiement non rempli");

            return;
        }
        if (typeFact === "FP" && (modePaiement === "" || modePaiement === null)) {
            toast.warn("Mode de paiement non rempli");
            return;
        }

        if (paieData.length > 0) {
            const data = {
                idPSelected: idPSelected,
                numFact: numFact,
                typeFact: typeFact,
                tarif: valeurPaie,
                datePaie: (typeFact === "FAP") ? null : paieFormate,
                dateEnv: envFormate,
                dateDebut: debutFormate,
                dateFin: finFormate,
                note: note,
                infoUpdate: localStorage.getItem('us'),
                numPaie: numPaie,
                typePaie: (typeFact === "FAP") ? null : modePaiement,
            };

            try {

                await axios

                    .put(`${apiUrl}IHM/api/facture.php`, data)

                    .then((response) => {
                       
                        if (typeFact === "FP") {

                            modifReglem();
                        }
                        setModifierFct(false);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } catch (error) {
                console.log("error axios IHM/api/facture.php");
            }
            window.location.reload()
        }
    };

    const handleDatePChange = (date) => {
        setDatePaie(date);
    };
    const handleDateDChange = (date) => {
        setDateDebut(date);
    };
    const handleDateEnvChange = (date) => {
        setDateEnv(date);
    };
    const handleDateFChange = (date) => {
        setDateFin(date);
    };
    const handleModePaiementChange = (e) => {
        setModePaiement(e.target.value);
    };

    const modifReglem = () => {

        //console.log("ty le mampifangojy dateFinAjoutreglement", dateFin);
        if (dateDebut === "" || dateFin === "") {
            toast.warn("Date limite non rempli");

            return; // Arrêter la soumission du formulaire
        }

        if (modePaiement === "") {
            toast.warn("Veuillez verifier le champ mode de paiement");

            return; // Arrêter la soumission du formulaire
        }

        if (idClient === "") {
            toast.warn("Veuillez entrer le nom ou numéro téléphone  du Client ");

            return;
        } else {
            let debutFormate;
            let finFormate;
            let paieFormate;
            paieFormate = formatDate(datePaie);
            debutFormate = formatDate(dateDebut);
            finFormate = formatDate(dateFin);


            const dataPaie = {

                idLocation: idLocation,
                idStockage: idStockage,
                typePaie: (typeFact === "FAP") ? null : modePaiement,
                datePaie: paieFormate,
                valeurPaie: valeurPaie,
                dateDebut: debutFormate,
                dateFin: finFormate,
                numFact: numFact,
                numFactRecup: numFactRecup,
                numPaie: numPaie
            };

            try {

                //modif paiement
                if (numPaie != "") {

                    try {
                        axios
                            .put(`${apiUrl}IHM/api/paiement.php?idPaieModif=${numFactRecup}`, dataPaie)

                            .then((response) => {
                                //console.log(response.data);
                                //console.log("paiement   REUSSIE");
                            })
                            .catch((error) => {
                                console.log(error);
                            });
                    } catch (error) {
                        //console.log("AIZAEEE error");
                        alert("paiement NON REUSSIE");
                    }
                }


                //ajout paiement
                else {
                    //console.log("TSISYY"); 
                    try {
                        axios
                            .put(`${apiUrl}IHM/api/paiement.php`, dataPaie)
                            .then((response) => {
                                //console.log(response.data);
                                //console.log("paiement   REUSSIE");

                            })
                            .catch((error) => {
                                console.log(error);
                            });
                    } catch (error) {
                        console.log("AIZAEEE error");
                        alert("paiement NON REUSSIE");
                    }



                }

            } catch (error) {
                console.log("AIZAEEE error");
                alert("paiement NON REUSSIE");
            }
            //insertion

        }
    };
    return (
        <div className=" ">
            {!isMobilePortrait && (
                <div className="modalContainerLoc">
                    <div className="">
                        <span className="titreEtBtnLoc">
                            <CancelIcon
                                style={{ cursor: "pointer" }}
                                sx={{ marginLeft: "95%", fontSize: 40, marginTop: "-26px" }}
                                onClick={() => setModifierFct(false)}
                            />
                            <h3 className="titleLoc">Modifier une facture</h3>
                        </span>
                        <form className="" onSubmit={faireModification}>
                            <span class="parent-span">
                                <span class="left-span">
                                    <div>
                                        <TextField
                                            id="standard-search"
                                            label="Numéro facture"
                                            type="text"
                                            variant="standard"
                                            value={numFact}
                                            onChange={handleNumFactChange}
                                            style={{ width: "100%" }}
                                            required
                                        />
                                        <br /> <br />
                                        <FormControl variant="standard" fullWidth>
                                            <InputLabel id="modePaiement-label">
                                                Type de factureeeee
                                            </InputLabel>

                                            <Select
                                                labelId="modePaiement-label"
                                                id="typeFact"
                                                value={typeFact}
                                                onChange={handleTypeFactChange}
                                            >
                                                <MenuItem value="FAP">FAP (Facture à Payé)</MenuItem>
                                                <MenuItem value="FP">FP (Facture Payé)</MenuItem>

                                            </Select>
                                        </FormControl>


                                        <br /> <br />
                                        {typeFact === "FP" && (<>
                                            <DemoContainer
                                                components={["DatePicker"]}
                                                style={{ width: "120%" }}
                                            >
                                                <DatePicker
                                                    format="DD-MM-YYYY"
                                                    label="Date de paiement"
                                                    value={datePaieM}
                                                    onChange={handleDatePChange}
                                                    style={{ width: "120%", display: "none" }}
                                                />
                                            </DemoContainer>
                                            <FormControl variant="standard" fullWidth>
                                                <InputLabel id="modePaiement-label">
                                                    Mode de Paiement
                                                </InputLabel>

                                                <Select
                                                    labelId="modePaiement-label"
                                                    id="modePaiement"
                                                    value={modePaiement}
                                                    onChange={handleModePaiementChange}
                                                >
                                                    <MenuItem value="vir">Virement bancaire</MenuItem>
                                                    <MenuItem value="espc">Espèce</MenuItem>
                                                    <MenuItem value="cBl">carte bleu</MenuItem>
                                                    <MenuItem value="cq">Chèque</MenuItem>
                                                    <MenuItem value="ABOcBl">ABO carte bleu</MenuItem>
                                                </Select>
                                            </FormControl></>)}


                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <DemoContainer components={["DatePicker"]}>
                                                    <DemoItem>
                                                        <DatePicker
                                                            format="DD-MM-YYYY"
                                                            label="Date Debut"
                                                            value={dateDebutM}
                                                            onChange={handleDateDChange}
                                                            style={{ display: "none" }}
                                                        />
                                                    </DemoItem>
                                                </DemoContainer>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <DemoContainer components={["DatePicker"]}>
                                                    <DemoItem>
                                                        <DatePicker
                                                            format="DD-MM-YYYY"
                                                            label="Date Fin"
                                                            value={dateFinM}
                                                            onChange={handleDateFChange}
                                                            style={{ display: "none" }}
                                                        />
                                                    </DemoItem>
                                                </DemoContainer>
                                            </Grid>
                                        </Grid> <br />
                                        <DemoContainer components={["DatePicker"]}>
                                            <DemoItem>
                                                <DatePicker
                                                    format="DD-MM-YYYY"
                                                    label="Date d'envoie"
                                                    value={dateEnvM}
                                                    onChange={handleDateEnvChange}
                                                    style={{ display: "none" }}
                                                />
                                            </DemoItem>
                                            <br /> <br />
                                        </DemoContainer><TextField
                                            id="standard-search"
                                            label="Valeur payée (€)"
                                            type="text"
                                            variant="standard"
                                            value={valeurPaie}
                                            onChange={handleValeurPaieChange}
                                            error={erreur}
                                            helperText={erreur ? "Entrez un nombre positif" : ""}
                                            style={{ width: "100%" }}
                                        />{" "}
                                        <br />
                                        <br />
                                        <>
                                            {typeFact === "FP" && (
                                                <TextField
                                                    id="standard-search"
                                                    label="Numéro Paiement"
                                                    type="text"
                                                    variant="standard"
                                                    value={numPaie}
                                                    onChange={(e) => setNumpaie(e.target.value)}
                                                    style={{ width: "100%" }} 
                                                />
                                            )}
                                        </>
                                        <br />

                                    </div>
                                </span>
                                <span class="right-span">
                                    <TextField
                                        id="standard-search"
                                        label="Nom "
                                        type="text"
                                        variant="standard"
                                        value={`${idClient} - ${nomCompl}`}
                                        disabled
                                    />
                                    <br />
                                    <TextField
                                        id="standard-search"
                                        label="Mail"
                                        type="text"
                                        variant="standard"
                                        value={mail}
                                        disabled
                                    />
                                    <br />
                                    <TextField
                                        id="standard-search"
                                        label="Numéro  "
                                        type="text"
                                        variant="standard"
                                        value={num}
                                        disabled
                                    />
                                    <br />
                                    <TextField
                                        id="standard-search"
                                        label="Adresse"
                                        type="text"
                                        variant="standard"
                                        value={adresse}
                                        disabled
                                    /><FormControl variant="standard" style={{ width: "100%" }}>
                                        <InputLabel> {label}</InputLabel>
                                        <TextareaAutosize
                                            variant="standard"
                                            aria-label={label}
                                            minRows={3} // Définissez le nombre minimum de lignes
                                            maxRows={5} // Définissez le nombre maximum de lignes
                                            value={note}
                                            onChange={(e) => setNote(e.target.value)}
                                            style={{ width: "100%" }}
                                        />
                                    </FormControl>
                                </span>
                            </span>
                            <br />
                            <br />
                            <Button
                                className="myBouton"
                                variant="contained"
                                type="submit"
                                sx={{ marginLeft: "40%" }}
                            >
                                Modifier
                            </Button>
                        </form>
                    </div>
                </div>
            )}{" "}
            {/* {isMobilePortrait && (
                <div className="modalContainerLoc">
                    <div className="">
                        <span className=" ">
                            <CancelIcon
                                style={{ cursor: "pointer" }}
                                sx={{ marginLeft: "90%", fontSize: 30, marginTop: "-6px" }}
                                onClick={() => setModifierFct(false)}
                            />

                            <div style={{ textAlign: "center" }}>
                                <span
                                    style={{
                                        display: "inline-block",
                                        fontSize: 20,
                                    }}
                                >
                                    Modifier une Facture
                                    <br /> <br />
                                </span>
                            </div>
                        </span>
                        <form className="" onSubmit={faireModification}>
                            <span class=" ">
                                <span class=" ">
                                    <div>
                                        <div
                                            style={{
                                                textAlign: "center",
                                            }}
                                        >
                                            <span
                                                className="LP"
                                                style={{
                                                    textAlign: "right",
                                                    fontWeight: "700",
                                                    fontFamily: "Arial, sans-serif",
                                                    fontSize: "17px",
                                                    color: "#EC994B",
                                                    marginTop: "10px 0",
                                                }}
                                            >
                                                {idClient} - {nomCompl}
                                            </span>
                                        </div>
                                        {mail}
                                        <br />
                                        {num}
                                        <br />
                                        {adresse} <br /> <br />
                                        <TextField
                                            id="standard-search"
                                            label="Id location : "
                                            type="text"
                                            variant="standard"
                                            value={idLocation}
                                            disabled
                                            style={{ width: "120%" }}
                                        />
                                        <br /> <TextField
                                            id="standard-search"
                                            label={
                                                <span style={inputLabelStyle}>{labelLoyer}</span> // Appliquer le style bold ici
                                            }
                                            type="text"
                                            variant="standard"
                                            value={valeurPaie}
                                            onChange={handleValeurPaieChange}
                                            error={erreur}
                                            helperText={erreur ? "Entrez un nombre positif" : ""}
                                            style={{ width: "45vh", overflowY: "hidden" }}
                                            InputProps={{ style: inputStyle }}
                                            required
                                        /><br />
                                        <FormControl variant="standard" style={{ width: "120%" }}>
                                            <InputLabel id="modePaiement-label">
                                                Type facture
                                            </InputLabel>

                                            <Select
                                                labelId="modePaiement-label"
                                                id="modePaiement"
                                                value={typeFact}
                                                onChange={handleTypeFactChange}
                                            >
                                                <MenuItem value="FAP">FAP (Facture à Payé)</MenuItem>
                                                <MenuItem value="FP">FP (Facture Payé)</MenuItem>
                                                <MenuItem value="EC">EC (Encours)</MenuItem>

                                            </Select>
                                        </FormControl>
                                        <br /> <br />
                                        <TextField
                                            id="standard-search"
                                            label="Valeur (à) payée (€)"
                                            type="text"
                                            variant="standard"
                                            value={valeurPaie}
                                            onChange={handleValeurPaieChange}
                                            error={erreur}
                                            helperText={erreur ? "Entrez un nombre positif" : ""}
                                            style={{ width: "120%" }}
                                        />
                                        <br />   <br />   <TextField
                                            id="standard-search"
                                            label="Numéro Paiement"
                                            type="text"
                                            variant="standard"
                                            value={numPaie}
                                            onChange={(e) => setNumpaie(e.target.value)}
                                            style={{ width: "100%" }}
                                            required={typeFact === "FP"}
                                        />
                                        <br />
                                        <br />           <DemoContainer
                                            components={["DatePicker"]}
                                            style={{ width: "120%" }}
                                        ><DatePicker
                                                format="DD-MM-YYYY"
                                                label="Date de paiement"
                                                value={datePaieM}
                                                onChange={handleDatePChange}
                                                style={{ width: "120%", display: "none" }}
                                            />
                                        </DemoContainer>
                                        <FormControl variant="standard" fullWidth>
                                            <InputLabel id="modePaiement-label">
                                                Mode de Paiement
                                            </InputLabel>

                                            <Select
                                                labelId="modePaiement-label"
                                                id="modePaiement"
                                                value={modePaiement}
                                                onChange={handleModePaiementChange}
                                            >
                                                <MenuItem value="vir">Virement bancaire</MenuItem>
                                                <MenuItem value="espc">Espèce</MenuItem>
                                                <MenuItem value="cBl">carte bleu</MenuItem>
                                                <MenuItem value="cq">Chèque</MenuItem>
                                                <MenuItem value="ABOcBl">ABO carte bleu</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <div style={{ width: "120%" }}>
                                            <DemoContainer
                                                components={["DatePicker"]}
                                                style={{ width: "120%" }}
                                            >
                                                <DatePicker
                                                    format="DD-MM-YYYY"
                                                    label="Date de paiement"
                                                    value={dateEnvM}
                                                    onChange={handleDateEnvChange}
                                                    style={{ width: "120%", display: "none" }}
                                                />
                                            </DemoContainer>
                                            <br />
                                            <DemoContainer
                                                components={["DatePicker"]}
                                                style={{ width: "120%" }}
                                            >
                                                <DatePicker
                                                    format="DD-MM-YYYY"
                                                    label="Date Debut"
                                                    value={dateDebutM}
                                                    onChange={handleDateDChange}
                                                    style={{ width: "120%", display: "none" }}
                                                />
                                            </DemoContainer>
                                            <br />
                                            <DemoContainer
                                                components={["DatePicker"]}
                                                style={{ width: "120%" }}
                                            >
                                                {" "}
                                                <DatePicker
                                                    format="DD-MM-YYYY"
                                                    label="Date Fin"
                                                    value={dateFinM}
                                                    onChange={handleDateFChange}
                                                    style={{ width: "120%", display: "none" }}
                                                />
                                            </DemoContainer>
                                        </div>
                                        <FormControl variant="standard" style={{ width: "100%" }}>
                                            <InputLabel> {label}</InputLabel>
                                            <TextareaAutosize
                                                variant="standard"
                                                aria-label={label}
                                                minRows={3} // Définissez le nombre minimum de lignes
                                                maxRows={5} // Définissez le nombre maximum de lignes
                                                value={note}
                                                onChange={(e) => setNote(e.target.value)}
                                                style={{ width: "100%" }}
                                            />
                                        </FormControl>
                                    </div>
                                </span>
                            </span>
                            <br />
                            <br />
                            <Button
                                className="myBouton"
                                variant="contained"
                                type="submit"
                                sx={{ marginLeft: "40%" }}
                            >
                                Modifier
                            </Button>
                        </form>
                    </div>
                </div>
            )} */}
        </div>
    );
};
