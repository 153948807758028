import { useState, useEffect, useRef } from "react";
import { DataTable } from 'primereact/datatable';
import axios from 'axios';
import { Column } from "primereact/column";
import { Card } from 'primereact/card';
import { Toast } from 'primereact/toast';
import Box from "@mui/material/Box";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button } from 'primereact/button';
import { Sidebar } from 'primereact/sidebar';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Password } from 'primereact/password';
import Grid from '@mui/material/Grid';
import { Dropdown } from 'primereact/dropdown';




const apiUrl = process.env.REACT_APP_API_URL;

export function Users() {
    const [users, setUsers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState(null);
    const [passwordVisibility, setPasswordVisibility] = useState({});
    const toast = useRef(null);
    
    const [visible, setVisible] = useState(false);
    const [modifVisible, setVisibleModif] = useState(false);

    const [identifiant, setIdentifiant] = useState("");
    const [motDePasse, setMotDePasse] = useState("");
    const [traitement, setTraitement] = useState("");

    const [identifiantNonModif,setIdentifiantNonModif] = useState("");

    useEffect(() => {
        axios.get(`${apiUrl}/IHM/api/users.php?AllUsers`)
            .then(response => {
                setUsers(response.data);
            })
            .catch(error => {
                console.error("Erreur lors du chargement des factures :", error);
            });
    }, [apiUrl]);

    const onModifIc = (idUser, motDePasse, traitement) => {
        setIdentifiant(idUser);
        setMotDePasse(motDePasse);
        setTraitement(traitement);
        setVisibleModif(true);
    };

    const onDeleteIc = async (idUser, motDePasse, traitement) => {  

        if(window.confirm(`Voulez vous supprimer ${idUser}?`)){
            try {
            await axios.get(`${apiUrl}IHM/api/users.php?suppression&identifiant=${idUser}`).then(response => {
                if (response.data) {
                    toast.current.show({
                        severity: "success",
                        summary: `Réussi`,
                        detail: "Suppression de l'utilisateur réussi",
                        life: 3500,
                    });
                    setVisible(false);
                    window.location.reload()
                }
                else {
                    toast.current.show({
                        severity: "error",
                        summary: `échec`,
                        detail: "erreur sur la suppression",
                        life: 3500,
                    });
                }

            })
        }
        catch (error){
            toast.current.show({
                severity: "error",
                summary: `Erreur`,
                detail: "Une erreur est survenue lors de la modification de l'utilisateur",
                life: 3500,
            });
            console.error("Erreur dans la modification de l'utilisateur :", error);
        } 
        }
       else{
        setIdentifiant("");
        setMotDePasse("");
        setTraitement("");
       }
    }

    const handleSubmit = async (e) => {
        const data = {
            identifiant: identifiant,
            motDePasse: motDePasse,
        };
        try {
            await axios.put(`${apiUrl}IHM/api/users.php`, data).then((response) => {
                if (response.data) {
                    toast.current.show({
                        severity: "success",
                        summary: `Réussi`,
                        detail: "Ajout de l'utilisateur réussi",
                        life: 3500,
                    });
                    setVisible(false);
                    window.location.reload()
                }
                else {
                    toast.current.show({
                        severity: "error",
                        summary: `échec`,
                        detail: "erreur sur la création",
                        life: 3500,
                    });
                }

            })
        }
        catch {

        }

    }

    const handelSubmitModif = async (e) => {
        try {
            await axios.get(`${apiUrl}IHM/api/users.php?traitement=${identifiantNonModif}&identifiant=${identifiant}&motDePasse=${motDePasse}`).then(response => {
                if (response.data) {
                    toast.current.show({
                        severity: "success",
                        summary: `Réussi`,
                        detail: "Modification de l'utilisateur réussi",
                        life: 3500,
                    });
                    setVisible(false);
                    setIdentifiant("");
                    setIdentifiantNonModif("");
                    setMotDePasse("");
                    setTraitement("");
                    window.location.reload()
                }
                else {
                    toast.current.show({
                        severity: "error",
                        summary: `échec`,
                        detail: "erreur sur la modification",
                        life: 3500,
                    });
                }

            })
        }
        catch (error){
            toast.current.show({
                severity: "error",
                summary: `Erreur`,
                detail: "Une erreur est survenue lors de la modification de l'utilisateur",
                life: 3500,
            });
            console.error("Erreur dans la modification de l'utilisateur :", error);
        }
    }
    
    const togglePasswordVisibility = (rowIndex) => {
        setPasswordVisibility(prevState => ({
            ...prevState,
            [rowIndex]: !prevState[rowIndex]
        }));
    };

    const passwordBodyTemplate = (rowData, { rowIndex }) => {
        return (
            <div>
                <span>
                    {passwordVisibility[rowIndex] ? rowData.password : "••••••"}
                </span>
                <i
                    className={`pi ${passwordVisibility[rowIndex] ? "pi-eye-slash" : "pi-eye"}`}
                    onClick={() => togglePasswordVisibility(rowIndex)}
                    style={{
                        cursor: 'pointer',
                        fontSize: '15px',
                        marginLeft: '10px'
                    }}
                ></i>
            </div>
        );
    };

    if (localStorage.getItem("us") === 'admin') {
        return (
            <div>
                <Toast ref={toast} position="top-right"/>
                <div>

                    <Sidebar visible={visible} position="right" onHide={() => setVisible(false)}>
                        <div>
                                <InputText
                                    placeholder="Identifiant"
                                    className="p-inputtext-lg"
                                    onChange={(e) => setIdentifiant(e.target.value)}
                                    value={identifiant}
                                />
                                <br />
                                <br />
                                <Password
                                    placeholder="Mot de passe"
                                    className="p-inputtext-lg"
                                    onChange={(e) => setMotDePasse(e.target.value)}
                                    value={motDePasse}
                                    toggleMask
                                />
                                <br />
                                <br />

                            <Button
                                label="Enregistrer"
                                icon="pi pi-check"
                              
                                onClick={() => { handleSubmit(); }}
                                style={{
                                    backgroundColor: '#146C94',
                                    marginLeft: '13%',
                                    marginRight: 'auto',
                                    width:'200px',
                                    flexShrink: 0
                                }}
                            />

                        </div>
                    </Sidebar>

                    <Sidebar visible={modifVisible} position ="right" onHide={() => setVisibleModif(false)}>
                        <div>
                            <InputText
                                placeholder="Identifiant"
                                className="p-inputtext-lg"
                                onChange={(e) => setIdentifiant(e.target.value)}
                                value={identifiant}
                            />
                            <br />
                            <br />
                            <Password
                                placeholder="Mot de passe"
                                className="p-inputtext-lg"
                                onChange={(e) => setMotDePasse(e.target.value)}
                                value={motDePasse}
                                toggleMask
                            />
                            <br />
                            <br />

                            <Button
                                label="Modifier"
                                icon="pi pi-check"
                                onClick={() => { handelSubmitModif(); }}
                                style={{
                                    backgroundColor: '#146C94',
                                    marginLeft: '13%',
                                    marginRight: 'auto',
                                    width: '200px',
                                    flexShrink: 0
                                }}
                            />

                        </div>
                    </Sidebar>




                    <div style={{ display: "flex", alignItems: "center", gap: "30vw" }}>

                        <h3>Utilisateurs</h3>



                        <Button
                            style={{
                                backgroundColor: '#146C94',
                                flexShrink: 0
                            }}
                            label="Utilisateur"
                            onClick={() => {
                                setIdentifiant("");
                                setMotDePasse("");
                                setVisible(true)
                            }}
                            icon="pi pi-plus"
                            severity="info"
                        />
                    </div>
                    <div>
                        <DataTable
                            value={users}
                            rows={10}
                            tableStyle={{width:"90%" ,minWidth: '50rem'}}
                            size={'small'}
                            dataKey="idUser"
                            rowsPerPageOptions={[5, 8, 10, 25, 50]}
                            paginator
                            paginatorTemplate={
                                "FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
                            }
                        >
                            <Column field="idUser" header="identifiant" />
                            <Column
                                field="password"
                                header="Mot de passe"
                                style={{ width: "30%" }}
                                body={passwordBodyTemplate}
                            />

                            <Column header="Actions" body={(rowData) => (
                                <>
                                    <ModeEditOutlineIcon
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                            onModifIc(rowData.idUser, rowData.password, 'modification');
                                            setIdentifiantNonModif(rowData.idUser);
                                        }}
                                    />
                                    <DeleteIcon
                                        style={{ cursor: "pointer",marginLeft: "3%" }}
                                        onClick={() => {
                                            onDeleteIc(rowData.idUser, rowData.password, 'suppression');
                                        }}
                                    />
                                </>
                            )}>

                            </Column>
                        </DataTable>

                    </div>

                </div>
            </div>
        );
    }
    else {
        return (
            <div className="p-d-flex p-jc-center p-ai-center" style={{ height: '100vh' }}>
                <Card title="Page Non Disponible" className="p-shadow-3">
                    <div className="p-text-center">
                        <p>Nous sommes désolés, mais la page que vous cherchez n'est pas disponible.</p>
                    </div>
                </Card>
            </div>
        );

    }

}