import React from "react";
import "../../location/locationStyle/ajoutLocation.css";
import axios from "axios";
import { useState, useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ToastContainer, toast } from "react-toastify";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Grid,
  TextareaAutosize,
} from "@mui/material";

import CancelIcon from "@mui/icons-material/Cancel";
import { TextField } from "@mui/material";
export const ModifierContrat = ({ setModifierCtr, idPSelected }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [ctrData, setCtrData] = useState([]);

  const [lienContrat, setLienContrat] = useState("");
  const [typeContrat, setTypeContrat] = useState("vir");
  const [noteContrat,setNoteContrat] = useState("");
  const [valeurPaie, setValeurPaie] = useState(0); 
  const [dateCtr, setDateCtr] = useState("2021-01-01");
  const [dateCtrM, setDateCtrM] = useState(dayjs(dateCtr)); 
  const formatDate = (date) => {
    const dateObject = new Date(date);
    const year = dateObject.getFullYear();
    const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
    const day = dateObject.getDate().toString().padStart(2, "0");

    return `${year}-${month}-${day}`;
  };
  const [idLocation, setIdLocation] = useState("");
  const [idClient, setIdClient] = useState("");
  const [idStockage, setIdStockage] = useState("");

  const isMobilePortrait = useMediaQuery("(max-width:1207px)  ");
  //mitovy am'ajout
  const [nomCompl, setNomCompl] = useState("");
  const [num, setNum] = useState("");
  const [mail, setMail] = useState("");
  const [adresse, setAdresse] = useState("");
  useEffect(() => {
    console.log("TADAVINA", idPSelected);
    axios
      .post(`${apiUrl}IHM/api/contrat.php?idModifSelect=${idPSelected}`,  )

       


      .then((response) => { 
        const ctrData = response.data;
        setCtrData(ctrData);
        const adresse = `${ctrData[0].ville} - ${ctrData[0].codePostal} - ${ctrData[0].adresse}`;
        const num = `${ctrData[0].num1} - ${ctrData[0].num2}`;
        setIdLocation(ctrData[0].idLocation);
        setIdClient(ctrData[0].idClient);
        setNomCompl(ctrData[0].nom);
        setNum(num);
        setMail(ctrData[0].mail);
        setAdresse(adresse);
        setIdStockage(ctrData[0].idStockage);
        setTypeContrat(ctrData[0].typeContrat); 
        setDateCtr(ctrData[0].dateContrat); 
        setLienContrat(ctrData[0].lienCtr);
        setNoteContrat(ctrData[0].noteCtr);
      })
      .catch((error) => {
        console.error("error");
      });
  }, [idPSelected]); // Exécutez cette requête lorsque idPSelectedchange

  //fin client

  const handleTypeContratChange = (e) => {
    setTypeContrat(e.target.value);
  };

  const [erreur, setErreur] = useState(false);

  
 
  useEffect(() => {
    setDateCtrM(dayjs(dateCtr));
  }, [dateCtr]);
   

  const faireModification = async (e) => {
    e.preventDefault();

    console.log("appelle  a update");
    // updateBase();
    
    let ctrFormate;

    ctrFormate = formatDate(dateCtr);
   

    if (ctrData.length > 0) {
      const data = {
        idContrat: idPSelected,
        typeContrat: typeContrat, 
        dateContrat: ctrFormate,
        infoUpdate: localStorage.getItem('us'),
        lienCtr: lienContrat,
        noteCtr: noteContrat,
      };


  
      try {
        await axios
       
          .post(`${apiUrl}IHM/api/contrat.php`, data)

          .then((response) => {
            setModifierCtr(false);

          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
       
      }
      window.location.reload()

    }
  };
 
  const handleDatePChange = (date) => {
    setDateCtr(date);
  };
  
  return (
    <div className=" ">
      {!isMobilePortrait && (
        <div className="modalContainerLoc">
          <div className="">
            <span className="titreEtBtnLoc">
              <CancelIcon
                style={{ cursor: "pointer" }}
                sx={{ marginLeft: "95%", fontSize: 40, marginTop: "-26px" }}
                onClick={() => setModifierCtr(false)}
              />
              <h3 className="titleLoc">Modifier un Contrat</h3>
            </span>
            <form className="" onSubmit={faireModification}>
              <span class="parent-span">
                <span class="left-span">
                  <div>

                    <TextField
                      id="standard-search"
                      label="Id location"
                      type="text"
                      variant="standard"
                      value={idLocation}
                      onChange={(e) => setIdLocation(e.target.value)}
                      disabled
                      style={{ width: "100%" }}
                    />{" "}

                    <br /> <br />
                    <FormControl variant="standard" style={{ width: "100%" }}>
                    <InputLabel id="modePaiement-label">
                     Type de contrat
                    </InputLabel>

                    <Select
                      labelId="modePaiement-label"
                      id="typeContrat"
                      value={typeContrat}
                      onChange={handleTypeContratChange}
                    >
               <MenuItem value="CNS">Contrat non signé (CNS)</MenuItem>
                      <MenuItem value="CS">Contrat signé (CS)</MenuItem>
                      <MenuItem value="CE">Contrat envoyé (CE)</MenuItem>
                      {/* <MenuItem value="CNE">Contrat non envoyé (CNE)</MenuItem>
                      <MenuItem value="PDCAE">Pas de contrat à envoyer (PDC AE)</MenuItem> */}
                      <MenuItem value="PDC">Pas de contrat (PDC)</MenuItem>
                    </Select>
                  </FormControl>
                    <br />  
                    
                    <br /> <TextField
                    id="standard-search"
                    label="Lien contrat"
                    type="text"
                    variant="standard"
                    value={lienContrat}

                    onChange={(e) => (setLienContrat(e.target.value),
                      e.target.value.includes("https://drive") && setTypeContrat("CS")
                    )}

                    style={{ width: "100%" }}
                    required
                  />

                  <br />
                  <br />
                    <DemoContainer components={["DatePicker"]}>
                      <DemoItem>
                        <DatePicker
                          format="DD-MM-YYYY"
                          label="Date du contrat"
                          value={dateCtrM}
                          onChange={handleDatePChange}
                          style={{ display: "none" }}
                        />
                      </DemoItem>
                    </DemoContainer>{" "}
                    <br />
                    <br />
                  
                  </div>
                </span>
                <span class="right-span">
                  <TextField
                    id="standard-search"
                    label="Nom "
                    type="text"
                    variant="standard"
                    value={`${idClient} - ${nomCompl}`}
                    disabled
                  />
                  <br />
                  <TextField
                    id="standard-search"
                    label="Mail"
                    type="text"
                    variant="standard"
                    value={mail}
                    disabled
                  />
                  <br />
                  <TextField
                    id="standard-search"
                    label="Numéro  "
                    type="text"
                    variant="standard"
                    value={num}
                    disabled
                  />
                  <br />
                  <TextField
                    id="standard-search"
                    label="Adresse"
                    type="text"
                    variant="standard"
                    value={adresse}
                    disabled
                  />

                  <TextareaAutosize
                    variant="standard"
                    label="Notes"
                    minRows={3} // Définissez le nombre minimum de lignes
                    maxRows={5} // Définissez le nombre maximum de lignes
                    value={noteContrat}
                    onChange={(e) => setNoteContrat(e.target.value)}
                    style={{ width: "100%" }}
                  />
                </span>
              </span>
              <br />
              <br />
              <Button
                className="myBouton"
                variant="contained"
                type="submit"
                sx={{ marginLeft: "40%" }}
              >
                Modifier
              </Button>
            </form>
          </div>
        </div>
      )}{" "}
      {isMobilePortrait && (
        <div className="modalContainerLoc">
          <div className="">
            <span className=" ">
              <CancelIcon
                style={{ cursor: "pointer" }}
                sx={{ marginLeft: "90%", fontSize: 30, marginTop: "-6px" }}
                onClick={() => setModifierCtr(false)}
              />

              <div style={{ textAlign: "center" }}>
                <span
                  style={{
                    display: "inline-block",
                    fontSize: 20,
                  }}
                >
                  Modifier un Contrat
                  <br /> <br />
                </span>
              </div>
            </span>
            <form className="" onSubmit={faireModification}>
              <span class=" ">
                <span class=" ">
                  <div>
                    <div
                      style={{
                        textAlign: "center",
                      }}
                    >
                      <span
                        className="LP"
                        style={{
                          textAlign: "right",
                          fontWeight: "700",
                          fontFamily: "Arial, sans-serif",
                          fontSize: "17px",
                          color: "#EC994B",
                          marginTop: "10px 0",
                        }}
                      >
                        {idClient} - {nomCompl}
                      </span>
                    </div>
                    {mail}
                    <br />
                    {num}
                    <br />
                    {adresse} <br /> <br />
                    <TextField
                      id="standard-search"
                      label="Id location : "
                      type="text"
                      variant="standard"
                      value={idLocation}
                      disabled
                      style={{ width: "120%" }}
                    />
                    <br /> <br />
                    <FormControl variant="standard" style={{ width: "120%" }}>
                    <InputLabel id="modePaiement-label">
                     Type de contrat
                    </InputLabel>

                    <Select
                      labelId="modePaiement-label"
                      id="typeContrat"
                      value={typeContrat}
                      onChange={handleTypeContratChange}
                      
                    >
               <MenuItem value="CNS">Contrat non signé (CNS)</MenuItem>
                      <MenuItem value="CS">Contrat signé (CS)</MenuItem>
                      <MenuItem value="CE">Contrat envoyé (CE)</MenuItem>
                      {/* <MenuItem value="CNE">Contrat non envoyé (CNE)</MenuItem>
                      <MenuItem value="PDCAE">Pas de contrat à envoyer (PDC AE)</MenuItem> */}
                      <MenuItem value="PDC">Pas de contrat (PDC)</MenuItem>
                    </Select>
                  </FormControl>
                    <br /> <br />
                    <TextField
                    id="standard-search"
                    label="Lien contrat"
                    type="text"
                    variant="standard"
                    value={lienContrat}
                    onChange={(e) => setLienContrat(e.target.value)}
                    style={{ width: "100%" }}
                    required
                  />
                  
                    <br />
                    <br />
                    <div style={{ width: "120%" }}>
                      <DemoContainer
                        components={["DatePicker"]}
                        style={{ width: "120%" }}
                      >
                        <DatePicker
                          format="DD-MM-YYYY"
                          label="Date de paiement"
                          value={dateCtrM}
                          onChange={handleDatePChange}
                          style={{ width: "120%", display: "none" }}
                        />
                      </DemoContainer>
                      <br />
                      
                    </div>
                  </div>
                </span>
              </span>
              <br />
              <br />
              <Button
                className="myBouton"
                variant="contained"
                type="submit"
                sx={{ marginLeft: "40%" }}
              >
                Modifier
              </Button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};
