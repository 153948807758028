const formattedDate = (dateRecup) => {
    if (dateRecup === "0000-00-00" || dateRecup === "" || dateRecup === null) {
      return ``;
    }

    const date = new Date(dateRecup);

    // Options pour le formatage
    const options = {
      day: 'numeric',   // Jour du mois
      month: 'numeric',    // Mois complet (janvier, février, etc.)
      year: 'numeric'   // Année complète
    };

    // Formatage de la date avec les options spécifiées
    return date.toLocaleDateString('fr-FR', options);
  };

  export default formattedDate