import React, { useState, useEffect, useRef } from "react";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CloseIcon from "@mui/icons-material/Close";
import { Tooltip, Modal } from "@mui/material";
import ClientTooltip from "../nameTooltip";
import { TabView, TabPanel } from 'primereact/tabview';
import { useMediaQuery } from '@mui/material';
import axios from 'axios';
import { Box } from '@mui/material';
import { Toast } from 'primereact/toast';
import correctEncoding from "../correctEncoding";
import { Sidebar } from 'primereact/sidebar';
import formattedDate from "../formatDate";
import InfoIcon from '@mui/icons-material/Info';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import 'primeicons/primeicons.css';
import { InputText } from 'primereact/inputtext';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { Tag } from 'primereact/tag';
import mapEtatFiche from "../mapEtatFiche";
import { Badge } from 'primereact/badge';

import {
    TextField,
    TextareaAutosize
} from "@mui/material";

const apiUrl = process.env.REACT_APP_API_URL;



export default function FicheListe() {
    const [ficheListe, setFicheListe] = useState([]);
    const [ficheListeTraite, setFicheListeTraite] = useState([]);

    const [ficheListeEnCours, setFicheListeEnCours] = useState([]);

    const [ficheListeGiveUp, setFicheListeGiveUp] = useState([]);
    const [ficheListeTous,setFicheListeTous] = useState([]);

    const [copiedText, setCopiedText] = useState("");
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [visible, setVisible] = useState(false);
    const [appel, setAppel] = useState("");
    const [note, setNote] = useState("");

    const [dateMAJ, setDateMAJ] = useState("");
    const [infoMAJ, setInfoMAJ] = useState("");

    const toast = useRef(null);

    const [tempName, setTempName] = useState("");
    const [tempNum, setTempNum] = useState("");
    const [tempBesoin, setTempBesoin] = useState("");
    const [tempMail, setTempMail] = useState("");
    const [tempIdFiche, setTempIdFiche] = useState("");

    const [recherche, setRecherche] = useState("");

    const [rechercheEnCours, setRechercheEnCours] = useState("");
    const [rechercheToutes,setRechercheToutes] = useState("");

    const [rechercheTr, setRechercheTr] = useState("");
    const [rechercheGv, setRechercheGv] = useState("");



    const [ficheListeFiltre, setFicheListeFiltre] = useState(ficheListe);
    
    const [ficheFiltreEnCours, setFicheFiltreEnCours] = useState(ficheListeEnCours);
    const [ficheListeFiltreTr, setFicheListeFiltreTr] = useState(ficheListeTraite);
    const [ficheListeFiltreGv, setFicheListeFiltreGv] = useState(ficheListeGiveUp);
    const [ficheListeFiltreToutes, seFicheListeFiltreToutes] = useState(ficheListeTous);


    const [activeIndex, setActiveIndex] = useState(0);


    useEffect(() => {
        const storedIndex = localStorage.getItem('activeTabIndex');
        if (storedIndex !== null) {
            setActiveIndex(parseInt(storedIndex, 10));
        }
    }, []);
    const handleTabChange = (e) => {
        setActiveIndex(e.index);
        localStorage.setItem('activeTabIndex', e.index);
    };

    useEffect(() => {
        const filteredList = ficheListe.filter((fiche) => {
            return (
                fiche.nom.toLowerCase().includes(recherche.toLowerCase()) ||
                fiche.num.toLowerCase().includes(recherche.toLowerCase()) ||
                fiche.ville.toLowerCase().includes(recherche.toLowerCase())
            );
        });
        setFicheListeFiltre(filteredList);
    }, [recherche, ficheListe]);

    useEffect(() => {
        const filteredList1 = ficheListeEnCours.filter((fiche) => {
            return (
                fiche.nom.toLowerCase().includes(rechercheEnCours.toLowerCase()) ||
                fiche.num.toLowerCase().includes(rechercheEnCours.toLowerCase()) ||
                fiche.ville.toLowerCase().includes(rechercheEnCours.toLowerCase())
            );
        });
        setFicheFiltreEnCours(filteredList1);
    }, [rechercheEnCours, ficheListeEnCours]);

    useEffect(() => {
        const filteredListTr = ficheListeTraite.filter((fiche) => {
            return (
                fiche.nom.toLowerCase().includes(rechercheTr.toLowerCase()) ||
                fiche.num.toLowerCase().includes(rechercheTr.toLowerCase()) ||
                fiche.ville.toLowerCase().includes(rechercheTr.toLowerCase())
            );
        });
        setFicheListeFiltreTr(filteredListTr);
    }, [rechercheTr, ficheListeTraite]);

    useEffect(() => {
        const filteredList2 = ficheListeGiveUp.filter((fiche) => {
            return (
                fiche.nom.toLowerCase().includes(rechercheGv.toLowerCase()) ||
                fiche.num.toLowerCase().includes(rechercheGv.toLowerCase()) ||
                fiche.ville.toLowerCase().includes(rechercheGv.toLowerCase())
            );
        });
        setFicheListeFiltreGv(filteredList2);
    }, [rechercheGv, ficheListeGiveUp]);

    useEffect(() => {
        const filteredList2 = ficheListeTous.filter((fiche) => {
            return (
                fiche.nom.toLowerCase().includes(rechercheToutes.toLowerCase()) ||
                fiche.num.toLowerCase().includes(rechercheToutes.toLowerCase()) ||
                fiche.ville.toLowerCase().includes(rechercheToutes.toLowerCase())
            );
        });
        seFicheListeFiltreToutes(filteredList2);
    }, [rechercheToutes, ficheListeTous]);


    const setInfoClick = (idFiche, name, numero, Besoin, mail) => {
        setNote("");
        setAppel("");

        setDateMAJ("");
        setInfoMAJ("");

        setTempName(name);
        setTempNum(numero);
        setTempBesoin(Besoin);
        setTempMail(mail);
        setTempIdFiche(idFiche);

    axios.get(`${apiUrl}IHM/api/fiche.php?idFiche=${idFiche}`)
            .then(response => {
                setNote(response.data[0].note);
                setAppel(response.data[0].etat);

                setDateMAJ(response.data[0].dateNote);
                setInfoMAJ(response.data[0].adminNote);

                setVisible(true);
            })
            .catch(error => {
                console.error("Une erreur s'est produite lors de la récupération de la fiche :", error);
            });
    };

    const copyToClipboard = (text) => {
        if (text !== "") {
            if (navigator.clipboard && navigator.clipboard.writeText) {
                navigator.clipboard
                    .writeText(text)
                    .then(() => {
                        setCopiedText(text);
                        setOpenSnackbar(true);
                    })
                    .catch((error) => {
                        console.error(
                            "Erreur lors de la copie dans le presse-papiers : ",
                            error
                        );
                    });
            } else {
                // Utiliser l'API de sélection de texte comme solution de secours
                const textArea = document.createElement("textarea");
                textArea.value = text;
                document.body.appendChild(textArea);
                textArea.select();
                document.execCommand("copy");
                document.body.removeChild(textArea);

                setCopiedText(text);
                setOpenSnackbar(true);
            }
        }
    };
    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };
    const handleSavePhoneCall = async () => {
        const data = {
            idFiche: tempIdFiche,
            etat: appel,
            note: note,
            adminMod: localStorage.getItem('us')
        };

        try {
            const response = await axios.put(`${apiUrl}IHM/api/fiche.php`, data);

            if (response.data) {
                
                setVisible(false);
                window.location.reload();

            } else {
                setVisible(false);
                window.location.reload();
            }
        } catch (error) {
            // Gérer l'erreur ici
            console.error("Erreur lors de la sauvegarde de l'appel téléphonique", error);
        }
    };


    useEffect(() => {
        axios.get(`${apiUrl}/IHM/api/fiche.php?ficheListe`)
            .then(response => {
                setFicheListe(response.data);

            })
            .catch(error => {
                console.error("Erreur lors du chargement des factures :", error);
            });
    }, []);
    useEffect(() => {
        axios.get(`${apiUrl}/IHM/api/fiche.php?enCours`)
            .then(response => {
                setFicheListeEnCours(response.data);
            })
            .catch(error => {
                console.error("Erreur lors du chargement des factures :", error);
            });
    }, []);
    useEffect(() => {
        axios.get(`${apiUrl}/IHM/api/fiche.php?idFicheListeGiveUp`)
            .then(response => {
                setFicheListeGiveUp(response.data);
            })
            .catch(error => {
                console.error("Erreur lors du chargement des fiches abandonnées :", error);
            });
    }, []);
    useEffect(() => {
        axios.get(`${apiUrl}/IHM/api/fiche.php?ficheListeTous`)
            .then(response => {
                setFicheListeTous(response.data);
            })
            .catch(error => {
                console.error("Erreur lors du chargement des fiches abandonnées :", error);
            });
    }, []);
    useEffect(() => {
        axios.get(`${apiUrl}/IHM/api/fiche.php?traitee`)
            .then(response => {
                setFicheListeTraite(response.data);

            })
            .catch(error => {
                console.error("Erreur lors du chargement des factures :", error);
            });
    }, []);


    const enteteTabeau1 = () => {
        return (
            <div className="flex justify-content-between">

                <IconField iconPosition="left">
                    <InputIcon className="pi pi-search" />
                    <InputText value={recherche} onChange={(e) => setRecherche(e.target.value)} placeholder="Rechercher" />
                </IconField>
            </div>
        );
    };

    const enteteTableau2 = () =>{
        return (
            <div className="flex justify-content-between">

                <IconField iconPosition="left">
                    <InputIcon className="pi pi-search" />
                    <InputText value={rechercheEnCours} onChange={(e) => setRechercheEnCours(e.target.value)} placeholder="Rechercher" />
                </IconField>
            </div>
        );

    }

    const enteteTabeauTr = () => {
        return (
            <div className="flex justify-content-between">

                <IconField iconPosition="left">
                    <InputIcon className="pi pi-search" />
                    <InputText value={rechercheTr} onChange={(e) => setRechercheTr(e.target.value)} placeholder="Rechercher" />
                </IconField>
            </div>
        );
    };

    const enteteTabeauGv = () => {
        return (
            <div className="flex justify-content-between">

                <IconField iconPosition="left">
                    <InputIcon className="pi pi-search" />
                    <InputText value={rechercheGv} onChange={(e) => setRechercheGv(e.target.value)} placeholder="Rechercher" />
                </IconField>
            </div>
        );
    };

    const enteteTableauTt = () => {
        return (
            <div className="flex justify-content-between">

                <IconField iconPosition="left">
                    <InputIcon className="pi pi-search" />
                    <InputText value={rechercheToutes} onChange={(e) => setRechercheToutes(e.target.value)} placeholder="Rechercher" />
                </IconField>
            </div>
        );
    };

    return (
        <>
          
            <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                open={openSnackbar}
                autoHideDuration={1000}
                onClose={handleCloseSnackbar}
                message={`Copie réussie de " ${copiedText === "location.mail" ? "e-mail" : copiedText
                    } "`}
                action={
                    <React.Fragment>

                        <IconButton
                            size="small"
                            aria-label="close"
                            color="inherit"
                            onClick={handleCloseSnackbar}
                        >
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>
                }
            />
            <Sidebar visible={visible} position="right" onHide={() => setVisible(false)}>
                <div style={{ padding: '10px', borderRadius: '8px', backgroundColor: '#f9f9f9', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)', lineHeight: '1.8' }}>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px', cursor: "pointer" }}>
                        <i className="pi pi-user" style={{ fontSize: '1.2rem', marginRight: '8px' }}></i>
                        <span style={{ fontSize: '1rem', fontWeight: '500' }} onClick={() => copyToClipboard(tempName)}>{tempName} <ContentCopyIcon sx={{ fontSize: 10 }} /></span>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px', cursor: "pointer" }}>
                        <i className="pi pi-envelope" style={{ fontSize: '1.2rem', marginRight: '8px' }} ></i>
                        <span style={{ fontSize: '1rem' }} onClick={() => copyToClipboard(tempMail)}>{tempMail} <ContentCopyIcon sx={{ fontSize: 10 }} /></span>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px', cursor: "pointer" }}>
                        <i className="pi pi-phone" style={{ fontSize: '1.2rem', marginRight: '8px' }}></i>
                        <span style={{ fontSize: '1rem' }} onClick={() => copyToClipboard(tempNum)}>{tempNum} <ContentCopyIcon sx={{ fontSize: 10 }} /></span>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <i className="pi pi-box" style={{ fontSize: '1.2rem', marginRight: '8px' }}></i>
                        <span style={{ fontSize: '1rem' }}>{tempBesoin}</span>
                    </div>
                </div>


                <div>
                    <Select
                        labelId="modePaie-label"
                        id="typeAppel"
                        style={{ width: "110%" }}
                        value={appel}
                        onChange={(e) => setAppel(e.target.value)}
                    >
                        <MenuItem value="0">Injoignable</MenuItem>
                        <MenuItem value="1">Devis envoyé</MenuItem>
                        <MenuItem value="2">Demande de rappel</MenuItem>
                        <MenuItem value="3">Pas interessé / a déjà trouvé</MenuItem>
                    </Select>
            
                </div>
                <div>
                    <TextareaAutosize
                        variant="standard"
                        minRows={10} // Définissez le nombre minimum de lignes
                        maxRows={20} // Définissez le nombre maximum de lignes
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                        style={{ width: "110%", marginTop: "30px" }}
                    />
                </div>
                {dateMAJ ? (
                    <div>
                        <span>
                            modifié le {formattedDate(dateMAJ)} par {infoMAJ}
                        </span>
                    </div>
                ) : (
                    <>
                    </>
                )
                }
                <div>
                    <Button
                        label="Enregistrer"
                        icon="pi pi-check"
                        severity="success"
                        style={{ marginTop: "30px", width: "110%" }}
                        size="small"
                        onClick={() => {
                            handleSavePhoneCall();
                        }}
                    />
                </div>

            </Sidebar>
            <TabView activeIndex={activeIndex} onTabChange={handleTabChange}>
                <TabPanel header="Nouvelles">
                    <DataTable
                        value={ficheListeFiltre}
                        paginator
                        rows={10}
                        header={enteteTabeau1}
                        rowsPerPageOptions={[5, 10, 25, 50]}
                        tableStyle={{ minWidth: '50rem' }}
                        scrollable
                        scrollDirection="horizontal"
                        paginatorTemplate={
                            "FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
                        }
                        paginatorRight={
                            <span style={{ margin: "10px", fontWeight: "bold" }}>
                                Résultats : {ficheListeFiltre.length}
                            </span>
                        }
                    >
                        <Column field="dateFiche" header="Date" bodyStyle={{fontSize:'14px'}} body={(rowData) => {
                            const date = new Date(rowData.dateFiche);
                            const day = String(date.getDate()).padStart(2, '0');
                            const month = String(date.getMonth() + 1).padStart(2, '0');
                            const year = date.getFullYear();
                            return (
                                <span>
                                    {day}/{month}/{year}{" "}
                                    <small>
                                        <small>({rowData.urgence})</small>

                                    </small>
                                </span>
                            );
                            // return `${day}/${month}/${year} (${rowData.urgence})`;
                        }} />
                        <Column
                            style={{ cursor: "pointer" }}
                            field="nom"
                            header="Client"
                            bodyStyle={{fontSize:"14px"}}
                            body={(rowData) => (
                                <div>
                                    <span>
                                        {rowData.nom}
                                    </span> 
                                    <br /> 
                                    <span onClick={() => { copyToClipboard(rowData.mail) }}>
                                        {rowData.mail} <ContentCopyIcon sx={{ fontSize: 10 }} />
                                    </span>
                                     <br />
                                     <span onClick={() => { copyToClipboard(rowData.num) }}>
                                        {rowData.num} <ContentCopyIcon sx={{ fontSize: 10 }} />
                                    </span> 
                                   
                                </div>

                            )}
                        />

                        <Column header="Besoin" bodyStyle={{ width: '16%', fontSize: '14px' }} body={(rowData) =>

                            <>
                                <span>
                                    {rowData.dimension}
                                </span><br />
                                <span>
                                    {rowData.duree}
                                </span><br />
                                <span>
                                    {rowData.ville}
                                </span>


                            </>

                        } />


                        <Column header='Actions' style={{ float: 'right' }} body={(rowData) =>
                            <div>
                                <Button
                                    icon="pi pi-phone"
                                    severity="success"
                                    size="small"
                                    onClick={() => {

                                        setInfoClick(rowData.idFiche, rowData.nom, rowData.num, rowData.dimension, rowData.mail);

                                    }}
                                />


                            </div>
                        } />
                    </DataTable>
                </TabPanel>
                <TabPanel header="En cours">
                    <DataTable
                        value={ficheFiltreEnCours}
                        paginator
                        rows={10}
                        header={enteteTableau2}
                        rowsPerPageOptions={[5, 10, 25, 50]}
                        tableStyle={{ minWidth: '50rem' }}
                        scrollable
                        scrollDirection="horizontal"
                        paginatorTemplate={
                            "FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
                        }
                        paginatorRight={
                            <span style={{ margin: "10px", fontWeight: "bold" }}>
                                Résultats : {ficheFiltreEnCours.length}
                            </span>
                        }
                    >
                        <Column field="dateFiche" header="Date" bodyStyle={{fontSize:'14px'}} body={(rowData) => {
                            const date = new Date(rowData.dateFiche);
                            const day = String(date.getDate()).padStart(2, '0');
                            const month = String(date.getMonth() + 1).padStart(2, '0');
                            const year = date.getFullYear();
                            return (
                                <span>
                                    {day}/{month}/{year}{" "}
                                    <small>
                                        <small>({rowData.urgence})</small>

                                    </small>
                                </span>
                            );
                            // return `${day}/${month}/${year} (${rowData.urgence})`;
                        }} />
                         <Column
                            style={{ cursor: "pointer" }}
                            field="nom"
                            header="Client"
                            bodyStyle={{fontSize:"14px"}}
                            body={(rowData) => (
                                <div>
                                    <span>
                                        {rowData.nom}
                                    </span> 
                                    <br /> 
                                    <span onClick={() => { copyToClipboard(rowData.mail) }}>
                                        {rowData.mail} <ContentCopyIcon sx={{ fontSize: 10 }} />
                                    </span>
                                     <br />
                                     <span onClick={() => { copyToClipboard(rowData.num) }}>
                                        {rowData.num} <ContentCopyIcon sx={{ fontSize: 10 }} />
                                    </span> 
                                   
                                </div>

                            )}
                        />

                        <Column header="Besoin" bodyStyle={{ width: '16%', fontSize: '14px' }} body={(rowData) =>

                            <>
                                <span>
                                    {rowData.dimension}
                                </span><br />
                                <span>
                                    {rowData.duree}
                                </span><br />
                                <span>
                                    {rowData.ville}
                                </span>


                            </>

                        } />
                        <Column field="etat" header="Etat" body={(rowData) =>
                            <>
                                <Tag value={`${mapEtatFiche(rowData.etat)} x${rowData.nbRepondeur}`} severity={`${rowData.severity}`}>
                                </Tag>
                            </>

                        } />

                        <Column field="note" header="Notes" bodyStyle={{fontSize:"14px"}} body={(rowData) => <div><span>{rowData.note}</span></div>} />
                        <Column field="adminNote"  header="MAJ"
                        bodyStyle={{fontSize:'14px'}} 
                            body={(rowData) =>
                                <div>
                                <span>
                                    {formattedDate(rowData.dateNote)}
                                </span>
                                <br />
                                <span>
                                {rowData.adminNote}
                                </span>
                                </div>
                                
                            }
                        />
                        <Column header='Actions' style={{ float: 'right' }} body={(rowData) =>
                            <div>
                                <Button
                                    icon="pi pi-phone"
                                    severity="success"
                                    size="small"
                                    onClick={() => {

                                        setInfoClick(rowData.idFiche, rowData.nom, rowData.num, rowData.dimension, rowData.mail);

                                    }}
                                />


                            </div>
                        } />
                    </DataTable>

                </TabPanel>
                <TabPanel header="Traitées">
                    <DataTable
                        value={ficheListeFiltreTr}
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25, 50]}
                        tableStyle={{ minWidth: '50rem' }}
                        header={enteteTabeauTr}
                        scrollable
                        scrollDirection="horizontal"
                        paginatorTemplate={
                            "FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
                        }
                        paginatorRight={
                            <span style={{ margin: "10px", fontWeight: "bold" }}>
                                Résultats : {ficheListeFiltreTr.length}
                            </span>
                        }
                    >
                        <Column field="dateFiche" header="Date" bodyStyle={{fontSize:'14px'}} body={(rowData) => {
                            const date = new Date(rowData.dateFiche);
                            const day = String(date.getDate()).padStart(2, '0');
                            const month = String(date.getMonth() + 1).padStart(2, '0');
                            const year = date.getFullYear();
                            return (
                                <span>
                                    {day}/{month}/{year}{" "}
                                    <small>
                                        <small>({rowData.urgence})</small>

                                    </small>
                                </span>
                            );
                            // return `${day}/${month}/${year} (${rowData.urgence})`;
                        }} />
                         <Column
                            style={{ cursor: "pointer" }}
                            field="nom"
                            header="Client"
                            bodyStyle={{fontSize:"14px"}}
                            body={(rowData) => (
                                <div>
                                    <span>
                                        {rowData.nom}
                                    </span> 
                                    <br /> 
                                    <span onClick={() => { copyToClipboard(rowData.mail) }}>
                                        {rowData.mail} <ContentCopyIcon sx={{ fontSize: 10 }} />
                                    </span>
                                     <br />
                                     <span onClick={() => { copyToClipboard(rowData.num) }}>
                                        {rowData.num} <ContentCopyIcon sx={{ fontSize: 10 }} />
                                    </span> 
                                   
                                </div>

                            )}
                        />
                        <Column header="Besoin" bodyStyle={{ width: '16%', fontSize: '14px' }} body={(rowData) =>

                            <>
                                <span>
                                    {rowData.dimension}
                                </span><br />
                                <span>
                                    {rowData.duree}
                                </span><br />
                                <span>
                                    {rowData.ville}
                                </span>


                            </>

                        } />
                        <Column field="etat" header="Etat" body={(rowData) =>
                            <>
                                <Tag value={`${mapEtatFiche(rowData.etat)} x${rowData.nbRepondeur}`} severity={`${rowData.severity}`}>
                                </Tag>
                            </>

                        } />
                        <Column field="note" header="Notes" bodyStyle={{fontSize:"14px"}} body={(rowData) => <div><span>{rowData.note}</span></div>} />
                        <Column field="adminNote"  header="MAJ"
                        bodyStyle={{fontSize:'14px'}} 
                            body={(rowData) =>
                                <div>
                                <span>
                                    {formattedDate(rowData.dateNote)}
                                </span>
                                <br />
                                <span>
                                {rowData.adminNote}
                                </span>
                                </div>
                                
                            }
                        />
                        <Column header='Actions' style={{ float: 'right' }} body={(rowData) =>
                            <div>
                                <Button
                                    icon="pi pi-phone"
                                    severity="success"
                                    size="small"
                                    onClick={() => {

                                        setInfoClick(rowData.idFiche, rowData.nom, rowData.num, rowData.dimension, rowData.mail);

                                    }}
                                />


                            </div>
                        } />
                    </DataTable>
                </TabPanel>
                <TabPanel header="Abandonnées">
                    <DataTable
                        value={ficheListeFiltreGv}
                        paginator
                        rows={10}
                        header={enteteTabeauGv}
                        rowsPerPageOptions={[5, 10, 25, 50]}
                        tableStyle={{ minWidth: '50rem' }}
                        scrollable
                        scrollDirection="horizontal"
                        paginatorTemplate={
                            "FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
                        }
                        paginatorRight={
                            <span style={{ margin: "10px", fontWeight: "bold" }}>
                                Résultats : {ficheListeFiltreGv.length}
                            </span>
                        }
                    >
                        <Column field="dateFiche" header="Date" bodyStyle={{fontSize:'14px'}} body={(rowData) => {
                            const date = new Date(rowData.dateFiche);
                            const day = String(date.getDate()).padStart(2, '0');
                            const month = String(date.getMonth() + 1).padStart(2, '0');
                            const year = date.getFullYear();
                            return (
                                <span>
                                    {day}/{month}/{year}{" "}
                                    <small>
                                        <small>({rowData.urgence})</small>

                                    </small>
                                </span>
                            );
                            // return `${day}/${month}/${year} (${rowData.urgence})`;
                        }} />
                         <Column
                            style={{ cursor: "pointer" }}
                            field="nom"
                            header="Client"
                            bodyStyle={{fontSize:"14px"}}
                            body={(rowData) => (
                                <div>
                                    <span>
                                        {rowData.nom}
                                    </span> 
                                    <br /> 
                                    <span onClick={() => { copyToClipboard(rowData.mail) }}>
                                        {rowData.mail} <ContentCopyIcon sx={{ fontSize: 10 }} />
                                    </span>
                                     <br />
                                     <span onClick={() => { copyToClipboard(rowData.num) }}>
                                        {rowData.num} <ContentCopyIcon sx={{ fontSize: 10 }} />
                                    </span> 
                                   
                                </div>

                            )}
                        />
                        <Column header="Besoin" bodyStyle={{ width: '16%', fontSize: '14px' }} body={(rowData) =>

                            <>
                                <span>
                                    {rowData.dimension}
                                </span><br />
                                <span>
                                    {rowData.duree}
                                </span><br />
                                <span>
                                    {rowData.ville}
                                </span>


                            </>

                        } />
                        <Column field="etat" header="Etat" body={(rowData) =>
                            <>
                                <Tag value={`${mapEtatFiche(rowData.etat)} x${rowData.nbRepondeur}`} severity={`${rowData.severity}`}>
                                </Tag>
                            </>

                        } />
                        <Column field="note" header="Notes" bodyStyle={{fontSize:"14px"}} body={(rowData) => <div><span>{rowData.note}</span></div>} />
                        <Column field="adminNote"  header="MAJ"
                        bodyStyle={{fontSize:'14px'}} 
                            body={(rowData) =>
                                <div>
                                <span>
                                    {formattedDate(rowData.dateNote)}
                                </span>
                                <br />
                                <span>
                                {rowData.adminNote}
                                </span>
                                </div>
                                
                            }
                        />
                        <Column header='Actions' style={{ float: 'right' }} body={(rowData) =>
                            <div>
                                <Button
                                    icon="pi pi-phone"
                                    severity="success"
                                    size="small"
                                    onClick={() => {

                                        setInfoClick(rowData.idFiche, rowData.nom, rowData.num, rowData.dimension, rowData.mail);

                                    }}
                                />


                            </div>
                        } />
                    </DataTable>
                </TabPanel>
                <TabPanel header="Toutes">
                    <DataTable
                        value={ficheListeFiltreToutes}
                        paginator
                        rows={10}
                        header={enteteTableauTt}
                        rowsPerPageOptions={[5, 10, 25, 50]}
                        tableStyle={{ minWidth: '50rem' }}
                        scrollable
                        scrollDirection="horizontal"
                        paginatorTemplate={
                            "FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
                        }
                        paginatorRight={
                            <span style={{ margin: "10px", fontWeight: "bold" }}>
                                Résultats : {ficheListeFiltreToutes.length}
                            </span>
                        }
                    >
                        <Column field="dateFiche" header="Date" bodyStyle={{fontSize:'14px'}} body={(rowData) => {
                            const date = new Date(rowData.dateFiche);
                            const day = String(date.getDate()).padStart(2, '0');
                            const month = String(date.getMonth() + 1).padStart(2, '0');
                            const year = date.getFullYear();
                            return (
                                <span>
                                    {day}/{month}/{year}{" "}
                                    <small>
                                        <small>({rowData.urgence})</small>

                                    </small>
                                </span>
                            );
                            // return `${day}/${month}/${year} (${rowData.urgence})`;
                        }} />
                         <Column
                            style={{ cursor: "pointer" }}
                            field="nom"
                            header="Client"
                            bodyStyle={{fontSize:"14px"}}
                            body={(rowData) => (
                                <div>
                                    <span>
                                        {rowData.nom}
                                    </span> 
                                    <br /> 
                                    <span onClick={() => { copyToClipboard(rowData.mail) }}>
                                        {rowData.mail} <ContentCopyIcon sx={{ fontSize: 10 }} />
                                    </span>
                                     <br />
                                     <span onClick={() => { copyToClipboard(rowData.num) }}>
                                        {rowData.num} <ContentCopyIcon sx={{ fontSize: 10 }} />
                                    </span> 
                                   
                                </div>

                            )}
                        />
                        <Column header="Besoin" bodyStyle={{ width: '16%', fontSize: '14px' }} body={(rowData) =>

                            <>
                                <span>
                                    {rowData.dimension}
                                </span><br />
                                <span>
                                    {rowData.duree}
                                </span><br />
                                <span>
                                    {rowData.ville}
                                </span>


                            </>

                        } />
                        <Column field="etat" header="Etat" body={(rowData) =>
                            <>
                                <Tag value={`${mapEtatFiche(rowData.etat)} x${rowData.nbRepondeur}`} severity={`${rowData.severity}`}>
                                </Tag>
                            </>

                        } />
                        <Column field="note" header="Notes" bodyStyle={{fontSize:"14px"}} body={(rowData) => <div><span>{rowData.note}</span></div>} />
                        <Column field="adminNote"  header="MAJ"
                        bodyStyle={{fontSize:'14px'}} 
                            body={(rowData) =>
                                <div>
                                <span>
                                    {formattedDate(rowData.dateNote)}
                                </span>
                                <br />
                                <span>
                                {rowData.adminNote}
                                </span>
                                </div>
                                
                            }
                        />
                        <Column header='Actions' style={{ float: 'right' }} body={(rowData) =>
                            <div>
                                <Button
                                    icon="pi pi-phone"
                                    severity="success"
                                    size="small"
                                    onClick={() => {

                                        setInfoClick(rowData.idFiche, rowData.nom, rowData.num, rowData.dimension, rowData.mail);

                                    }}
                                />


                            </div>
                        } />
                    </DataTable>
                </TabPanel>

            </TabView>

        </>
    );




}