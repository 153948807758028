import React, { useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../ClientStyle/client.css";
import * as ClientStyle from "../ClientStyle/clientStyle";
//import Button from "@mui/material/Button";
import { AjoutClient } from "./ajoutClient";
import { ModifierClient } from "./modifierClient";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button } from 'primereact/button';
import { SuprClient } from "./suprClient";
import useMediaQuery from "@mui/material/useMediaQuery";
import FiltreClient from "./FiltreClient";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Tooltip from "@mui/material/Tooltip";
import { Dialog } from 'primereact/dialog';
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import { Toast } from "primereact/toast";
import Grid from '@mui/material/Grid';
import Snackbar from "@mui/material/Snackbar";
import CloseIcon from "@mui/icons-material/Close";
import { TabView, TabPanel } from 'primereact/tabview';
import correctEncoding from "../../correctEncoding";
import formattedDate from "../../formatDate";
import EmailLink from "../../sendMail";
import mapTypeCtr from "../../mapContrat";
import mapTypePaie from "../../mapTypePaie";

import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TextField,
  Modal,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { Bar } from "react-chartjs-2";
import { fontWeight } from "@mui/system";

export const Client = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const isMobilePortrait = useMediaQuery("(max-width:1207px) ");
  const [titre, setTitre] = useState("Liste des clients");
  const style = {
    marginTop: "50%",
    paddingLeft: "50%",
    transform: "translate(-50%, -50%)",
  };
  const [valueFiltre, setValueFiltre] = React.useState("tout");
  const [recherche, setRecherche] = useState("");
  const [rescentCli, setRescentCli] = useState("");

  const [copiedText, setCopiedText] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const [infoLocation, setInfoLocation] = useState([]);
  const [infoFacture, setInfoFacture] = useState([]);
  const [infoPaie,setInfoPaie] = useState([]);
  const [infoContrat, setInfoContrat] = useState([]);
  const [locationVisible, setLocationVisible] = useState(false);
  const [personName, setPersonName] = useState('');

  const handleClick = async (idStockage, idClient) => {
    //Clique du numero de stockage
    try { 
      
      const response = await axios.get(`${apiUrl}/IHM/api/suggestion.php?idStockage=${idStockage}&idClient=${idClient}`);
      setInfoLocation(response.data);
      setLocationVisible(true);

      const factureResponse = await axios.get(`${apiUrl}/IHM/api/suggestion.php?idClient=${idClient}&facture`);
      setInfoFacture(factureResponse.data);

      const paieResponse = await axios.get(`${apiUrl}/IHM/api/suggestion.php?idClient=${idClient}&paiement`);
      setInfoPaie(paieResponse.data);


      const contratResponse = await axios.get(`${apiUrl}/IHM/api/suggestion.php?idClient=${idClient}&contrat`);
      setInfoContrat(contratResponse.data);

    } catch (error) {
      console.error("Erreur lors de la récupération des suggestions", error);
    }

  };


  const handleChange = async (event) => {
    setValueFiltre(event.target.value);

    if (event.target.value === "nonActif") {
      setTitre("Liste des clients non actifs");
    } else if (event.target.value === "actif") {
      setTitre("Liste des clients actifs");
    } else {
      setTitre("Liste des Clients");
    }


    // axios

    //   .put(
    //     `${apiUrl}IHM/api/?recherche=${recherche}&filtre=${event.target.value}`
    //   )

    //   .then((response) => {
    //     const clientsData = response.data;
    //     setClients(clientsData);
    //   })
    //   .catch((error) => {
    //     console.error(error);
    //   });
  };
  const copyToClipboard = (text) => {
    if (text !== "") {
      if (navigator.clipboard && navigator.clipboard.writeText) {
        navigator.clipboard
          .writeText(text)
          .then(() => {
            setCopiedText(text);
            setOpenSnackbar(true);
          })
          .catch((error) => {
            console.error(
              "Erreur lors de la copie dans le presse-papiers : ",
              error
            );
          });
      } else {
        // Utiliser l'API de sélection de texte comme solution de secours
        const textArea = document.createElement("textarea");
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand("copy");
        document.body.removeChild(textArea);

        setCopiedText(text);
        setOpenSnackbar(true);
      }
    }
  };


  const searchTextFieldRef = useRef(null);

  const [selectedClient, setSelectedClient] = useState({
    idClient: "",
  });

  const [clients, setClients] = useState([]);

  useEffect(() => {
    filtrerClient(recherche);
    setRescentCli("");
  }, [valueFiltre]);

  // const listerClient = useCallback(() => {
  //   axios
  //     .get(`${apiUrl}IHM/api/?filtre=${valueFiltre}`)
  //     .then((response) => {
  //       const clientsData = response.data;
  //       setClients(clientsData);
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // });

  const filtrerClient = (param) => {
    setRecherche(param.trimStart());
    // setCurrentPage(1);

    axios
      .put(`${apiUrl}IHM/api/?recherche=${param}&filtre=${valueFiltre}`)

      .then((response) => {
        const clientsData = response.data;
        console.log(response.data);
        setClients(clientsData);
      })
      .catch((error) => {
        console.error(error);
      });
  };


  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  //mipasser anle page akary amle modifier ary de mila manao anty const vaovao inty  anavahany ny ajout sy ny update
  const [modifierClient, setModifierClient] = useState(false);
  const [suprClient, setSuprClient] = useState(false);

  //*************************************************************TABLEAU TABLEAU TABLEAU******************************************************** */

  const [visible, setVisible] = useState(false);

  const vide = (param) => {
    return param === "" ? "N" : "O";
  };

  useEffect(() => {
    renderClientList();

    if (searchTextFieldRef.current) {
      searchTextFieldRef.current.focus();
    }
  }, []);
  const actionTemplate = (rowData) => {
    return (
      <>
        <Button
          icon="pi pi-pencil"
          onClick={() => handleEdit(rowData.idClient)}
        />
        <Button
          icon="pi pi-trash"
          onClick={() => handleDelete(rowData.idClient)}
        />
      </>
    );
  };
  const handleEdit = (clientId) => {
    setSelectedClient(clientId);
    setRescentCli(clientId);
    setModifierClient(true);
  };

  const handleDelete = (clientId) => {
    setSelectedClient(clientId);
    setRescentCli("");
    setSuprClient(true);
  };
  const [tooltipText, setTooltipText] = useState("");

  const renderTooltip = (rowData) => {
    return `${rowData.differenceJours} jours à payer depuis ${formattedDate(
      rowData.dateFin
    )} `;
  };
  const renderTooltipSansP = (rowData) => {
    if (rowData.dateEntree === null) {
      return `Aucun paiement depuis sa réservation : ${formattedDate(
        rowData.dateReservation
      )}`;
    } else {
      return `Aucun paiement depuis son entrée : ${formattedDate(
        rowData.dateEntree
      )}`;
    }
  };

  const renderTooltipAjour = (rowData) => {
    return `À jour jusqu'au ${formattedDate(rowData.dateFin)} `;
  };
  const toast = useRef(null);

  const renderClientList = () => {
    return (
      <div>
        <Grid container item xs={12} md={9} spacing={2} direction="column">

          <Toast ref={toast} />
          <div className="toutCL">
            {isMobilePortrait && (
              <div>
                <div className="center">
                  <FiltreClient
                    valueFiltre={valueFiltre}
                    handleChange={handleChange}
                    recherche={recherche}
                    filtrerClient={filtrerClient}
                    searchTextFieldRef={searchTextFieldRef}
                  />
                </div>


                <div style={{ display: "flex", alignItems: "center", gap: "20%" }} >
                  <h3 className="titreTableau">{titre} </h3>
                  <Button
                    style={{
                      backgroundColor: '#146C94',
                      flexShrink: 0
                    }}
                    label="Client"
                    onClick={() => setVisible(true)}
                    icon="pi pi-plus"
                    severity="info"
                  /></div>

                <ClientStyle.StyledPaper>
                  <div className=" ">
                    <div>
                      <DataTable
                        value={clients}
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 8, 10, 25, 50]}
                        tableStyle={{ minWidth: '50rem' }}
                        scrollable
                        scrollDirection="horizontal"
                        rowClassName={(rowData) =>
                          `${rowData.encours === "oui" ? "enCours-bgd " : ""}${rowData.idClient === rescentCli
                            ? "rescentCli custom-row-class"
                            : "custom-row-class"
                          }`
                        }
                        columnSeparator="   "
                        // paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
                        paginatorTemplate={
                          "FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
                        }
                        paginatorRight={
                          <span style={{ margin: "10px", fontWeight: "bold" }}>
                            Résultats : {clients.length}
                          </span>
                        }
                      >
                        <Column
                          field="idClient"
                          header="Id"
                        />
                        <Column
                          header="Nom"

                          body={(rowData) => (
                            <>
                              {rowData && (
                                <span className="client-column-content1">
                                  {correctEncoding(rowData.nom)}
                                </span>
                              )}
                            </>
                          )}
                        />
                        <Column
                          header="Tél"
                          bodyStyle={{ cursor: "pointer" }}

                          body={(rowData) => (
                            <span>
                              {rowData && (
                                <>
                                  <span
                                    onClick={() => copyToClipboard(rowData.num1)}
                                  >
                                    {rowData.num1}
                                  </span>
                                  <br />
                                  <span
                                    onClick={() => copyToClipboard(rowData.num2)}
                                  >
                                    {" "}
                                    {rowData.num2}
                                  </span>
                                </>
                              )}
                            </span>
                          )}
                        />
                        <Column
                          className="client-column-content"
                          header="Mail"
                          body={(rowData) => (
                            <>
                              <>
                                <span
                                  className="client-column-content1"
                                  style={{ paddingRight: "10px" }}
                                  onDoubleClick={()=>copyToClipboard(rowData.mail)}
                                >
                                  <EmailLink email={rowData.mail} name={correctEncoding(rowData.nom)} states={"1"} />
                                  <br />

                                  <EmailLink email={rowData.mail2} name={correctEncoding(rowData.nom)} states={"1"} />
                                </span>
                              </>
                            </>
                          )}
                        />
                        <Column
                          header="Stockage"
                          className="client-column-content"
                          body={(rowData) =>
                            rowData.encours === "oui" ? (
                              <span
                                onClick={() => handleClick(rowData.idStockage, rowData.idClient)}>
                                {rowData.idStockage.split('||').join(',')}
                                <br />
                              </span>
                            ) : null
                          }
                        />
                        <Column
                          style={{ width: "30%" }}
                          header="Situation"
                          body={(rowData) =>
                            rowData.encours === "oui" ? (
                              <span className="client-column-content1">
                                {rowData && (
                                  <span>
                                    {rowData.dateFin === "Sans paiement" ? (
                                      <>
                                        Aucun Paiement
                                        <Tooltip
                                          title={renderTooltipSansP(rowData)}
                                          arrow
                                        >
                                          <i
                                            className="pi pi-circle-fill"
                                            style={{
                                              fontSize: "10px",
                                              padding: "10px",
                                              cursor: "pointer",
                                              color: "#e4b61a",
                                            }}
                                          ></i>
                                        </Tooltip>
                                      </>
                                    ) : Number(rowData.differenceJours) >=
                                      0 ? (
                                      <>
                                        {rowData.differenceJours > 30 ? (
                                          <>
                                            {rowData.differenceJours > 30
                                              ? `${Math.ceil(
                                                rowData.differenceJours / 30
                                              )} mois à payer`
                                              : `${rowData.differenceJours} jours à payer`}
                                            <Tooltip
                                              title={renderTooltip(rowData)}
                                              arrow
                                            >
                                              <i
                                                className="pi pi-circle-fill"
                                                style={{
                                                  fontSize: "10px",
                                                  padding: "10px",
                                                  cursor: "pointer",
                                                  color:
                                                    rowData.differenceJours <
                                                      45
                                                      ? "#e2711d"
                                                      : "#da2c38",
                                                }}
                                              ></i>
                                            </Tooltip>
                                          </>
                                        ) : (
                                          <>
                                            {`${rowData.differenceJours} jours à
                                        payer`}
                                            <Tooltip
                                              title={renderTooltip(rowData)}
                                              arrow
                                            >
                                              <i
                                                className="pi pi-circle-fill"
                                                style={{
                                                  fontSize: "10px",
                                                  padding: "10px",
                                                  cursor: "pointer",
                                                  color: "#e2711d",
                                                }}
                                              ></i>
                                            </Tooltip>
                                          </>
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        À jour
                                        <Tooltip
                                          title={renderTooltipAjour(rowData)}
                                          arrow
                                        >
                                          <i
                                            className="pi pi-circle-fill"
                                            style={{
                                              fontSize: "10px",
                                              padding: "10px",
                                              cursor: "pointer",
                                              color: "#a1c181",
                                            }}
                                          ></i>
                                        </Tooltip>
                                      </>
                                    )}
                                  </span>
                                )}
                                <br />
                              </span>
                            ) : null
                          }
                        />



                        {localStorage.getItem("pr") === "sad" ||
                          localStorage.getItem("pr") === "st" ||
                          localStorage.getItem("pr") === "ad" ? (
                          <Column
                            header="Actions"
                            body={(rowData) => (
                              <span style={{ width: "5px", maxWidth: "5px" }}>
                                {rowData && (
                                  <>
                                    <ModeEditOutlineIcon
                                      style={{ cursor: "pointer" }}
                                      onClick={() => handleEdit(rowData.idClient)}
                                    />
                                    <Tooltip
                                      title={
                                        rowData && rowData.encours === "oui"
                                          ? "Suppression interdite : Ce client a encore de location en cours"
                                          : ""
                                      }
                                    >
                                      <span>
                                        <DeleteIcon
                                          onClick={() => {
                                            if (
                                              rowData &&
                                              rowData.encours === "oui"
                                            ) {
                                              return; // Ne rien faire si le bouton est désactivé
                                            }

                                            handleDelete(rowData.idClient);
                                          }}
                                          style={{
                                            marginLeft: "10px",
                                            cursor:
                                              rowData && rowData.encours === "oui"
                                                ? "not-allowed"
                                                : "pointer",
                                            opacity:
                                              rowData && rowData.encours === "oui"
                                                ? 0.3
                                                : 1,
                                          }}
                                        />
                                      </span>
                                    </Tooltip>
                                  </>
                                )}
                              </span>
                            )}
                          />
                        ) : null}
                      </DataTable>
                    </div>
                  </div>
                </ClientStyle.StyledPaper>{" "}

                <Dialog visible={locationVisible} modal style={{ width: '90vw' }} onHide={() => { if (!locationVisible) return; setLocationVisible(false); }}>
                  <TabView>
                    <TabPanel header="Location">
                      <DataTable
                        value={infoLocation}
                      >
                        <Column header="Client" field="nom" />
                        <Column header="Box" field="idStockage" body={(rowData) =>

                          <span>{rowData.idStockage.split('||').join(',')}</span>

                        } />
                        <Column header="Volume" field="totVolume" body={(rowData) =>

                          <span>{rowData.totVolume} m³</span>

                        } />
                        <Column header="Loyer" field="loyer" body={(rowData) =>

                          <span>{rowData.loyer} €</span>

                        } />
                        <Column
                          field="dateReservation"
                          header=" R/E"
                          style={{ width: "20%" }}
                          bodyStyle={{ fontSize: "13px" }}
                          body={(rowData) => (
                            <div>
                              R: {formattedDate(rowData.dateReservation)} <br />
                              E: {formattedDate(rowData.dateEntree)}
                            </div>
                          )}
                        />
                        <Column header="Notes" field="noteLocation" />
                      </DataTable>
                    </TabPanel >
                    <TabPanel header="Facture(s)">
                      <DataTable value={infoFacture}>
                        <Column field="numFact" header="Ref" bodyStyle={{ fontSize:'14px',cursor: "pointer" }} body={(rowData) => (
                          <span onClick={() => copyToClipboard(rowData.numFact)}>
                            {rowData.numFact}
                          </span>
                        )} />
                        <Column field="nom" header="Client" />
                        <Column field="dateEnv" header="Envoi" body={(rowData) => (
                          <span>
                            {formattedDate(rowData.dateEnv)}
                          </span>
                        )} />
                        <Column field="typeFact" header="Type" />
                        <Column field="idPaiement" header="Paiement" bodyStyle={{ cursor: "pointer" }} body={(rowData => (

                          <span onClick={() => copyToClipboard(rowData.idPaiement)}>
                            {rowData.idPaiement}
                          </span>


                        ))} />
                        <Column field="" header="Période" bodyStyle={{ fontSize: "14px" }} body={(rowData) => (
                                <span>
                                    D:{formattedDate(rowData.dateDebut)}<br/>
                                    F:{formattedDate(rowData.dateFin)}
                                </span>
                        )}/>
                        <Column header="Notes" field="note" />

                      </DataTable>
                    </TabPanel>
                    <TabPanel header="Paiement(s)">
                    <DataTable value={infoPaie}>
                        <Column header="Ref" field="idPaiement"/>
                        <Column header="Type" field="typePaie" body={(rowData) => 
                            <span>
                                {mapTypePaie(rowData.typePaie)}
                            </span>

                        }/>
                        <Column header="Client" field="nom" />
                        <Column header="Envoi" field="datePaie" body={(rowData) => 
                            <span>
                                {formattedDate(rowData.datePaie)}
                            </span>
                        }/>
                        <Column header="Période" field="dateFin" bodyStyle={{fontSize:"14px"}} body={(rowData) =>
                            <span>
                                D:{formattedDate(rowData.dateDebut)}<br/>
                                F:{formattedDate(rowData.dateFin)}
                            </span>
                        }/>
                                <Column header="Montant" field="valeurPaie" body={(rowData) =>
                                    <span>
                                    {rowData.valeurPaie}€
                                    </span>
                                } />


                    </DataTable>
                    </TabPanel>

                    <TabPanel header="Contrat">
                      <DataTable value={infoContrat}>
                        <Column field="typeContrat" header="Type" body={(rowData) => (<span>
                          {mapTypeCtr(rowData.typeContrat)}
                        </span>)} />
                        <Column field="dateContrat" header="Date du contrat" body={(rowData) =>
                        (
                          <span>
                            {formattedDate(rowData.dateContrat)}
                          </span>
                        )
                        } />
                        <Column header="Client" field="nom" />
                        <Column
                          field="lienCtr"
                          header="Lien du contrat"

                          body={(rowData) => {

                            const urlPattern = /^(https?:\/\/)/;
                            const link = urlPattern.test(rowData.lienCtr)
                              ? rowData.lienCtr
                              : null;

                            return link ? (
                              <a
                                style={{
                                  display: "block",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  maxWidth: "10vw",
                                  color: "#146C94",
                                }}
                                href={link}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {rowData.lienCtr}
                              </a>
                            ) : (
                              <span
                                style={{
                                  display: "block",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  maxWidth: "10vw",
                                  color: "#999",  // Couleur grise pour indiquer un état "en attente"
                                }}
                              >
                                {rowData.lienCtr}
                              </span>
                            );
                          }}
                        />
                      </DataTable>

                    </TabPanel>
                  </TabView>


                </Dialog>

              </div>
            )}
            <div className="flex">
              <Modal
                open={visible}
                disableBackdropClick={true}
                aria-describedby="modal-modal-description"
              >
                <AjoutClient
                  setVisible={setVisible}
                  setRescentCli={setRescentCli}
                  onClose={() => {
                    setVisible(false);
                    filtrerClient(""); // Appel de listerClient après la fermeture ,,pour que les données du table se mettent à jour
                  }}
                />
              </Modal>
              <Modal
                open={modifierClient}
                disableBackdropClick={true}
                aria-describedby="modal-modal-description"
              >
                <ModifierClient
                  idSelected={selectedClient}
                  setModifierClient={setModifierClient}
                  onClose={() => {
                    setModifierClient(false);
                    filtrerClient(""); // Appel de listerClient après la fermeture ,,pour que les données du table se mettent à jour
                  }}
                />
              </Modal>
              <Modal
                open={suprClient}
                disableBackdropClick={true}
                aria-describedby="modal-modal-description"
              >
                <SuprClient
                  setSuprClient={setSuprClient}
                  idSelected={selectedClient}
                  onClose={() => {
                    setSuprClient(false);
                    filtrerClient("");
                  }}
                />
              </Modal>
              <div className="gaucheStock">
                <div>
                  {!isMobilePortrait && (
                    <div>
                      <div className="center">
                      </div>{" "}
                      <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%'
                      }}>
                        <h3 style={{
                          margin: 0,
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          maxWidth: 'calc(100% - 120px)'
                        }}>{titre}</h3> {/* Enlève les marges par défaut du <h2> */}
                        <Button
                          label="Client"
                          onClick={() => setVisible(true)}
                          icon="pi pi-plus"
                          severity="info"
                          style={{
                            backgroundColor: '#146C94',
                            flexShrink: 0 // Prevents button from shrinking
                          }}
                        />
                      </div>
                      <div className=" ">
                        <DataTable
                          value={clients}
                          className="p-datatable"
                          paginator
                          rows={10}
                          rowsPerPageOptions={[5, 8, 10, 25, 50]}
                          tableStyle={{ minWidth: "40rem" }}
                          rowClassName={(rowData) =>
                            `${rowData.encours === "oui" ? "enCours-bgd " : ""
                            }${rowData.idClient === rescentCli
                              ? "rescentCli custom-row-class"
                              : "custom-row-class"
                            }`
                          }
                          columnSeparator="   "
                          // paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
                          paginatorTemplate={
                            "FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
                          }
                          paginatorRight={
                            <span
                              style={{ margin: "10px", fontWeight: "bold" }}
                            >
                              Résultats : {clients.length}
                            </span>
                          }
                        >
                          <Column
                            field="idClient"
                            bodyStyle={{width:'5%',fontSize:'14px'}}
                            header="Id"
                          />
                          <Column
                            header="Nom"
                            bodyStyle={{ width:'20%',cursor: "pointer",fontSize:"14px"}}
                            body={(rowData) => (
                              <>
                                {rowData && (
                                  <span className="client-column-content1">
                                    {correctEncoding(rowData.nom)}
                                  </span>
                                )}
                              </>
                            )}
                          />
                          <Column
                            header="Tél" 
                            bodyStyle={{cursor: "pointer" ,width:'15%',fontSize:'14px'}}
                            body={(rowData) => (
                              <span>
                                {rowData && (
                                  <>
                                    <span
                                      onClick={() => copyToClipboard(rowData.num1)}
                                    >
                                      {rowData.num1}
                                    </span>
                                    <br />
                                    <span
                                      onClick={() => copyToClipboard(rowData.num2)}
                                    >
                                      {" "}
                                      {rowData.num2}
                                    </span>
                                  </>
                                )}
                              </span>
                            )}
                          />
                          <Column
                            className="client-column-content"
                            header="Mail"
                            bodyStyle={{width:'20%',fontSize:'14px'}}
                            body={(rowData) => (
                              <>
                                <>
                                <span
                                  className="client-column-content1"
                                  style={{ paddingRight: "10px" }}
                                  onDoubleClick={()=>copyToClipboard(rowData.mail)}
                                >
                                  {rowData.mail}
                                  {/* <EmailLink email={rowData.mail} name={correctEncoding(rowData.nom)} states={"1"} /> */}
                                  <br />

                                  {/* <EmailLink email={rowData.mail2} name={correctEncoding(rowData.nom)} states={"1"} /> */}
                                  {rowData.mail2}
                                </span>
                                </>
                              </>
                            )}
                          />
                          <Column
                            header="Stockage"
                            className="client-column-content"
                            bodyStyle={{ width:'15%',cursor: "pointer",fontSize:"14px"}}
                            body={(rowData) =>
                              rowData.encours === "oui" ? (
                                <span
                                  onClick={() => handleClick(rowData.idStockage, rowData.idClient)}>
                                  {rowData.idStockage.split('||').join(',')}
                                  <br />
                                </span>
                              ) : null
                            }
                          />
                          <Column
                            bodyStyle={{ width: "25%",fontSize:"14px" }}
                            header="Situation"
                            body={(rowData) =>
                              rowData.encours === "oui" ? (
                                <span className="client-column-content1">
                                  {rowData && (
                                    <span>
                                      {rowData.dateFin === "Sans paiement" ? (
                                        <>
                                          Aucun Paiement
                                          <Tooltip
                                            title={renderTooltipSansP(rowData)}
                                            arrow
                                          >
                                            <i
                                              className="pi pi-circle-fill"
                                              style={{
                                                fontSize: "10px",
                                                padding: "10px",
                                                cursor: "pointer",
                                                color: "#e4b61a",
                                              }}
                                            ></i>
                                          </Tooltip>
                                        </>
                                      ) : Number(rowData.differenceJours) >=
                                        0 ? (
                                        <>
                                          {rowData.differenceJours > 30 ? (
                                            <>
                                              {rowData.differenceJours > 30
                                                ? `${Math.ceil(
                                                  rowData.differenceJours / 30
                                                )} mois à payer`
                                                : `${rowData.differenceJours} jours à payer`}
                                              <Tooltip
                                                title={renderTooltip(rowData)}
                                                arrow
                                              >
                                                <i
                                                  className="pi pi-circle-fill"
                                                  style={{
                                                    fontSize: "10px",
                                                    padding: "10px",
                                                    cursor: "pointer",
                                                    color:
                                                      rowData.differenceJours <
                                                        45
                                                        ? "#e2711d"
                                                        : "#da2c38",
                                                  }}
                                                ></i>
                                              </Tooltip>
                                            </>
                                          ) : (
                                            <>
                                              {`${rowData.differenceJours} jours à
                                          payer`}
                                              <Tooltip
                                                title={renderTooltip(rowData)}
                                                arrow
                                              >
                                                <i
                                                  className="pi pi-circle-fill"
                                                  style={{
                                                    fontSize: "10px",
                                                    padding: "10px",
                                                    cursor: "pointer",
                                                    color: "#e2711d",
                                                  }}
                                                ></i>
                                              </Tooltip>
                                            </>
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          À jour
                                          <Tooltip
                                            title={renderTooltipAjour(rowData)}
                                            arrow
                                          >
                                            <i
                                              className="pi pi-circle-fill"
                                              style={{
                                                fontSize: "10px",
                                                padding: "10px",
                                                cursor: "pointer",
                                                color: "#a1c181",
                                              }}
                                            ></i>
                                          </Tooltip>
                                        </>
                                      )}
                                    </span>
                                  )}
                                  <br />
                                </span>
                              ) : null
                            }
                          />
                          {/* <Column
                            field="totVolume"
                            header="Volume|Loyer"
                            body={(rowData) =>
                              rowData.encours === "oui" ? (
                                <span className="client-column-content1">
                                  {rowData && (
                                    <>
                                      <span>{rowData.totVolume} m³</span>{" | "}
                                      <span>{rowData.loyer} €</span>
                                    </>
                                  )}
                                  <br />
                                </span>
                              ) : null
                            }
                          /> */}

                          {localStorage.getItem("pr") === "sad" ||
                            localStorage.getItem("pr") === "st" ||
                            localStorage.getItem("pr") === "ad" ? (
                            <Column
                              header="Actions"
                              style={{ width: "10vw" }}
                              body={(rowData) => (
                                <>
                                  {rowData && (
                                    <>
                                      <ModeEditOutlineIcon
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          handleEdit(rowData.idClient)
                                        }
                                      />
                                      <Tooltip
                                        title={
                                          rowData && rowData.encours === "oui"
                                            ? "Suppression interdite : Ce client a encore de location en cours"
                                            : ""
                                        }
                                      >
                                        <span>
                                          <DeleteIcon
                                            onClick={() => {
                                              if (
                                                rowData &&
                                                rowData.encours === "oui"
                                              ) {
                                                return; // Ne rien faire si le bouton est désactivé
                                              }

                                              handleDelete(rowData.idClient);
                                            }}
                                            style={{
                                              marginLeft: "10px",
                                              cursor:
                                                rowData &&
                                                  rowData.encours === "oui"
                                                  ? "not-allowed"
                                                  : "pointer",
                                              opacity:
                                                rowData &&
                                                  rowData.encours === "oui"
                                                  ? 0.3
                                                  : 1,
                                            }}
                                          />
                                        </span>
                                      </Tooltip>
                                    </>
                                  )}
                                </>
                              )}
                            />
                          ) : null}
                        </DataTable>
                      </div>
                      <Dialog visible={locationVisible} modal style={{ width: '70vw' }} onHide={() => { if (!locationVisible) return; setLocationVisible(false); }}>
                        <TabView>
                          <TabPanel header="Location">
                            <DataTable
                              value={infoLocation}
                            >
                              <Column header="Client" field="nom" />
                              <Column header="Box" field="idStockage" body={(rowData) =>

                                <span>{rowData.idStockage.split('||').join(',')}</span>

                              } />
                              <Column header="Volume" field="totVolume" body={(rowData) =>

                                <span>{rowData.totVolume} m³</span>

                              } />
                              <Column header="Loyer" field="loyer" body={(rowData) =>

                                <span>{rowData.loyer} €</span>

                              } />
                              <Column
                                field="dateReservation"
                                header=" R/E"
                                style={{ width: "20%" }}
                                bodyStyle={{ fontSize: "13px" }}
                                body={(rowData) => (
                                  <div>
                                    R: {formattedDate(rowData.dateReservation)} <br />
                                    E: {formattedDate(rowData.dateEntree)}
                                  </div>
                                )}
                              />
                              <Column header="Notes" field="noteLocation" />




                            </DataTable>
                          </TabPanel >
                          <TabPanel header="Facture(s)">
                            <DataTable value={infoFacture}>
                              <Column field="numFact" header="Ref" bodyStyle={{ cursor: "pointer" }} body={(rowData) => (
                                <span onClick={() => copyToClipboard(rowData.numFact)}>
                                  {rowData.numFact}
                                </span>
                              )} />
                              <Column field="nom" header="Client" />
                              <Column field="dateEnv" header="Envoi" body={(rowData) => (
                                <span>
                                  {formattedDate(rowData.dateEnv)}
                                </span>
                              )} />
                              <Column field="typeFact" header="Type" />
                              <Column field="idPaiement" header="Paiement" bodyStyle={{ cursor: "pointer" }} body={(rowData => (

                                <span onClick={() => copyToClipboard(rowData.idPaiement)}>
                                  {rowData.idPaiement}
                                </span>


                              ))} />
                              <Column field="" header="Période" bodyStyle={{ fontSize: "14px" }} body={(rowData) => (
                                <span>
                                  D:{formattedDate(rowData.dateDebut)}<br />
                                  F:{formattedDate(rowData.dateFin)}
                                </span>
                              )} />
                              <Column header="Notes" field="note" />

                            </DataTable>
                          </TabPanel>
                          <TabPanel header="Paiement(s)">
                    <DataTable value={infoPaie}>
                        <Column header="Ref" field="idPaiement"/>
                        <Column header="Type" field="typePaie" body={(rowData) => 
                            <span>
                                {mapTypePaie(rowData.typePaie)}
                            </span>

                        }/>
                        <Column header="Client" field="nom" />
                        <Column header="Envoi" field="datePaie" body={(rowData) => 
                            <span>
                                {formattedDate(rowData.datePaie)}
                            </span>
                        }/>
                        <Column header="Période" field="dateFin" bodyStyle={{fontSize:"14px"}} body={(rowData) =>
                            <span>
                                D:{formattedDate(rowData.dateDebut)}<br/>
                                F:{formattedDate(rowData.dateFin)}
                            </span>
                        }/>
                                <Column header="Montant" field="valeurPaie" body={(rowData) =>
                                    <span>
                                    {rowData.valeurPaie}€
                                    </span>
                                } />


                    </DataTable>
                         </TabPanel>
                          <TabPanel header="Contrat">
                            <DataTable value={infoContrat}>
                              <Column field="typeContrat" header="Type" body={(rowData) => (<span>
                                {mapTypeCtr(rowData.typeContrat)}
                              </span>)} />
                              <Column field="dateContrat" header="Date du contrat" body={(rowData) =>
                              (
                                <span>
                                  {formattedDate(rowData.dateContrat)}
                                </span>
                              )
                              } />
                              <Column header="Client" field="nom" />
                              <Column
                                field="lienCtr"
                                header="Lien du contrat"
                                style={{ width: "10vw", maxWidth: "10vw" }}
                                body={(rowData) => {

                                  const urlPattern = /^(https?:\/\/)/;
                                  const link = urlPattern.test(rowData.lienCtr)
                                    ? rowData.lienCtr
                                    : null;

                                  return link ? (
                                    <a
                                      style={{
                                        display: "block",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        maxWidth: "10vw",
                                        color: "#146C94",
                                      }}
                                      href={link}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {rowData.lienCtr}
                                    </a>
                                  ) : (
                                    <span
                                      style={{
                                        display: "block",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        maxWidth: "10vw",
                                        color: "#999",  // Couleur grise pour indiquer un état "en attente"
                                      }}
                                    >
                                      {rowData.lienCtr}
                                    </span>
                                  );
                                }}
                              />
                            </DataTable>

                          </TabPanel>
                        </TabView>


                      </Dialog>

                      <Snackbar
                        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                        open={openSnackbar}
                        autoHideDuration={3000}
                        onClose={handleCloseSnackbar}
                        message={`Copie réussie de " ${copiedText === "location.mail" ? "e-mail" : copiedText
                          } "`}
                        action={
                          <React.Fragment>

                            <IconButton
                              size="small"
                              aria-label="close"
                              color="inherit"
                              onClick={handleCloseSnackbar}
                            >
                              <CloseIcon fontSize="small" />
                            </IconButton>
                          </React.Fragment>
                        }
                      />
                    </div>
                  )}
                </div>
              </div>
              {!isMobilePortrait && (
                <div className="droiteStock">
                  <FiltreClient
                    valueFiltre={valueFiltre}
                    handleChange={handleChange}
                    recherche={recherche}
                    filtrerClient={filtrerClient}
                    searchTextFieldRef={searchTextFieldRef}
                  />
                </div>
              )}
            </div>
          </div>{" "}
        </Grid>
      </div>
    );
  };

  //***********************************FIN TABLEAU TABLEAU TABLEAU******************************* */

  // listerClient();

  return <div>{renderClientList()} </div>;
};
