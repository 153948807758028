import React, { useState, useEffect } from 'react';
import { Carousel } from 'primereact/carousel';

import Chart from "chart.js/auto";
import axios from "axios";
import "../dashboardStyle/dash.css";
import { DashStk } from "./DashStockage/dashStockage";
import { DashRetard } from "./DashRetard/dashRetard";
import { DashRetard2 } from "./DashRetard/dashRetard2";

import { LineChart22 } from "./dashPeriodique/dashPeriode22";
import { LineChart23 } from "./dashPeriodique/dashPeriode23";

import { LineChart24 } from "./dashPeriodique/dashPeriode24";
import { Box, fontStyle } from '@mui/system';

import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';




import useMediaQuery from "@mui/material/useMediaQuery";
export const Dash = () => {
  const [value, setValue] = React.useState('1');


  const isMobilePortrait = useMediaQuery("(max-width:1207px) ");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      {isMobilePortrait && (
        <div className="cardDashMob" >
          <TabContext value={value}>
            <Box sx={{ width: '100%', bgcolor: 'background.paper', display: 'flex', justifyContent: 'center' }}>
              <TabList onChange={handleChange} aria-label="lab API tabs example">
                <Tab label="Occupation" value="1" />
                <Tab label="Retards" value="2" />
                <Tab label="Entrée/Sortie" value="3" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <div className="cardDashMobStk"  >

                <DashStk />
              </div>
            </TabPanel>
            <TabPanel value="2">
              <div  >
             <DashRetard />   
           
                  <DashRetard2 /></div>  
               

            </TabPanel>
            <TabPanel value="3">
              <LineChart22 />
              <br></br>
              <LineChart24 />
               <br></br>
              <LineChart23 />
          
             </TabPanel>
          </TabContext>
          <div >

          </div></div>
      )
      }
      {!isMobilePortrait && (
        <>
          <div className="containerDash">
            <div className="card">
              <DashStk />
            </div>
            <div
              style={{ marginLeft: "1%" }}
              className="card"
            >
              <DashRetard />{" "}
              <>
                <DashRetard2 />
              </>
            </div>

            <div
              className="card"
              style={{ marginLeft: "1%" }}
            >
             
              <LineChart22 />

              <LineChart24 />

              <LineChart23 />
             
              

             
            </div>
          </div>
        </>
      )
      }

    </div>
  )
};
